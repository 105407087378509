import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { axios } from "../../axios"
import { useContext, useEffect, useRef, useState } from "react"
import { Input } from "antd"

import style from './post.detail.module.css'
import { localDate, share, toFold } from "../../tools"
import Msg from "../my-com/Msg"
import Linkify from "../my-com/linkfy"
import Page from "../my-com/page"
import { PostEdit } from "./edit/post.edit"
import { SusparContext } from "../../context"
import { Down, Ms, Share, UP } from "../../icons"
export default function TopicPostDetail(){

    const context = useContext(SusparContext)
    const navi = useNavigate()
    const id = useParams().id
    const [post,setPost] = useState({
        description:""
    })

    const [searchParmas,setSearchParmas] = useSearchParams()
    const [replys,setReplys] = useState({
        filter:'all',
        list:[],
        total:0,
        page:1
    })
    let catcheReply = localStorage.getItem("cache_post_add_reply")
    const [replyToAdd,setReplyToAdd] = useState(catcheReply?catcheReply:"")
    //展开的评论ids
    const [unfoldIds,setUnfoldIds] = useState([])

    useEffect(()=>{
        getPostDetail()
        let page = searchParmas.get("page")?parseInt(searchParmas.get("page")):1
        getReplys(page,false,searchParmas.get("filter"))
        // eslint-disable-next-line
    },[])

    const [showFullDescription,setShowFullDescription] = useState(false)

    //置灰提交按钮
    const submitingRef = useRef(false)


    const [showEdit,setShowEdit] = useState({
        show:false,
        detail:{}
    })


    function canEdit(){
        if(post.replyCount!==0){
            return false
        }
        if(!context.loggedIn)
            return false
        if(!context.userInfo)
            return false
        if(post.user.id!==context.userInfo.user.id){
            return false
        }
        return true
    }
    return <div className={style.container}>

        <h3>话题详情</h3>

        <div className={[style.detail,style.first].join(" ")}>
            <div className={style.postHeader}>
                <span className={style.node} onClick={()=>navi("/topic/node/detail/"+post.nodeId)}>#{post.node}</span>
                <div className={style.right}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+post.user.id)}>{getNicknameOrUsername(post.user)}</span>
                    <span className={style.date}>{localDate(post.createDatetime)}</span>
                </div>
            </div>
            <div className={style.title}>
                <span>{post.title}</span>
            </div>
            <div className={style.starAndUnstar}>
                <span className={style.ss}>
                    <span><Ms/>{post.replyCount}</span>
                    {post.stared?<span className={style.star}>
                        <UP />
                        {post.star}</span>:
                    <span onClick={starPost}><UP />
                        {post.star}</span>}
                    {post.unstared?<span className={style.star}><Down />{post.unstar}</span>:
                    <span onClick={unstarPost}><Down />{post.unstar}</span>}
                </span>
                <span className={style.shareAndArchive}>
                    {canEdit()&&<span onClick={()=>setShowEdit({
                        show:true,
                        detail:post
                    })}>编辑</span>}
                    {post.archived?<span className={style.archived} onClick={unarchivePost}>已收藏</span>:
                    <span className={style.unarchived} onClick={archivePost}>收藏</span>}
                    <span className={style.share} onClick={()=>share("/topic/post/detail/"+post.id,post.title)}><Share/></span>
                </span>
            </div>

            <div style={{borderBottom:"1px solid lightgray",margin:"8px 0px"}}></div>
            <div>
                <div className={style.postDesciption}>
                    <div className={toFold(post.description)&&!showFullDescription?style.descriptionHide:style.description}>
                        <Linkify text={post.description}></Linkify>
                    </div>
                    {toFold(post.description)&&<div>
                        {showFullDescription?<span onClick={()=>setShowFullDescription(false)} style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>:
                        <span onClick={()=>setShowFullDescription(true)} style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>}
                    </div>}
                </div>
            </div>
        </div>


        <div className={[style.replys,style.first].join(" ")}>
            <div className={style.replysFilter}>
                <span className={replys.filter==='all'?style.active:''}
                    onClick={()=>{
                        setReplys({...replys,filter:"all"})
                        getReplys(1,true,"all")
                    }}
                >全部</span>
                <span className={replys.filter==='op'?style.active:''}
                    onClick={()=>{
                        setReplys({...replys,filter:"op"})
                        getReplys(1,true,"op")
                    }}>只看话题OP</span>
            </div>
            {replys.list.map(e=><div key={e.id} className={style.reply}>
                <div className={style.replyId}>#{e.id}</div>
                <div className={style.replyHeader}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+e.user.id)}>{getNicknameOrUsername(e.user)}</span>
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </div>
                <p className={toFold(e.content)&&!unfoldIds.includes(e.id)?style.replyContentHide:style.replyContent}>
                    <Linkify text={e.content}/>
                </p>

                {toFold(e.content)&&<>{unfoldIds.includes(e.id)?
                    <p className={style.folder} onClick={()=>{
                        let tmpIds = unfoldIds.filter(id=>id!==e.id)
                        setUnfoldIds(tmpIds)
                    }}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>   
                    </p>:<p className={style.folder} onClick={()=>{
                        unfoldIds.push(e.id)
                        let tmpIds = [...unfoldIds]
                        tmpIds.push(e.id)
                        setUnfoldIds(tmpIds)
                    }}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>
                    </p>}</>}

                <div className={style.replyFoot}>
                    <span onClick={()=>navi("/topic/post/reply/"+e.id)}><Ms/>{e.replyCount}</span>
                    <span onClick={()=>{
                        if(!e.stared) starReply(e)
                    }} className={e.stared?style.active:""}><UP/>{e.star}</span>
                    <span onClick={()=>{if(!e.unstared) unstarReply(e)}} className={e.unstared?style.active:""}><Down />{e.unstar}</span> 
                    {!e.archived&&<span onClick={()=>archiveReply(e)}>收藏</span>}
                    {e.archived&&<span onClick={()=>unarchiveReply(e)} className={style.active}>取消收藏</span>}
                    <span onClick={()=>share("/topic/post/reply/"+e.id,e.content)}><Share/></span>
                </div>
            </div>)}

      
            {replys.total>=18&&<div style={{padding:'1em 0',textAlign:'center'}}>
                <Page total={replys.total} current={replys.page} pageSize={18} onChange={(p)=>getReplys(p,true)}/>  
            </div>}
        </div>
        <div className={style.first}>
            <div>
                <Input.TextArea 
                    autoSize={{minRows:4}}
                    value={replyToAdd} 
                    maxLength={2000}
                    placeholder="请理性、真诚的回复"
                    onChange={e=>{
                        setReplyToAdd(e.target.value)
                        localStorage.setItem("cache_post_add_reply",e.target.value)
                    }} />
                <span className={style.btn}
                    onClick={()=>{
                    addReply()
                }}>回复话题</span>
            </div>
        </div>

        {showEdit.show&&<PostEdit detail={showEdit.detail} close={(v)=>{
            setShowEdit({
                show:false,
                detail:{}
            })
            if(v){
                getPostDetail()
            }
        }}/>}
    </div>

    async function getPostDetail() {
        axios.get("/api/topic/post",{
            params:{
                id
            }
        })
        .then(({data})=>{
            if(data.code===200){
                setPost(data.data)
            }else{
                Msg.msg(data.msg)
            }
        }).catch(e=>{})
    }

    function getReplys(tmpPage,changeURL,filter) {
        axios.get("/api/topic/reply",{
            params:{
                postId:id,
                page:(tmpPage?tmpPage:replys.page) -1,
                filter:filter?filter:replys.filter
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplys({...replys,
                    filter:filter?filter:replys.filter,
                    page:tmpPage?tmpPage:replys.page,
                    list:data.data.list,
                    total:data.data.total})
                if(changeURL){
                    setSearchParmas(pre=>{
                        let tmp = new URLSearchParams(pre)
                        tmp.set("page",tmpPage?tmpPage:replys.page)
                        if(filter==='all'){
                            tmp.delete("filter")
                        }else if(filter==='op'){
                            tmp.set("filter",filter)
                        }
                        return tmp
                    })
                }
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function addReply() {
        if(submitingRef.current){
            return
        }
        submitingRef.current = true
        if(!replyToAdd){
            alert("不能为空")
            submitingRef.current = false
            return
        }
        //长度不能超过2000
        if(replyToAdd.length>2000){
            alert("回复内容不能超过2000个字符")
            submitingRef.current = false
            return
        }
        axios.post("/api/topic/reply/add-to-post",{
            postId:id,
            reply:replyToAdd
        }).then(({data})=>{
            if(data.code===200){
                setReplyToAdd("")
                getReplys()
                localStorage.removeItem("cache_post_add_reply")
            }else{
                Msg.msg(data.msg)
            }
            submitingRef.current = false
        }).catch(err=>{
            submitingRef.current = false
        })
    }


    function starPost(){
        axios.post("/api/topic/post/star",{
            postId:id
        }).then(({data})=>{
            if(data.code===200){
                getPostDetail()
            }else{
                Msg.msg(data.msg,1)
            }
        }).catch(err=>{})
    }
    function unstarPost(){
        axios.post("/api/topic/post/unstar",{
            postId:id
        }).then(({data})=>{
            if(data.code===200){
                getPostDetail()
            }else{
                Msg.msg(data.msg,1)
            }
        }).catch(err=>{})
    }

    function archivePost(){
        axios.post("/api/topic/post/archive",{
            postId:id
        }).then(({data})=>{
            if(data.code === 200){
                getPostDetail()
            }
        })
    }
    function unarchivePost(){
        axios.delete("/api/topic/post/archive",{
            params:{
                postId:id
            }
        }).then(({data})=>{
            if(data.code === 200){
                getPostDetail()
            }
        })
    }



    // 
    function getNicknameOrUsername(user){
        if(!user){
            return ""
        }
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }



    // 顶踩收藏评论
    function starReply(e){
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Msg.msg(data.msg,1)
            }
        }).catch(err=>{})
    }
    function unstarReply(e){
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Msg.msg(data.msg,1)
            }
        }).catch(err=>{})
    }
    function archiveReply(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = true
                    }
                })
                setReplys({...replys,list:tmps})
            } 
        }).catch(err=>{})
    }
    function unarchiveReply(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = false
                    }
                })
                setReplys({...replys,list:tmps})
            }    
        }).catch(err=>{})
    }


}