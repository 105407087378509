import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { axios } from "../../../../axios"


import style from "./index.module.css"
import { useNavigate } from "react-router-dom"
import Confirm from "../../../my-com/confirm"
import Flashs from "./flashs"
import Posts from "./posts"
import Replies from "./replies"
import ArchivedPosts from "./archived.posts"
import ArchivedReplies from "./archived.replies"
import { AvatarBaseURL, AvatarDefault, CACHE, shareText } from "../../../../tools"
import { PullRefresh, Tabs, Toast } from "react-vant"

export default function UserProfile({username,userId}) {
    const navi = useNavigate()


    const [profile,setProfile] = useState({})


    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const tmp = scrollRef.current
        return ()=>{
            //离开时获取滚动条位置
            if(tmp){
                localStorage.setItem("user-scroll",tmp.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[])

    
 
    function setScroll(v){
        if(scrollRef.current){
            let container = scrollRef.current
            if(v===0){
                container.scrollTop = 0
                localStorage.setItem("user-scroll",0)
            }else{
                container.scrollTop = localStorage.getItem("user-scroll")
            }
        }
    }


    const [hisCache,setHisCache] = useState({
        userId:userId,
        username:username,
        activeTab:null, //0 动态 1 话题 2 回复
        topicActiveTab:null, //0 他的 1 收藏
        replyActiveTab:null,//0 他的 1 收藏
        flashPage:1,
        topicPage:1,
        topicArchivedPage:1,
        replyPage:1,
        replyArchivedPage:1,

    })
    //首次加载 从ls里获取启动tab
    useEffect(()=>{
        let hisConfigStr = localStorage.getItem("hisProfileConfig")
        let create = false;
        if(!hisConfigStr){
            create = true;
        }else{
            let tmp = JSON.parse(hisConfigStr);
            if(tmp.username!==username && tmp.userId!==userId){
                create = true;
            }
        }
        if(create){
            let hisCacheConfig = {
                userId:userId,
                username:username,
                activeTab:0, //0 动态 1 话题 2 回复
                topicActiveTab:null, //0 他的 1 收藏
                replyActiveTab:null,//0 他的 1 收藏
                flashPage:1,
                topicPage:1,
                topicArchivedPage:1,
                replyPage:1,
                replyArchivedPage:1,
            }
            setHisCache(hisCacheConfig)
            localStorage.setItem("hisProfileConfig",JSON.stringify(hisCacheConfig))
        }else{
            setHisCache(JSON.parse(hisConfigStr))
        }
        getUserProfileFromCache()
        // eslint-disable-next-line
    },[])


    return<div className={style.bg} ref={scrollRef}>
        <PullRefresh onRefresh={() =>{
            return new Promise(resolve => {
                new CACHE("profiles").clear().then(e=>{
                    localStorage.removeItem("hisProfileConfig")
                    Toast.info('正在刷新')
                    new CACHE("ContentOfUser").delByPrefix("user").then(async ()=>{
                        await axios.get('/api/user/profile',{
                            params:{
                                userId:userId?userId:null,
                                username:username?username:null
                            }
                        })
                        .then(({data})=>{
                            if(data.code===200){
                                setProfile(data.data)
                                new CACHE("profiles").add(userId,data.data)
                            }else if(data.code===400){
                                alert(data.msg)
                                navi(-1)
                            }
                        })
                        Toast.info('刷新成功')
                        resolve(true)
                    })
                    
                }
            )})
        }} onRefreshEnd={() => console.log('onRefreshEnd')} >
        <div className={style.container}>
        <div className={style.items}>
            <div className={style.avatarAndAccount}>
                <span className={style.avatar}>
                <img src={AvatarBaseURL+(profile.avatar?profile.avatar:AvatarDefault)} alt="avatar" />
                </span>
                <div>
                    <span className={style.nickname}>{profile.nickname}</span>
                    <span className={style.username} onClick={()=>shareText(profile.username,"账号")}>@{profile.username}</span>
                </div>
            </div>

            <div className={style.itemNextLine}>
                <div className={style.placeholder}>个性签名:</div>
                <div className={style.content}>
                    <span className={style.text}>
                        {profile.signature}
                    </span>
                </div>
            </div>


            <div className={style.followerContainer}>
                <div>
                    <div>
                        <span>{profile.level}</span>
                        <span>等级</span>
                    </div>
                    <div>
                        <span>{profile.followCount}</span>
                        <span>关注</span>
                    </div>
                    <div>
                        <span>{profile.fansCount}</span>
                        <span>粉丝</span>
                    </div>
                    <div>
                        <span>&nbsp;</span>
                        {profile.followed&&<span onClick={unfollow}>已关注</span>}
                        {!profile.followed&&<span onClick={follow}>关注</span>}
                    </div>
                </div>
            </div>
        </div>



        {profile.id&&!profile.privacy&&<div className={style.myActivity}>                    
            <Tabs swipeable active={hisCache.activeTab} color="#333" onChange={(v)=>{
                    let tmp = {...hisCache,activeTab:v}
                    setHisCache(tmp)
                    localStorage.setItem("hisProfileConfig",JSON.stringify(tmp))
                    clearScroll()
                }}>
                <Tabs.TabPane title="动态">
                    {profile.userId&&hisCache.activeTab===0&&<Flashs userId={profile.userId} setScroll={setScroll} />}
                </Tabs.TabPane>
                <Tabs.TabPane title="话题">
                    <div className={style.topicActiveTabs}>
                        <span className={hisCache.topicActiveTab===0?style.active:''} onClick={()=>{
                            let tmp = {...hisCache,topicActiveTab:0}
                            setHisCache(tmp)
                            localStorage.setItem("hisProfileConfig",JSON.stringify(tmp))
                            clearScroll()
                        }}>他的</span>
                        <span className={hisCache.topicActiveTab===1?style.active:''} onClick={()=>{
                            let tmp = {...hisCache,topicActiveTab:1}
                            setHisCache(tmp)
                            localStorage.setItem("hisProfileConfig",JSON.stringify(tmp))
                            clearScroll()
                        }}>收藏</span>
                    </div>
                    {profile.userId&&hisCache.activeTab===1&&hisCache.topicActiveTab===0&&<Posts userId={profile.userId} setScroll={setScroll} />}
                    {profile.userId&&hisCache.activeTab===1&&hisCache.topicActiveTab===1&&<ArchivedPosts userId={profile.userId} setScroll={setScroll} />}
                </Tabs.TabPane>
                <Tabs.TabPane title="回复">
                    <div className={style.topicActiveTabs}>
                        <span className={hisCache.replyActiveTab===0?style.active:''} onClick={()=>{
                            let tmp = {...hisCache,replyActiveTab:0}
                            setHisCache(tmp)
                            localStorage.setItem("hisProfileConfig",JSON.stringify(tmp))
                            clearScroll()
                        }}>他的</span>
                        <span className={hisCache.replyActiveTab===1?style.active:''} onClick={()=>{
                            let tmp = {...hisCache,replyActiveTab:1}
                            setHisCache(tmp)
                            localStorage.setItem("hisProfileConfig",JSON.stringify(tmp))
                            clearScroll()
                        }}>收藏</span>
                    </div>
                    {profile.userId&&hisCache.activeTab===2&&hisCache.replyActiveTab===0&&<Replies userId={profile.userId} setScroll={setScroll} />}
                    {profile.userId&&hisCache.activeTab===2&&hisCache.replyActiveTab===1&&<ArchivedReplies userId={profile.userId} setScroll={setScroll}  />}
                </Tabs.TabPane>
            </Tabs>
        </div>}

        {profile.id&&profile.privacy&&<div className={style.privacy}>
            已开启隐私保护,动态、话题、回复不对外公开 😜    
        </div>}
        </div>

        </PullRefresh>
    </div>



    function getUserProfile(){
        axios.get('/api/user/profile',{
            params:{
                userId:userId?userId:null,
                username:username?username:null
            }
        })
        .then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
            }else if(data.code===400){
                alert(data.msg)
                navi(-1)
            }
        })
    }
    function getUserProfileFromCache(){
        if(userId){
            new CACHE("profiles").get(userId).then(data=>{
                setProfile(data)
            }).catch(()=>{
                axios.get('/api/user/profile',{
                    params:{
                        userId:userId?userId:null,
                        username:username?username:null
                    }
                })
                .then(({data})=>{
                    if(data.code===200){
                        setProfile(data.data)
                        new CACHE("profiles").add(userId,data.data)
                    }else if(data.code===400){
                        alert(data.msg)
                        navi(-1)
                    }
                })
            })
        }else{
            axios.get('/api/user/profile',{
                params:{
                    userId:userId?userId:null,
                    username:username?username:null
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    setProfile(data.data)
                    new CACHE("profiles").add(userId,data.data)
                }else if(data.code===400){
                    alert(data.msg)
                    navi(-1)
                }
            })
        }

    }

    function clearScroll(){
        localStorage.setItem("user-scroll",0)
    }

    function follow(){
        if(!profile.userId){
            return
        }
        axios.post('/api/follower/follow',{
            userId:profile.userId
        })
        .then(({data})=>{
            if(data.code===200){
                getUserProfile()
            }
        })
    }

    function unfollow(){
        if(!profile.userId){
            return
        }
        Confirm.confirm("确定取消关注吗?").then(()=>{
            axios.delete('/api/follower/unfollow',{
                params:{
                    userId:profile.userId
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    getUserProfile()
                }
            })
        })
    }

}









