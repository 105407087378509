import { Link, useNavigate } from "react-router-dom"

import style from "./new.module.css"
import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import { CACHE, localDate } from "../../../tools"
import Page from "../../my-com/page"
import { Down, Ms, UP } from "../../../icons"
// 话题详情页 最新的话题
export function NewList({posts,getPosts,id}){
    const navi = useNavigate()

    useEffect(()=>{
        if(posts.page!==null){
            getPosts(posts.page,false)
        }
        // eslint-disable-next-line
    },[posts.page])
    useEffect(()=>{
        if(posts.page === 1){
            getStickedOfNode()
        }
        // eslint-disable-next-line
    },[posts.page])

    const [sticked,setSticked] = useState({
        list:[]
    })


    return <div className={style.newlist}>
        {posts.page === 1 && sticked.list.map(e=><div key={e.id} className={style.stickedPost}>
            <div>
                <sup style={{fontSize:'0.5em',color:"goldenrod"}}>置顶</sup><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
            </div>
        </div>)}

        {posts.list.map(e=><div key={e.id} className={style.post}>
                <div>
                    <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                </div>

                <div className={style.description} onClick={()=>navi('/topic/post/detail/'+e.id)}>
                    {e.description}
                </div>

                <div>
                <span className={style.ss}>
                    <span className={style.left}>
                        <span><Ms/>{e.replyCount}</span>
                        {e.stared?<span><UP/>{e.star}</span>:<span className={style.un}><UP/>{e.star}</span>}
                        {e.unstared?<span><Down/>{e.unstar}</span>:<span className={style.un}><Down/>{e.unstar}</span>}
                    </span>
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </span>
                    
                </div>
        </div>)}

        <div style={{padding:"1em 0"}}>
            <Page total={posts.total} current={posts.page} pageSize={18} onChange={page=>{
                    getPosts(page,true)
                }}
            />
        </div>
    </div>

    function getStickedOfNode() {
        let cacheKey = "NodeDetail-Sticked-"+id

        new CACHE("API").get(cacheKey).then(data=>{
            setSticked({
                list:data
            })
        }).catch(err=>{
            axios.get("/api/topic/post/stickies-all-and-node", {
                params: {
                    page: posts.page-1,
                    nodeId:id
                }
            }).then(({ data }) => {
                if (data.code === 200) {
                    setSticked({
                        list: data.data
                    })
                    new CACHE("API").add(cacheKey,data.data)
                }
            })
        })
    }

}