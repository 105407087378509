import { Input } from "antd"
import { axios } from "../../../axios"
import { useState } from "react"

import style from "./dislike.module.css"

function Dislike(props){

    const [msg,setMsg] = useState("")

    return <div className={style.bg}>
        <div>
            <div className={style.hint}>
                <p>请认真对待每一次相遇。</p>
                <p>擦肩而过的缘分甚是遗憾，请填写不合适的理由再开始下一份期待。</p>
            </div>

            <div>
                <Input.TextArea 
                    placeholder="请填写终止原因"
                    maxLength={2000}
                    autoSize={{
                            minRows: 2,
                            maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)}/>
            </div>

            <div className={style.btns}>
                <span onClick={dislike}>提交</span> 
                <span onClick={()=>{
                    props.close(false)
                }}>取消</span>
            </div>
        </div>
    </div>

    //不合适
    function dislike(){
        axios.post("/api/square/flow/base-profile/dislike",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                props.close(true)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
}

function Like(props){
    const [msg,setMsg] = useState("")
    return <div className={style.bg}>
        <div>
            <div className={style.hint}>
                <p>请认真对待每一次爱意。尽可能表达适当的友善真实的你。</p>
            </div>

            <div>
                <Input.TextArea placeholder="请填写继续理由" autoSize={{
                    minRows: 2,
                    maxRows: 16,
                }} onChange={v=>setMsg(v.target.value)} />
            </div>

            <div className={style.btns}>
                <span onClick={like}>提交</span> <span onClick={()=>{
                    props.close(false)
                }}>取消</span>
            </div>
        </div>
    </div>

    //继续沟通
    function like(){
        axios.post("/api/square/flow/base-profile/like",{
            sessionId:props.sessionId,
            comment:msg
        }).then(({data})=>{
            if(data.code===200){
                props.close(true)
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
    
}

export {Dislike,Like}