import { Pagination, Select } from "antd"
import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import Alert from "../../my-com/alert"

import style from "./index.module.css"
import Confirm from "../../my-com/confirm"
import { localDate, shareText } from "../../../tools"
import Switch from "../../my-com/switch"


export default function Share(){

    const [showAdd,setShowAdd] = useState(false)
    const [list,setList] = useState({
        total:0,
        page:1,
        list:[]
    })
    useEffect(()=>{
        getList(1)
        // eslint-disable-next-line
    },[])
    return <div className={style.container}>
        <div>
            <span className={style.addBtn} onClick={()=>setShowAdd(true)}>新建访问密钥</span>
        </div>
        <div className={style.tip}>
            <p>最安全、隐私的资料交换功能</p>
            <p>其他人可通过你分享的密钥和你互换基本资料，进入匹配流程。在互相确认前资料中的置灰项目会被隐藏，以保护双方隐私。</p>
        </div>

        <div className={style.shareList}>
            {list.list.map(e=><div id={e.id} key={e.id} className={style.shareItem}>
                <p className={style.key}>
                    <span>{e.passkey}</span>
                </p>
                <p className={style.expire}>
                    {e.type===0&& <>
                        <span style={{fontSize:'0.9em',color:'#666'}}>有效期至</span>
                        <span>{localDate(e.disableTime)}</span>
                    </>}
                    {e.type===1&& <>
                        <span style={{fontSize:'0.9em',color:'#666'}}>剩余次数</span>
                        <span>{e.disableTimes}</span>
                    </>}
                </p>

                <p className={style.itemBtns}>
                    {e.status===1&&<span style={{backgroundColor:'red'}}
                        onClick={()=>destroy(e.id)}
                    >已失效，请销毁</span>}
                    {e.status===0&&<span onClick={()=>destroy(e.id)}>销毁</span>}
                    <span style={{backgroundColor:'#9E9E9E',color:'wheat'}}
                        onClick={()=>{
                            shareText("sp密钥:"+e.passkey,"密钥")
                        }}>复制</span>
                    
                </p>
            </div>)}

            {list.total>=18&&<Pagination 
                total={list.total}
                simple 
                pageSize={18} 
                current={list.page}
                onChange={(page)=>{
                    getList(page)
                }} />}
        </div>

        {showAdd && <Add close={()=>setShowAdd(false)} refresh = {()=>{
            getList(1)
            setShowAdd(false)
        }} />}
    </div>

    function destroy(id) {
        Confirm.confirm("确认销毁？").then((data)=>{
            axios.delete("/api/square/share",{
                params:{
                    id
                }
            }).then(({data})=>{
                if(data.code===200){
                    getList(1)
                }
            }).catch(err=>console.log(err))
        }).catch(()=>{})

    }


    function getList(page) {
        axios.get("/api/square/share/list",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setList({...list,...data.data,page})
            }
        }).catch(err=>console.log(err))
    }
}


function Add(props){
    const [expireType,setExpireType] = useState(1)
    const [diy,setDiy] = useState(false)

    const [key,setKey] = useState(null)

    return <div className={style.add}>
        
        <div>
            <p>密钥到期或到达您指定的人次后会自动失效销毁。</p>
            <div className={style.timeAndTimes}>
                <span>
                    有效：
                        <Select style={{width:'7em'}}
                        onChange={v=>{
                            setExpireType(v)
                        }}
                        defaultValue={'1'}
                        options={[
                        {
                            value: '1',
                            label: '1天',
                        },
                        {
                            value: '2',
                            label: '7天',
                        },
                        {
                            value: '3',
                            label: '30天',
                        },
                        {
                            value: '4',
                            label: '1人|次'
                        },
                        {
                            value: '5',
                            label: '7人|次'
                        },
                        {
                            value: '6',
                            label: '30人|次'
                        }
                    ]} />
                </span>
                
                <Switch value={diy} back={(v)=>{
                    setDiy(v)
                    setKey('')
                }}/>
            </div>

            <div className={style.diyInputC}>
                {<input className={diy?style.active:''} value={key} onChange={v=>{
                    let tmp = v.target.value
                    if(tmp.length > 15){
                        alert("不能超过15个字")
                        return
                    }
                    setKey(tmp)
                }} />}
            </div>

            <div className={style.btns}>
                <span onClick={submit}>提交</span>
                <span onClick={()=>props.close()}>取消</span>
            </div>
       
        </div>
        
    </div>

    function submit() {
        if(!expireType){
            Alert.alert("fuck")
            return
        }
        if(key && key.length<5){
            alert("自定义密钥需大于5个及以上字符")
            return
        }
        axios.post("/api/square/share",{
            type:expireType,
            key:key
        }).then(({data})=>{
            if(data.code===200){
                props.refresh()
            }else{
                alert(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}