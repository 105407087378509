import { useEffect, useState } from "react"

import style from "./index.module.css"
import { axios } from "../../../axios"
import { genderStr, getSexRole } from "../profile/dict"
import { useNavigate } from "react-router-dom"
import { Dislike, Like } from "./dislike"
import { SettingFilled } from "@ant-design/icons"
import { SettingPannel } from "../setting/pannel"


export default function FindYou(){

    const navi = useNavigate()

    const [profile,setProfile] = useState(null)

    const [stopMsg,setStopMsg] = useState({
        error:false,
        msg:""
    }) 
    const [findding,setFindding] = useState(null)

    const [showWarning,setShowWarning] = useState(true)

    const [showSetting,setShowSetting] = useState(false)

    useEffect(()=>{
    },[])

    const [showDislike,setShowDislike] = useState(false)
    const [showLike,setShowLike] = useState(false)

    return <div className={style.container}>
            
        {showWarning&& <div className={style.warning}>
            <p onClick={()=>{setShowSetting(true)}} className={style.setBtn}>
                <span><SettingFilled /> 设置</span>
            </p>
            <div>
                <div className={style.title}>
                    <div>
                        <p>请认真对待每一次缘分。</p>
                        <p>未匹配前将<b style={{color:'brown'}}>互相看不到对方联系方式</b>，需要匹配后再查看对方资料进行下一步。</p>
                    </div>
                </div>

                <div className={[style.stopMsg,
                    findding!==null?style.active:'',
                    stopMsg.error?style.stopMsgBorder:''].join(" ")}>
                    {stopMsg.error && <h4>{stopMsg.msg}</h4>}
                    {findding&&!stopMsg.error&&<div className={style.findding}>
                        正在寻找
                    </div>}
                    {findding!==null&&!findding&&!stopMsg.error&&<div className={style.findded}>
                        已找到
                    </div>}
                </div>

                {findding!==null&&!findding&&<div className={style.btnC}>
                    <span className={style.btn} onClick={()=>{
                        if(stopMsg.error){
                            navi("/square")
                        }else{
                            setShowWarning(false);
                        }
                    }}>我知道了</span>
                </div>}
                
                {findding===null&&<div className={style.btnC}>
                    <span className={style.btn} onClick={()=>{
                        setFindding(true)
                        setTimeout(()=>{
                            getRecommend()
                        },2000)
                    }}>开始寻他</span>
                </div>}
            </div>
        </div>}


        {!showWarning&& profile!=null &&<div>
            <div className={style.profile}>
                <h4><span className={style.gray}>灰色</span>条目匹配后可见</h4>
                
                <div className={style.item}>
                    <span>昵称</span>
                    <div>{profile.name}</div>
                </div>

                <div className={style.item} >
                    <span>性别</span>
                    <div>{genderStr(profile.gender)}</div>
                </div>

                <div className={style.item}>
                    <span>年龄</span>
                    <div>{profile.age}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<17))!==0?style.hide:""].join(" ")}>
                    <span>生日</span>
                    <div>{profile.birthday}</div>
                </div>

                <div className={style.signature}>
                    <span>个性签名</span>
                    <div>{profile.signature}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<14))!==0?style.hide:""].join(" ")}>
                    <span>性偏向</span>
                    <div>{(profile.hideCode&(1<<14))===0?getSexRole(profile.sexRole):""}</div>
                </div>

                <div className={style.item}>
                    <span>身高</span>
                    <div>{profile.height}</div>
                </div>
                <div className={style.item}>
                    <span>体重</span>
                    <div>{profile.weight}</div>
                </div>
                <div className={style.item}>
                    <span>所在城市</span>
                    <div>
                        {profile.province?profile.province:''} {profile.city?profile.city:''}
                    </div>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<10))!==0?style.hide:""].join(" ")}>
                    <span>家乡</span>
                    <span>{profile.hometown}</span>
                </div>

                <div className={style.item}>
                    <span>职业</span>
                    <span>{profile.job}</span>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<8))!==0?style.hide:""].join(" ")}>
                    <span>年薪</span>
                    <span>{profile.salary}</span>
                </div>
                <div className={style.item}>
                    <span>学历</span>
                    <span>{profile.education}</span>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<6))!==0?style.hide:""].join(" ")}>
                    <span>学校</span>
                    <span>{profile.school}</span>
                </div>

                <div className={style.item}>
                    <span>专业</span>
                    <span>{profile.major}</span>
                </div>
                <div className={[style.detail,(profile.hideCode&(1<<4))!==0?style.hide:""].join(" ")}>
                    <span>详细描述</span>
                    <div>
                        {profile.detail}
                    </div>
                </div>
                <div className={[style.item,style.hide].join(" ")}>
                    <span>微信</span>
                    <span>{profile.wechat}</span>
                </div>
                <div className={[style.item,style.hide].join(" ")}>
                    <span>联系方式</span>
                    <span>{profile.contact}</span>
                </div>
            </div>

            <div className={style.confirmBtns}>
                <div>
                    <span onClick={()=>{
                        setShowDislike(true)
                    }}>不合适</span>
                    <span onClick={()=>{
                        setShowLike(true)
                    }}>继续沟通</span>
                </div>
            </div>

        </div>}


        {showDislike&&<Dislike sessionId={profile.sessionId} close={(v)=>{
            setShowDislike(false)
            //执行了不喜欢操作成功
            if(v===true){
                //回到寻他首页、
                navi("/square/paired/"+profile.sessionId)
            }
        }} />}
        {showLike&&<Like sessionId={profile.sessionId} close={(v)=>{
            setShowLike(false)
            //执行了喜欢操作成功
            if(v===true){
                //去到擦肩记录
                navi("/square/paired/"+profile.sessionId)
            }
        }} />}

        {showSetting&&<SettingPannel close={()=>setShowSetting(false)} />}
    </div>

    //获取推介
    function getRecommend(){
        axios.get("/api/square/yuelao/recommend").then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
            }else{
                setStopMsg({msg:data.msg,error:true})
            }
            setFindding(false)
        }).catch(err=>{
            console.log(err)
        })
    }



}


