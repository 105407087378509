
import style from "./index.module.css"
import { useRef, useState } from "react"
import { SettingFilled } from "@ant-design/icons";
import { SettingPannel } from "./pannel";

export default function SquareSetting({setEnable}){

    const [setting,setSetting] = useState({
        show:false,
        base:false,
        age:false,
        tmpAgeStart:'',
        tmpAgeEnd:'',
    })

    const testRef = useRef(null)

    


    return <div className={style.container}>

        <div>
            <p className={style.settingBtn}onClick={()=>setSetting({show:true})}>
                <span><SettingFilled/> 设置</span>
            </p>
        </div>

        <div className={style.header}>
            <div className={style.a}>
                <div>太多快餐式的同性感情，</div>
                <div>基于社会压力也好、自我认同问题也好，</div>
                <div>既渴望又恐惧走入一段稳定关系。</div>
                <div>如果你不喜欢这样，</div>
                <div>欢迎加入浮尘开始寻他。</div>
                <div>寻一个志同道合的他，</div>
                <div>体会生活，感受爱与被爱。</div>
            </div>
            <div className={style.b}></div>
        </div>

        <div className={style.test}>
            <div>
                <div style={{
                    textAlign:'center',paddingTop:'10px',fontWeight:'bold'
                }}>寻他流程与规则</div>
            </div>
            <div className={style.scrollContent}  ref={testRef}>
                <div>
                    <div>
                        <b>第一步 确认资料。</b>
                    </div>
                    <div>
                        基本资料合适的话，双方都确认后才会彼此释放联系方式和其他置灰隐藏资料，进入下一步。不合适结束此次缘分。
                    </div>
                </div>
                <div>
                    <div>
                        <b>第二步 确认形象。</b>
                    </div>
                    <div>
                    通过第一步释放的双方联系方式，二人进行基本的沟通。确认外形。
                        
                    </div>
                    <div>
                    ps：萝卜白菜各有所爱，不合适不代表不好。好比我们的产品他就喜欢喜欢矮一点的，不代表高个子的人很差。
                    双方都觉得合适，进入下一步。不合适结束此次缘分。
                    </div>
                </div>
                <div>
                    <div>
                        <b>第三步 试相处一周。</b> 
                    </div>
                    <div>
                        双方已经有了基本的沟通，也是彼此喜欢的类型，可以试相处一星期。一周后在此做出相处反馈。双方反馈都合适的话，确定关系。不合适则结束此次缘分。
                    </div>
                </div>
                <div>
                    <div>双方结成伴侣关系后，系统自动关闭二人的匹配功能，彼此绑定为对方的"我的爱人"</div>
                </div>
                <div>
                    <div>流程总时间超过10天且对方未反馈，会出现结束按钮，可选择主动结束。</div>
                    <div>
                        缘分易逝，请及时处理。
                    </div>
                </div>
                <div>
                    <div>此功能目的是为了寻找长久的亲密关系，不是约炮工具。不是约炮工具。不是约炮工具。
                    <b><i>如果你的目标不是一段长久稳定的亲密关系，请去其他合适的平台。</i></b></div>
                </div>
                <div>
                    <div>
                    如果你已有爱人或固定的亲密关系，请不要开启此功能。点击上面设置，关闭寻他功能。
                    </div>
                    <div>
                    关闭后你的资料将会隐藏，其他人不再可见。同时你也不能再查看别人的资料。
                    </div>
                </div>
            </div>

            <div className={style.scrollBtns}>
                <span className={style.left} onClick={handleMoveLeft}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="left" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path></svg>
                </span>
                <span className={style.right} onClick={handleMoveRight}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="right" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path></svg>
                </span>
            </div>
        </div>

        {setting.show&&<SettingPannel close={()=>setSetting({show:false})}/>}
        
    </div>

    function handleMoveLeft() {
        let width = testRef.current.offsetWidth
        //获取当前滚动条的位置，然后减去一个屏幕的宽度
        let scrollLeft = testRef.current.scrollLeft

        console.log(width,scrollLeft)
        let tmp = scrollLeft % width
        scrollLeft = scrollLeft - (tmp)
        if(tmp !== 0){
            testRef.current.scrollLeft =  scrollLeft
        }else{
            testRef.current.scrollLeft = scrollLeft - width
        }
    }
    function handleMoveRight() {
        testRef.current.scrollLeft += testRef.current.offsetWidth
        let width = testRef.current.offsetWidth
        let scrollLeft = testRef.current.scrollLeft
        console.log(width,scrollLeft)

        let tmp = scrollLeft % width
        if(tmp !== 0){
            testRef.current.scrollLeft = scrollLeft + (width - tmp)
        }else{
            testRef.current.scrollLeft = scrollLeft + width
        } 
    }
    
}