import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { axios } from "../../../axios"
import { Link, useNavigate } from "react-router-dom"

import style from "./index.module.css"
import { CACHE, localDate, toFold } from "../../../tools"
import Msg from "../../my-com/Msg"
import Page from "../../my-com/page"
import { Down, Ms, UP } from "../../../icons"
import { PullRefresh, Tabs, Toast } from "react-vant"


export default function TopicHome(props){
    const navi = useNavigate()


    const [topicConfig,setTopicConfig] = useState({
        activeTab:0, // 0最新 1精选 2精选回复
        newPage:1,
        selectedPage:1,
        replyPage:1
    })
    useEffect(()=>{
        let topicConfig = localStorage.getItem("topicConfig")
        let create = false;
        if(!topicConfig){
            create = true;
        }
        if(create){
            let tmp = {
                activeTab:0, // 0最新 1精选 2精选回复
                newPage:1,
                selectedPage:1,
                replyPage:1
            }
            topicConfig = JSON.stringify(tmp)
            localStorage.setItem("topicConfig",JSON.stringify(tmp))
        }
        setTopicConfig(JSON.parse(topicConfig))

        const tmp = JSON.parse(topicConfig);
        if(tmp.activeTab===0){
            getPosts(tmp.newPage,false)
            getStickedOfAll()
        }else if(tmp.activeTab===1){
            getSelectedPost(tmp.selectedPage,false)
        }else if(tmp.activeTab===2){
            getSelectedReplyPost(tmp.replyPage,false)
        }

        getUserArchivedNodes()
        // eslint-disable-next-line
    },[])
    function updateConfig(tmp){
        localStorage.setItem("topicConfig",JSON.stringify(tmp))
        setTopicConfig(tmp)
    }


    const [archivedNodes,setArchivedNodes] = useState([])

    // 最新话题列表
    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })

    // 置顶的话题
    const [sticked,setSticked] = useState({
        list:[]
    })

    // 精选话题列表
    const [selectedList,setSelectedList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })

    // 精选回复列表
    const [selectedReplyList,setSelectedReplyList] = useState({
        list:[],
        total:0,
        page:1,
        pageSize:18
    })


    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const container = scrollRef.current
        return()=>{
            if(container){
                props.setHomeScrollTop(container.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[props.homeScrollTop])

    useEffect(()=>{
        if(scrollRef.current){
            let container = scrollRef.current
            container.scrollTop = props.homeScrollTop
        }
        // eslint-disable-next-line
    },[scrollRef.current,props.homeScrollTop,postList,archivedNodes,selectedList])
    


    return <div className={style.container} ref={scrollRef}>

        <div className={style.header}>
            <div>
                <span className={style.dingwei} onClick={()=>navi("/locate")}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="expand" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M342 88H120c-17.7 0-32 14.3-32 32v224c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V168h174c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zm578 576h-48c-8.8 0-16 7.2-16 16v176H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h222c17.7 0 32-14.3 32-32V680c0-8.8-7.2-16-16-16zM342 856H168V680c0-8.8-7.2-16-16-16h-48c-8.8 0-16 7.2-16 16v224c0 17.7 14.3 32 32 32h222c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zM904 88H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h174v176c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V120c0-17.7-14.3-32-32-32z"></path></svg>
                </span>
                <span className={style.shuaxin} onClick={()=>{
                        // 清除缓存 回到第一页
                        new CACHE("API").clear().then(e=>{
                            getPosts(1,true)
                            getUserArchivedNodes()
                            getStickedOfAll()
                        })
                        Msg.msg("正在刷新列表")
                    }}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="reload" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path></svg>
                </span>
                <span className={style.add} onClick={()=>{navi("/topic/add")}}>
                    <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-square" width="30px" height="30px" fill="currentColor" aria-hidden="true"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path></svg>
                </span>
            </div>
        </div>


        <PullRefresh
                headHeight={200}
                onRefresh={() => refresh(true)}
                onRefreshEnd={() => console.log('onRefreshEnd')}>

        <div className={style.myNodesBg}>
            <h3 style={{display:'flex',gap:'4px',alignItems:'center'}}>
                <span>我的节点</span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M1 11C6.52285 11 11 6.52285 11 1H13C13 6.52285 17.4772 11 23 11V13C17.4772 13 13 17.4772 13 23H11C11 17.4772 6.52285 13 1 13V11ZM5.80342 12C8.56895 13.2093 10.7907 15.431 12 18.1966C13.2093 15.431 15.431 13.2093 18.1966 12C15.431 10.7907 13.2093 8.56895 12 5.80342C10.7907 8.56895 8.56895 10.7907 5.80342 12Z"></path></svg>
            </h3>
            <div className={style.myNodes}>
                {archivedNodes.map(e=><div key={e.id} className={style.archivedTopic}>
                    <Link to={'/topic/node/detail/'+e.id}>{e.node}</Link>
                </div>)}
            </div>
            <div className={style.toAllTopicBtn}>
                <Link to={'/topic/node/all'}>所有节点</Link>
            </div>
        </div>

        <div className={style.contents}>
            <Tabs swipeable color="#333" active={topicConfig.activeTab} onChange={(v)=>{
                        if(v===0){
                            getPosts(1,true)
                            getStickedOfAll()
                        }else if(v===1){
                            getSelectedPost(1,true)
                        }else if(v===2){
                            getSelectedReplyPost(1,true)
                        }
                    }}>
                <Tabs.TabPane title="最新">
                    {/* 最新 */}
                    {topicConfig.activeTab===0&&<div className={style.newest}>
                        {postList.page === 1 &&<div className={style.stickedPost}>
                            {postList.page === 1 && sticked.list.map(e=><div key={e.id}>
                                <div>
                                    <span style={{color:'#666',fontWeight:'bold'}} onClick={()=>{
                                        navi('/topic/node/detail/'+e.nodeId)
                                    }}>{e.node}·</span>
                                    <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                                </div>
                            </div>)}
                        </div>}

                        <div className={style.newestPosts}>
                            {postList.list.map(e=><div key={e.id}>
                                    <span style={{color:'#666',fontWeight:'bold'}} onClick={()=>{
                                        navi('/topic/node/detail/'+e.nodeId)
                                    }}>{e.node}·</span>
                                    <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                                </div>)}

                            <div>
                                <Page
                                    total={postList.total}
                                    current={postList.page}
                                    onChange={p=>getPosts(p,true)}
                                    pageSize={18}
                                />
                            </div>
                        </div>
                    </div>}
                </Tabs.TabPane>
                <Tabs.TabPane title="精选">
                    {/* 精选 */}
                    {topicConfig.activeTab===1&&<div className={style.selectedPosts}>
                        {selectedList.list.map(e=><div key={e.id}>
                                <span style={{fontWeight:'bold',color:'#666'}} onClick={()=>{
                                    navi('/topic/node/detail/'+e.nodeId)
                                }}>{e.node}·</span>
                                <Link to={'/topic/post/detail/'+e.id}>{e.title}</Link>
                            </div>)}

                        <div>
                            <Page
                                total={selectedList.total}
                                current={selectedList.page}
                                onChange={p=>getSelectedPost(p,true)}
                                pageSize={18}
                            />
                        </div>
                    </div>}
                </Tabs.TabPane>
                <Tabs.TabPane title="精选回复">
                    {/* 精选回复 */}
                    {topicConfig.activeTab===2&&<div className={style.selectedReplyContainer}>
                        {selectedReplyList.list.map(e=><div key={e.id} className={style.selectedReplyItem}>
                            <div className={style.postTitle}>
                                <p>
                                    <Link to={'/topic/post/detail/'+e.postId}>{e.post}</Link>
                                </p>
                            </div>
                            <div className={style.replyContentContainer}>
                                <p className={toFold(e.content)?style.hideReplyContent:style.replyContent}>
                                    <Link to={'/topic/post/reply/'+e.id}>{e.content}</Link>
                                </p>

                                {toFold(e.content)&&<>{
                                    <p className={style.folder}>
                                        <span>
                                            <Link style={{fontSize:"0.9em",color:"#ababab"}} to={'/topic/post/reply/'+e.id}>查看全部</Link>
                                        </span>
                                    </p>}</>}

                                <p className={style.btns}>
                                    <span className={style.btn}>
                                        <span><UP/>{e.star}</span>
                                        <span><Down/>{e.unstar}</span>
                                        <span><Ms/>{e.replyCount}</span>
                                    </span>
                                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                                </p>
                            </div>
                            </div>)}

                        <div style={{padding:'1em'}}>
                            <Page
                                total={selectedReplyList.total}
                                current={selectedReplyList.page}
                                onChange={p=>getSelectedReplyPost(p,true)}
                                pageSize={18}
                            />
                        </div>
                    </div>}
                </Tabs.TabPane>
            </Tabs>

        </div>
        </PullRefresh>
    </div>



    
function getPosts(page,changeURL){
    //从缓存获取
    let cacheKey = "TOPIC-posts"+page
    new CACHE('API').get(cacheKey).then(data=>{
        if(data){
            setPostList({
                ...postList,
                total:data.total,
                list:data.list,
                page:page
            })
            if(changeURL){
                updateConfig({...topicConfig,activeTab:0,newPage:page})
            }
        }else{
            axios.get("/api/topic/post/list",{
                params:{
                    page:page-1
                }
            }).then(({data})=>{
                if(data.code === 200){
                    setPostList({
                        ...postList,
                        total:data.data.total>54?54:data.data.total,
                        list:data.data.list,
                        page:page?page:postList.page
                    })
                    new CACHE('API').add(cacheKey,{
                        total:data.data.total,
                        list:data.data.list
                    })
                    if(changeURL){
                        updateConfig({...topicConfig,activeTab:0,newPage:page})
                    }
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }).catch(err=>{
        axios.get("/api/topic/post/list",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostList({
                    ...postList,
                    total:data.data.total,
                    list:data.data.list,
                    page:page?page:postList.page
                })
                new CACHE('API').add(cacheKey,{
                    total:data.data.total,
                    list:data.data.list
                })
                if(changeURL){
                    updateConfig({...topicConfig,activeTab:0,newPage:page})
                }
            }
        }).catch(err=>{
            console.log(err)
        })
    })

}
    //获取收藏的话题
    function getSelectedPost(page,changeURL){
        let cacheKey = "TOPIC-selected-"+page
        new CACHE('API').get(cacheKey).then(data=>{
            if(data){
                setSelectedList({
                    ...selectedList,
                    total:data.total,
                    list:data.list,
                    page:page
                })
                if(changeURL){
                    updateConfig({...topicConfig,activeTab:1,selectedPage:page})
                }
            }else{
                axios.get("/api/topic/post/selected",{
                    params:{
                        page:page?page-1:selectedList.page-1
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        setSelectedList({
                            ...selectedList,
                            total:data.data.total,
                            list:data.data.list,
                            page:page
                        })
                        new CACHE('API').add(cacheKey,{
                            total:data.data.total,
                            list:data.data.list
                        })
                        if(changeURL){
                            updateConfig({...topicConfig,activeTab:1,selectedPage:page})
                        }
                    }
                }).catch(err=>{})
            }
        }).catch(err=>{
            axios.get("/api/topic/post/selected",{
                params:{
                    page:page?page-1:selectedList.page-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    setSelectedList({
                        ...selectedList,
                        total:data.data.total,
                        list:data.data.list,
                        page:page,
                    })
                    new CACHE('API').add(cacheKey,{
                        total:data.data.total,
                        list:data.data.list
                    })
                    if(changeURL){
                        updateConfig({...topicConfig,activeTab:1,selectedPage:page})
                    }
                }else{
                    Msg.msg(data.msg)
                }
            }).catch(err=>{
                console.log(1,err)
            })
        }).catch(err=>{
            console.log(2,err)
        })

    }


    //从缓存获取 超过10次，从服务器获取
    function getUserArchivedNodes(){
        let cacheKey = "TOPIC-archivedNodes"
        new CACHE('API').get(cacheKey).then(data=>{
            if(data){
                if(data.times<1){
                    axios.get("/api/topic/node/archived",{
                        params:{
                        }
                    }).then(({data})=>{
                        //获取数据成功才缓存
                        if(data.code===200){
                            setArchivedNodes(data.data)
                            let cacheData = {
                                times:10,
                                data:data.data
                            }
                            new CACHE('API').del(cacheKey).then(()=>{
                                new CACHE('API').add(cacheKey,cacheData)
                            })
                        }
                    }).catch(err=>{})
                }else{
                    setArchivedNodes(data.data)
                    new CACHE('API').del(cacheKey).then(()=>{
                        new CACHE('API').add(cacheKey,{
                            times:data.times-1,
                            data:data.data
                        })
                    })
                }
            }
        }).catch(err=>{
            axios.get("/api/topic/node/archived",{
                params:{
                }
            }).then(({data})=>{
                if(data.code===200){
                    let cacheData = {
                        times:10,
                        data:data.data
                    }
                    new CACHE('API').add(cacheKey,cacheData)
                    setArchivedNodes(cacheData.data)
                }
            }).catch(err=>{})
        })

    }


    //获取置顶的话题
    function getStickedOfAll() {
        let cacheKey = "TOPIC-sticked"
        new CACHE('API').get(cacheKey).then(data=>{
            if(data){
                setSticked({
                    list:data.data
                })
                new CACHE('API').del(cacheKey).then(()=>{
                    new CACHE('API').add(cacheKey,{
                        times:data.times-1,
                        data:data.data
                    })
                })
            }else{
                axios.get("/api/topic/post/stickies-all", {
                }).then(({ data }) => {
                    if (data.code === 200) {
                        setSticked({
                            list: data.data
                        })
                        let cacheData = {
                            times:5,
                            data:data.data
                        }
                        new CACHE('API').del(cacheKey).then(()=>{
                            new CACHE('API').add(cacheKey,cacheData)
                        })
                    }
                }).catch(err=>{console.log(err)})
            }
        }).catch(err=>{
            axios.get("/api/topic/post/stickies-all", {
            }).then(({ data }) => {
                if (data.code === 200) {
                    let cacheData = {
                        times:5,
                        data:data.data
                    }
                    new CACHE('API').add(cacheKey,cacheData)
                    setSticked({
                        list: data.data
                    })
                }
            }).catch(err=>{console.log(err)})
        })
    }



    // 获取收藏的回复
    function getSelectedReplyPost(page,changeURL){
        let cacheKey = "TOPIC-selectedReply-"+page
        new CACHE('API').get(cacheKey).then(data=>{
            if(data){
                setSelectedReplyList({
                    ...selectedReplyList,
                    total:data.total,
                    list:data.list,
                    page:page
                })
                if(changeURL){
                    updateConfig({...topicConfig,activeTab:2,replyPage:page})
                }
            }else{
                axios.get("/api/topic/reply/selectedReply",{
                    params:{
                        page:page?page-1:selectedReplyList.page-1
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        setSelectedReplyList({
                            ...selectedReplyList,
                            total:data.data.total,
                            list:data.data.list,
                            page:page
                        })
                        new CACHE('API').add(cacheKey,{
                            total:data.data.total,
                            list:data.data.list
                        })
                        if(changeURL){
                            updateConfig({...topicConfig,activeTab:2,replyPage:page})
                        }
                    }
                }).catch(err=>{})
            }
        }).catch(err=>{
            axios.get("/api/topic/reply/selected",{
                params:{
                    page:page?page-1:selectedList.page-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    setSelectedReplyList({
                        ...selectedReplyList,
                        total:data.data.total,
                        list:data.data.list,
                        page:page,
                    })
                    new CACHE('API').add(cacheKey,{
                        total:data.data.total,
                        list:data.data.list
                    })
                    if(changeURL){
                        updateConfig({...topicConfig,activeTab:2,replyPage:page})
                    }
                }else{
                    Msg.msg(data.msg)
                }
            }).catch(err=>{
                console.log(1,err)
            })
        }).catch(err=>{
            console.log(2,err)
        })

    }



    async function refresh(){
        return new Promise(resolve => {
            new CACHE("API").clear().then(async e=>{
                Toast.info('正在刷新')
                await axios.get("/api/topic/post/list",{
                    params:{
                        page:0
                    }
                }).then(({data})=>{
                    if(data.code === 200){
                        setPostList({
                            ...postList,
                            total:data.data.total>54?54:data.data.total,
                            list:data.data.list,
                            page:1
                        })
                        let cacheKey = "TOPIC-posts"+1
                        new CACHE('API').add(cacheKey,{
                            total:data.data.total,
                            list:data.data.list
                        })
                        updateConfig({...topicConfig,activeTab:0,newPage:1})
                    }
                }).catch(err=>{
                    console.log(err)
                })

                await axios.get("/api/topic/node/archived",{
                    params:{
                    }
                }).then(({data})=>{
                    //获取数据成功才缓存
                    if(data.code===200){
                        setArchivedNodes(data.data)
                        let cacheData = {
                            times:10,
                            data:data.data
                        }
                        let cacheKey = "TOPIC-archivedNodes"
                        new CACHE('API').del(cacheKey).then(()=>{
                            new CACHE('API').add(cacheKey,cacheData)
                        })
                    }
                }).catch(err=>{})

                await axios.get("/api/topic/post/stickies-all", {
                }).then(({ data }) => {
                    if (data.code === 200) {
                        setSticked({
                            list: data.data
                        })
                        let cacheData = {
                            times:5,
                            data:data.data
                        }
                        let cacheKey = "TOPIC-sticked"
                        new CACHE('API').del(cacheKey).then(()=>{
                            new CACHE('API').add(cacheKey,cacheData)
                        })
                    }
                }).catch(err=>{console.log(err)})
                Toast.info('刷新成功')
                resolve(true)
            })
        })
    }
}
