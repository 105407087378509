
import { useState } from "react"
import { axios } from "../../../axios"
import { VERSION } from "../../../tools"
import style from "./index.module.css"
import { useEffect } from "react"
import Confirm from "../../my-com/confirm"
export default function Help(){

    const [version,setVersion] = useState({
        version:'',
        downloadURL:''
    })

    useEffect(()=>{
        getVersion()
    },[])


    return<div className={style.container}>
        <h2>使用帮助·必读</h2>

        <div className={style.hint}>
            <p>
                浮尘社区采用PWA应用架构。PWA应用简单，不需要下载安装，在浏览器打开是一个网站，添加到主屏幕打开就成了一个应用。
            </p>
            <p>
                因为PWA应用无法获取包括地理位置、手机数据等任何隐私内容，所以能保证用户的绝对隐私和数据安全。
            </p>
            
        </div>

        <div className={style.install}>
            <div className={style.ios}>
                <span>iPhone用户</span>
                <div>
                    <p>使用Safari浏览器打开 <span style={{fontWeight:'bold',color:'goldenrod'}}>suspar.com</span>，然后点击底部的分享按钮，选择添加到主屏幕即可。</p>
                    <div>
                        <p>第一步:</p>
                        <img src="/static/guide-step1.png" alt="" />
                    </div>
                    <div>
                        <p>第二步:</p>
                        <img src="/static/guide-step2.png" alt="" />
                    </div>
                </div>
            </div>

            <div className={style.android}>
                <span>Android用户</span>
                <div>
                    <p>
                        Firefox浏览器打开网页 <span style={{fontWeight:'bold',color:'goldenrod'}}>suspar.com</span>,菜单或者分享按钮 - 添加应用到主屏幕
                    </p>
                    <p style={{fontWeight:'bold'}}>或者下载安装包,安装</p>
                    <p>
                        <span className={style.androidDownload} onClick={()=>{
                            Confirm.confirm("确认下载？").then(()=>{
                                download()
                            })
                        }}>下载最新安卓包</span>
                    </p>
                    <p>
                        <span>服务器最新版本: {version.version}</span>
                    </p>

                    <p><span>当前版本：{VERSION}</span></p>

                </div>
            </div>
        </div>
        
        <div className={style.bottom}>
            <h4>如果出现白屏打不开，请先尝试从主屏幕删除浮尘图标，重新在浏览器中输入网址<b style={{color:'goldenrod'}}>suspar.com</b>打开，再将网页分享到主屏幕。</h4>
        </div>
    </div>


    function getVersion() {
        axios.get("/api/system/setting/version",{

        }).then(({data})=>{
            setVersion({...data.data})
        })
    }

    function download(){
        window.open(version.downloadURL, "_blank");
    }
}