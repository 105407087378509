import { Route, Routes } from "react-router-dom";
import TopicHome from "./home";
import TopicPostDetail from "./post.detail";
import TopicNodes from "./node.all";
import Replys from "./replys";
import NodeDetail from "./node-detail";

import { useState } from "react";
import { AddPage } from "./add";
export default function Topic(){

    const fontSize = localStorage.getItem('fontSize')

    const style = {
        height:'100%',
        fontSize:fontSize ? fontSize : '16px',
    }


    const [homeScrollTop,setHomeScrollTop] = useState(0)

    return <div style={style}>
        <Routes>
            <Route path="" element={<TopicHome 
                homeScrollTop={homeScrollTop}
                setHomeScrollTop={setHomeScrollTop}
            />} />

            <Route path="add" element={<AddPage />} />
            <Route path="node/all" element={<TopicNodes />}/>
            <Route path="node/detail/:id" element={<NodeDetail />} />
            
            <Route path="post/detail/:id" element={<TopicPostDetail />} />
            <Route path="post/reply/:id" element={<Replys />} />
        </Routes>
    </div>
}

