import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Home from './component/home';
import Login from './component/login';
import Test from './test';
import { SusparContext } from './context';
import { subscribeNotify } from './tools';
import RestPwd from './component/login/resetpwd';


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.Fragment>
    <Router />
  </React.Fragment>
);

function Router() {
  const isLogin = localStorage.getItem("info")!==null

  const [userLoged, setUserLoged] = useState(isLogin)

  //
  if(isLogin){
    subscribeNotify()
  }
  

  const user = isLogin? JSON.parse(localStorage.getItem("info")):null;


  const [baseFontSize, setBaseFontSize] = useState(
    localStorage.getItem("fontSize") || '16px'
  );

  const style = {
    fontSize: baseFontSize
  };
  return<SusparContext.Provider value={{
      loggedIn:userLoged,
      setLogin:()=>{
        setUserLoged(true);
      },
      logout:()=>{
        setUserLoged(false)
      },
      changeFontSize:()=>{
        let tmp = localStorage.getItem("fontSize")
        setBaseFontSize(tmp);
      },
      userInfo:user
  }} style={style}>
      <BrowserRouter scrollRestoration='manual'>
        <Routes>
          <Route path='/test' element={<Test />} />
          <Route path='/login' element={<Login />} />
          <Route path='/resetpwd' element={<RestPwd />} />
          <Route path='/*' element={auth(<Home />)} />
        </Routes>
      </BrowserRouter>
  </SusparContext.Provider>


// 用户量没到20万 先不要求必须登录
  function auth(ele){
    let permitAnonymous = true;

    if(permitAnonymous){
      return ele;
    }

    if(!userLoged){
      return <Navigate to={"/login"} />
    }
    
    return ele;
  }
}


