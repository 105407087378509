import React, { useEffect, useLayoutEffect, useRef, useState,  } from "react"
import { useNavigate } from "react-router-dom"
import { AvatarBaseURL, CACHE, localDate, toFold } from "../../tools"

import style from "./nhome.module.css"
import Page from "../my-com/page"
import { Down, Ms, UP } from "../../icons"

import { PullRefresh, Toast } from 'react-vant';
import { axios } from "../../axios"

export default function NFlashHome({flashScroll,setFlashScroll}){
    const navigate = useNavigate()


    const [flashs,setFlashs] = useState({
        page:1,
        total:0,
        list:[]
    })

    useEffect(()=>{
        let cfgFromLS = localStorage.getItem("flashCfg")
        if(cfgFromLS){
            let page = JSON.parse(cfgFromLS).page
            getFlashs(page)
        }else{
            cfgFromLS = JSON.stringify({
                page:1
            })
            localStorage.setItem("flashCfg",cfgFromLS)
            getFlashs(1)
        }
        // eslint-disable-next-line
    },[])


    //回来后重新定位
    useEffect(()=>{
        if(scrollRef.current){
            let container = scrollRef.current
            container.scrollTop = flashScroll
            console.log("now",flashScroll)
        }
        // eslint-disable-next-line
    },[flashScroll,flashs])


    //离开时获取滚动条位置
    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const tmp = scrollRef.current
        return ()=>{
            if(tmp){
                setFlashScroll(tmp.scrollTop)
                console.log("离开时获取滚动条位置",tmp.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[])


    const onRefresh = showToast => {
        return new Promise(resolve => {
            new CACHE("flashes").clear().then(e=>{
                refreshFuck(1)
                resolve(true)
                if (showToast) {
                    Toast.info('正在刷新')
                }
            })
        })
    }

    return<div className={style.container} ref={scrollRef}>
        <div>
            <div className={style.header}>
                <div>
                    <span className={style.dingwei} onClick={()=>navigate("/locate")}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="expand" width="1em" height="1em" fill="currentColor" aria-hidden="true"><defs><style></style></defs><path d="M342 88H120c-17.7 0-32 14.3-32 32v224c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V168h174c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zm578 576h-48c-8.8 0-16 7.2-16 16v176H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h222c17.7 0 32-14.3 32-32V680c0-8.8-7.2-16-16-16zM342 856H168V680c0-8.8-7.2-16-16-16h-48c-8.8 0-16 7.2-16 16v224c0 17.7 14.3 32 32 32h222c8.8 0 16-7.2 16-16v-48c0-8.8-7.2-16-16-16zM904 88H682c-8.8 0-16 7.2-16 16v48c0 8.8 7.2 16 16 16h174v176c0 8.8 7.2 16 16 16h48c8.8 0 16-7.2 16-16V120c0-17.7-14.3-32-32-32z"></path></svg>
                    </span>
                    <span className={style.shuaxin} onClick={()=>{
                        setFlashScroll(0)
                        new CACHE("flashes").clear().then(e=>{
                            Toast.info('正在刷新')
                            getFlashs(1)
                        })
                    }}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="reload" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M909.1 209.3l-56.4 44.1C775.8 155.1 656.2 92 521.9 92 290 92 102.3 279.5 102 511.5 101.7 743.7 289.8 932 521.9 932c181.3 0 335.8-115 394.6-276.1 1.5-4.2-.7-8.9-4.9-10.3l-56.7-19.5a8 8 0 00-10.1 4.8c-1.8 5-3.8 10-5.9 14.9-17.3 41-42.1 77.8-73.7 109.4A344.77 344.77 0 01655.9 829c-42.3 17.9-87.4 27-133.8 27-46.5 0-91.5-9.1-133.8-27A341.5 341.5 0 01279 755.2a342.16 342.16 0 01-73.7-109.4c-17.9-42.4-27-87.4-27-133.9s9.1-91.5 27-133.9c17.3-41 42.1-77.8 73.7-109.4 31.6-31.6 68.4-56.4 109.3-73.8 42.3-17.9 87.4-27 133.8-27 46.5 0 91.5 9.1 133.8 27a341.5 341.5 0 01109.3 73.8c9.9 9.9 19.2 20.4 27.8 31.4l-60.2 47a8 8 0 003 14.1l175.6 43c5 1.2 9.9-2.6 9.9-7.7l.8-180.9c-.1-6.6-7.8-10.3-13-6.2z"></path></svg>
                    </span>
                    <span className={style.add} onClick={()=>navigate("/flash/add")}>
                        <svg viewBox="64 64 896 896" focusable="false" data-icon="plus-square" width="30px" height="30px" fill="currentColor" aria-hidden="true"><path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zM704 536c0 4.4-3.6 8-8 8H544v152c0 4.4-3.6 8-8 8h-48c-4.4 0-8-3.6-8-8V544H328c-4.4 0-8-3.6-8-8v-48c0-4.4 3.6-8 8-8h152V328c0-4.4 3.6-8 8-8h48c4.4 0 8 3.6 8 8v152h152c4.4 0 8 3.6 8 8v48z"></path></svg>
                    </span>
                </div>
            </div>
            <PullRefresh 
                headHeight={200}
                onRefresh={() => onRefresh(true)}
                onRefreshEnd={() => console.log('onRefreshEnd')} 
                className={style.contentContainer}>
                {flashs.list.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
                    onClick={()=>{navigate("/flash/detail/"+e.id)}}>
                    <div className={style.itemHeader}>
                        <span className={style.avatar}><img src={AvatarBaseURL+(e.user.avatar?e.user.avatar:'avatar/default/1.png')} alt='avatar'/></span>
                        <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </div>
                    <div>
                        <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                        {toFold(e.content)&&<p className={style.folder} style={{fontSize:"0.9em",color:"#ababab",padding:"0"}}>查看全部</p>}
                    </div>
                    {e.imgs&&<div className={style.imgsContainer}>
                        {e.imgs.map((image,index)=><div key={index}>
                            <img src={AvatarBaseURL+image.thumbnail} alt="img" className={style.img} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                        </div>)}
                    </div>}
                    <div className={style.footer}>
                        <span><Ms/>{e.commentCount}</span>
                        <span><UP/>{e.up}</span>
                        <span><Down/>{e.down}</span>
                    </div>
                </div>)}

                <div style={{padding:'1em 0'}}>
                    <Page 
                        total={flashs.total}
                        current={flashs.page}
                        pageSize={18}
                        onChange={p=>{
                            setFlashScroll(0)
                            getFlashs(p)
                        }}
                    />
                </div>
            </PullRefresh>
        </div>
    </div>


    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }
    
    function update(config){
        localStorage.setItem("flashCfg",JSON.stringify(config))
    }

    async function getFlashs(n){
        //从缓存获取
        new CACHE("flashes").get(n).then(data=>{
            if(data){
                setFlashs({
                    page:n,
                    total:data.total,
                    list:data.list
                })
                update({page:n})
            }else{
                axios.get("/api/flash",{
                    params:{
                        page:n-1
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        let tmp = data.data
                        setFlashs({
                            page:n,
                            total:tmp.total,
                            list:tmp.list
                        })
                        new CACHE("flashes").add(n,tmp)
                        update({page:n})
                    }
                }).catch(err=>{
                    console.log(err)
                })
            }
        }).catch(er=>{
            axios.get("/api/flash",{
                params:{
                    page:n-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    let tmp = data.data
                    setFlashs({
                        page:n,
                        total:tmp.total,
                        list:tmp.list
                    })
                    update({page:n})
                    new CACHE("flashes").add(n,tmp)
                }
            }).catch(err=>{
                console.log(err)
            })
        })
    }

    async function refreshFuck(n){
        axios.get("/api/flash",{
            params:{
                page:n-1
            }
        }).then(({data})=>{
            if(data.code===200){
                let tmp = data.data
                setFlashs({
                    page:n,
                    total:tmp.total,
                    list:tmp.list
                })
                new CACHE("flashes").add(n,tmp)
                Toast.info('刷新成功')
                update({page:1})
            }
        }).catch(err=>{
            console.log(err)
        })
    }

}
