import { useParams } from "react-router-dom"
import MyProfile from "./my"
import UserProfile from "./user"


export default function ProfileBase(){
    const user = useParams().userId
    //id 或者 @用户名
    console.log(user)

    const info = localStorage.getItem("info")
    const me = info&&(
        '@'+JSON.parse(info).user.username===user||
        JSON.parse(info).user.id===parseInt(user)
    )

    const username = user.startsWith("@")?user.slice(1):null
    const userId = user.startsWith("@")?null:parseInt(user)

    return <>
        {me?<MyProfile />:<UserProfile username={username} userId={userId} />}
    </>
}