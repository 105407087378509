


import { useCallback, useEffect, useRef, useState } from "react";
import style from "./crop.module.css"

export default function Crop({image,close,back}){
    const canvasRef = useRef(null);
    const containerRef = useRef(null);
    const [layout, setLayout] = useState({
      squareSize: 0,
      scaledWidth: 0,
      scaledHeight: 0,
      mode: 'none',
      offsetX: 0,
      offsetY: 0,
      minX: 0,
      maxX: 0,
      minY: 0,
      maxY: 0
    });
  
    const layoutRef = useRef(layout);
    const touchStartRef = useRef({ x: 0, y: 0, offsetX: 0, offsetY: 0 });
    const imgRef = useRef(null);
  
    // 同步layout状态到ref
    useEffect(() => {
      layoutRef.current = layout;
    }, [layout]);
  
    // 计算布局和缩放参数
    const calculateLayout = useCallback((img) => {
      const container = containerRef.current;
      if (!container) return;
      
      const rect = container.getBoundingClientRect();
      const squareSize = Math.min(rect.width, rect.height) * 0.8;
      const imgWidth = img.naturalWidth;
      const imgHeight = img.naturalHeight;
  
      let scaledWidth, scaledHeight, mode;
      let offsetX = 0, offsetY = 0;
      let minX = 0, maxX = 0, minY = 0, maxY = 0;
  
      // 计算缩放模式
      const verticalScale = squareSize / imgWidth;
      const horizontalScale = squareSize / imgHeight;
  
      if (imgHeight * verticalScale > squareSize) {
        // 垂直模式（需要上下滚动）
        mode = 'vertical';
        scaledWidth = squareSize;
        scaledHeight = imgHeight * verticalScale;
        offsetY = (scaledHeight - squareSize) / 2;
        minY = 0;
        maxY = scaledHeight - squareSize;
      } else if (imgWidth * horizontalScale > squareSize) {
        // 水平模式（需要左右滚动）
        mode = 'horizontal';
        scaledHeight = squareSize;
        scaledWidth = imgWidth * horizontalScale;
        offsetX = (scaledWidth - squareSize) / 2;
        minX = 0;
        maxX = scaledWidth - squareSize;
      } else {
        // 适应模式
        mode = 'none';
        const scale = Math.min(verticalScale, horizontalScale);
        scaledWidth = imgWidth * scale;
        scaledHeight = imgHeight * scale;
        offsetX = (squareSize - scaledWidth) / 2;
        offsetY = (squareSize - scaledHeight) / 2;
      }
  
      setLayout({
        squareSize,
        scaledWidth,
        scaledHeight,
        mode,
        offsetX,
        offsetY,
        minX,
        maxX,
        minY,
        maxY
      });
    }, []);
  
    // 图片加载处理
    useEffect(() => {
        if (!image) return;
        
        const img = new Image();
        img.crossOrigin = 'anonymous';
        img.src = image;
        imgRef.current = img;
        
        img.onload = () => {
          calculateLayout(img);
          setTimeout(() => {
            drawImage();
          }, 0);
        }
        // eslint-disable-next-line
    }, [image, calculateLayout])
  
    // 绘制图片到canvas
    const drawImage = useCallback(() => {
      const canvas = canvasRef.current;
      const img = imgRef.current;
      if (!canvas || !img) return;
  
      const ctx = canvas.getContext('2d');
      ctx.clearRect(0, 0, canvas.width, canvas.height);
  
      const { 
        mode,
        scaledWidth,
        scaledHeight,
        offsetX,
        offsetY,
        squareSize
      } = layoutRef.current;
  
      ctx.save();
      
      if (mode === 'vertical' || mode === 'horizontal') {
        ctx.drawImage(
          img,
          -offsetX,
          -offsetY,
          scaledWidth,
          scaledHeight
        );
      } else {
        ctx.drawImage(
          img,
          (squareSize - scaledWidth) / 2,
          (squareSize - scaledHeight) / 2,
          scaledWidth,
          scaledHeight
        );
      }
      
      ctx.restore();
    }, []);
  
    // 触摸事件处理
    const handleTouchStart = useCallback((e) => {
      const touch = e.touches[0];
      touchStartRef.current = {
        x: touch.clientX,
        y: touch.clientY,
        offsetX: layoutRef.current.offsetX,
        offsetY: layoutRef.current.offsetY
      };
    }, []);
  
    const handleTouchMove = useCallback((e) => {
      const touch = e.touches[0];
      const deltaX = touch.clientX - touchStartRef.current.x;
      const deltaY = touch.clientY - touchStartRef.current.y;
  
      const currentLayout = layoutRef.current;
      const newLayout = { ...currentLayout };
      
      if (currentLayout.mode === 'vertical') {
        newLayout.offsetY = Math.max(
          currentLayout.minY,
          Math.min(currentLayout.maxY, touchStartRef.current.offsetY - deltaY)
        );
      } else if (currentLayout.mode === 'horizontal') {
        newLayout.offsetX = Math.max(
          currentLayout.minX,
          Math.min(currentLayout.maxX, touchStartRef.current.offsetX - deltaX)
        );
      }
  
      setLayout(newLayout);
      drawImage();
    }, [drawImage]);
  
    // 绑定原生事件
    useEffect(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
  
      const options = { passive: false };
      
      const startHandler = (e) => {
        e.preventDefault();
        handleTouchStart(e);
      };
      
      const moveHandler = (e) => {
        e.preventDefault();
        handleTouchMove(e);
      };
  
      canvas.addEventListener('touchstart', startHandler, options);
      canvas.addEventListener('touchmove', moveHandler, options);
  
      return () => {
        canvas.removeEventListener('touchstart', startHandler);
        canvas.removeEventListener('touchmove', moveHandler);
      };
    }, [handleTouchStart, handleTouchMove]);
  
    // 导出图片
    const handleExport = useCallback(() => {
      const canvas = canvasRef.current;
      if (!canvas) return;
  
      // 创建临时canvas裁剪内容
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = layout.squareSize;
      tempCanvas.height = layout.squareSize;
      const ctx = tempCanvas.getContext('2d');
      
      // 复制可见区域
      ctx.drawImage(
        canvas,
        0,
        0,
        layout.squareSize,
        layout.squareSize,
        0,
        0,
        layout.squareSize,
        layout.squareSize
      );
  
      // 创建下载链接
      let tmp = tempCanvas.toDataURL('image/png');
      back(tmp)
      // eslint-disable-next-line
    }, [layout.squareSize]);
  
    return (
      <div
        ref={containerRef}
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right:0,
          bottom:'90px',
          display: 'flex',
          zIndex:'999',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#fff',
          touchAction: 'none'
        }}
      >
        <div className={style.cornerBorder}>
            <canvas
            ref={canvasRef}
            width={layout.squareSize}
            height={layout.squareSize}
            
            />
        </div>
        
  
        <div className={style.btns}>
          <span
            onClick={close}
            className={style.btn}
          >
            取消
          </span>
          <span
            onClick={handleExport}
            className={style.btn}
          >
            确认
          </span>
        </div>
      </div>
    )

}