import { useEffect, useState } from "react"
import { axios } from "../../../../axios"
import { Link } from "react-router-dom"


import style from "./archived.posts.module.css"
import { CACHE, localDate } from "../../../../tools"
import Page from "../../../my-com/page"
import { Down, Ms, UP } from "../../../../icons"

// 我的话题
export default function ArchivedPosts({userId,setScroll}){

    const [postList,setPostList] = useState({
        list:[],
        total:0,
        page:1
    })

    useEffect(()=>{

        let config = localStorage.getItem("hisProfileConfig")
        if(config){
            getMyPosts(parseInt(JSON.parse(config).topicArchivedPage))
        }else{
            getMyPosts(1)
        }
        // eslint-disable-next-line
    },[])
    useEffect(()=>{
        setScroll()
    },[postList,setScroll])

    return <div className={style.container}>
        <div>
            {postList.list.map(e=><div key={e.id} className={style.post}>
                    <p><Link to={'/topic/post/detail/'+e.id}>{e.title}</Link></p>
                    <span className={style.ss}>
                        <span><Ms/>{e.replyCount}</span>
                        <span><UP/>{e.star}</span>
                        <span><Down/>{e.unstar}</span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </span>
                </div>)}


                <div style={{margin:'1em 0'}}>
                    <Page
                        current={postList.page}
                        total={postList.total}
                        pageSize={18}
                        onChange={(i)=>{
                            getMyPosts(i)
                            setScroll(0)
                        }}
                    />
                </div>
        </div>
    </div>

    function getMyPosts(page) {
        new CACHE("ContentOfUser").get("user-archived-posts-"+userId+"-"+page).then(data=>{
            setPostList({
                ...postList,
                total:data.data.total,
                list:data.data.list,
                page:page
            })

            let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),topicArchivedPage:page}
            localStorage.setItem("hisProfileConfig",JSON.stringify(config))

        }).catch(()=>{
            axios.get("/api/topic/post/of-user/archived",{
                params:{
                    page:page - 1,
                    userId
                }
            }).then(({data})=>{
                if(data.code === 200){
                    new CACHE("ContentOfUser").add("user-archived-posts-"+userId+"-"+page,data)
                    setPostList({
                        ...postList,
                        total:data.data.total,
                        list:data.data.list,
                        page:page
                    })
       
                    let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),topicArchivedPage:page}
                    localStorage.setItem("hisProfileConfig",JSON.stringify(config))
                }
            }).catch(err=>{console.log(err)})
        })
        
    }
}