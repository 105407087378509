
import { useNavigate } from "react-router-dom"
import style from "./flashs.module.css"
import { useEffect, useState } from "react"
import { AvatarBaseURL, CACHE, localDate, toFold } from "../../../../tools"
import { axios } from "../../../../axios"
import Confirm from "../../../my-com/confirm"
import Page from "../../../my-com/page"
import { Down, Ms, UP } from "../../../../icons"
import { PullRefresh, Toast } from "react-vant"


// 我的动态
export default function Flashs({setScroll}){

    const navi = useNavigate()

    useEffect(()=>{
        let pageTmp = localStorage.getItem("myProfileFlashPage")
        if(pageTmp){
            getFlashsOfUser(parseInt(pageTmp))
        }else{
            getFlashsOfUser(1)
        }
        // eslint-disable-next-line
    },[])



    const [flashs,setFlashs] = useState({
        total:0,
        list:[],
        page:1
    })

    useEffect(()=>{
        setScroll()
    },[flashs,setScroll])


    const onRefresh = showToast => {
        return new Promise(resolve => {
            new CACHE("ContentOfUser").clear().then(async e=>{
                Toast.info('正在刷新')
                localStorage.removeItem("myProfileFlashPage")
                localStorage.removeItem("myProfileTopicPage")
                localStorage.removeItem("myProfileReplyPage")
                localStorage.removeItem("myProfileArchivedTopicPage")
                localStorage.removeItem("myProfileArchivedReplyPage")

                await axios.get("/api/flash/of-mine",{
                    params:{
                        page:0
                    }
                }).then(({data})=>{
                    if(data.code===200){
                        data = data.data
                        new CACHE("ContentOfUser").add("mine-flash-"+1,data)
                        setFlashs({...flashs,total:data.total,list:data.list,page:1})
                        localStorage.setItem("myProfileFlashPage",1)
                    }
                }).catch(err=>{
                    console.log(err)
                })
                resolve(true)
                Toast.info('刷新成功')
            })
        })
    }

    return <PullRefresh 
            headHeight={100}
            onRefresh={() => onRefresh(true)}
            onRefreshEnd={() => console.log('onRefreshEnd')} 
        >
    <div className={style.flashContainer}>
        {flashs.list.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
            >
            <div className={style.itemHeader}>
                <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                <span className={style.date}>{localDate(e.createDatetime)}</span>
            </div>
            <div onClick={()=>{navi("/flash/detail/"+e.id)}}>
                <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                {toFold(e.content)&&<span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>}
            </div>
            {e.imgs&&<div className={style.imgsContainer}>
                {e.imgs.map((image,index)=><div key={index}>
                    <img src={AvatarBaseURL+image.thumbnail} alt="img" className={style.img} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                </div>)}
            </div>}
            <p className={style.footer}>
                <span><Ms/>{e.commentCount}</span>
                <span><UP/>{e.up}</span>
                <span><Down/>{e.down}</span>
                <span onClick={()=>del(e.id)}>删除</span>
                {e.state===0&&<span onClick={()=>{toPrivate(e.id)}}>私有化</span>}
            </p>
        </div>)}

        <div style={{margin:'1em 0'}}>
            <Page
                total={flashs.total}
                current={flashs.page}
                pageSize={18}
                onChange={p=>{
                    getFlashsOfUser(p)
                    setScroll(0)
                }}
            />
        </div>
    </div>
    </PullRefresh>


    function getFlashsOfUser(page) {

        new CACHE("ContentOfUser").get("mine-flash-"+page).then(data=>{
            setFlashs({...flashs,total:data.total,list:data.list,page})
            localStorage.setItem("myProfileFlashPage",page)
        }).catch(()=>{
            axios.get("/api/flash/of-mine",{
                params:{
                    page:page-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    data = data.data
                    new CACHE("ContentOfUser").add("mine-flash-"+page,data)
                    setFlashs({...flashs,total:data.total,list:data.list,page})
                    localStorage.setItem("myProfileFlashPage",page)
                }
            }).catch(err=>{
                console.log(err)
            })
        })

   
    }
    function del(id){
        Confirm.confirm("确认删除吗？").then(()=>{
            axios.delete("/api/flash",{
                params:{
                    id
                }
            })
            .then(({data})=>{
                if(data.code===200){
                    new CACHE("ContentOfUser").clear().then(e=>{
                        getFlashsOfUser(1)
                        Toast.info('正在刷新')
                    })
                }
            }).catch(err=>{
                    console.log(err)
            })
        }).catch(()=>{})
    }

    function toPrivate(id){
        Confirm.confirm("确认私有化吗？私有化后将仅你自己可见").then(()=>{
            axios.put("/api/flash/to-private",{
                id
            }).then(({data})=>{
                if(data.code===200){
                    getFlashsOfUser(flashs.page)
                }
            }).catch(err=>{
                console.log(err)
            })
        }).catch(()=>{})
    }

    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }
}