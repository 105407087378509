




import { useEffect, useState } from "react"
import style from "./pannel.module.css"
import { CheckOutlined, CloseSquareFilled, LockOutlined } from "@ant-design/icons"
import { axios } from "../../../axios"
import Confirm from "../../my-com/confirm"
import { getProvince } from "../../../tools"
export function SettingPannel({close}){

    useEffect(()=>{
        check()
    },[])
    const [checkStatus, setCheckStatus] = useState({
        vip:null,
        squareEnable:null,
        offlineEnable:null,
        filterSexRole:-1
        // ....
    })

    const [setting,setSetting] = useState({
        show:false,
        base:false,
        age:false,
        sexrole:false,
        location:false,
        offline:false,

        tmpAgeStart:'',
        tmpAgeEnd:'',
        tmpSexrole:null
    })


    const [tmpLocation,setTmpLocation] = useState({
        province:null,
        city:null
    })
    const [coties,setCities] = useState([])


    return <div className={style.settingBg}>
        <div className={style.setting}>
            <p onClick={()=>close()}><CloseSquareFilled /></p>

            <div className={style.first}>
                <div onClick={()=>setSetting({show:true,base:true})}>
                    <span className={style.hint}>功能总开关</span>
                    <span>{checkStatus.squareEnable?'已开启':'已关闭'}</span>
                </div>
                <p>关闭寻他功能后，你将不能参与匹配并且他人不会再匹配到你</p>
            </div>


            <div className={style.second}>
                <div onClick={()=>setSetting({offline:true})}>
                    <span className={style.hint}>离线模式</span>
                    <span>{checkStatus.offlineEnable?'已开启':'已关闭'}</span>
                </div>
                <p>如果你离线<b> 24小时 </b>未确认对方资料，且对方觉得资料合适想进一步发展，开启离线模式后将在24小时后向对方联系方式可见，<b style={{color:'goldenrod'}}>以便对方主动添加你</b>的联系方式进行交流</p>
            </div>            

            <div className={style.items}>
                <p style={{
                    fontWeight:"bold",
                    color:"#666"
                }}>设置筛选条件</p>
                <div onClick={()=>setSetting({show:true,age:true,tmpAgeStart:'',tmpAgeEnd:''})}>
                    <span className={style.hint}>他的年龄</span>
                    <span>
                        {checkStatus.filterAgeStart}&nbsp;-&nbsp;{checkStatus.filterAgeEnd}
                    </span>
                </div>
                <div onClick={()=>setSetting({show:true,sexrole:true,tmpSexrole:checkStatus.filterSexRole})}>
                    <span className={style.hint}>他的性偏向</span>
                    <span>
                        {sexroleStr(checkStatus.filterSexRole)}
                    </span>
                </div>

                <div onClick={()=>setSetting({show:true,location:true})}>
                    <span className={style.hint}>他在哪儿</span>
                    <span>{checkStatus.filterProvince}&nbsp;-&nbsp;{checkStatus.filterCity}</span>
                </div>
            </div>
        </div>




        {setting.base&&<div className={style.alterBg}>
            <div className={style.base}>
                <p className={style.title}>功能开关</p>
                <div className={style.items}>
                    <div onClick={()=>{
                            if(checkStatus.squareEnable) return
                            axios.put("/api/square/enable",{
                            }).then(({data})=>{
                                if(data.code===200){
                                    setCheckStatus({
                                        ...checkStatus,
                                        squareEnable:true
                                    })
                                    setSetting({show:true,base:false})
                                }else{
                                    alert(data.msg)
                                }
                            }).catch(err=>{})
                        }}>
                        <span><LockOutlined />&nbsp;打开</span>
                        {checkStatus.squareEnable&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                    <div onClick={()=>{
                            if(!checkStatus.squareEnable) return
                            Confirm.confirm("关闭后你的资料将会隐藏，其他人不再可见。同时你的访问密钥全部销毁，你也不能再查看别人的资料。确认关闭吗？")
                                .then(()=>{
                                    axios.put("/api/square/disable",{
                                    }).then(({data})=>{
                                        if(data.code===200){
                                            setCheckStatus({
                                                ...checkStatus,
                                                squareEnable:false
                                            })
                                            setSetting({show:true,base:false})
                                        }
                                    }).catch(err=>{})
                                })
                        }} >
                        <span><LockOutlined/>&nbsp;关闭</span>
                        {!checkStatus.squareEnable&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                </div>
                <p className={style.cancle} onClick={()=>setSetting({show:true,base:false})}>取消</p>
            </div>    
        </div>}

        {setting.offline&&<div className={style.alterBg}>
            <div className={style.offline}>
                <p className={style.title}>离线模式</p>
                <div className={style.items}>
                    <div onClick={()=>{
                            if(checkStatus.offlineEnable) return
                            axios.put("/api/square/offline",{
                                enable:true
                            }).then(({data})=>{
                                if(data.code===200){
                                    setCheckStatus({
                                        ...checkStatus,
                                        offlineEnable:true
                                    })
                                    setSetting({offline:false})
                                }else{
                                    alert(data.msg)
                                }
                            }).catch(err=>{})
                        }}>
                        <span><LockOutlined />&nbsp;打开</span>
                        {checkStatus.offlineEnable&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                    <div onClick={()=>{
                            if(!checkStatus.offlineEnable) return
                            axios.put("/api/square/offline",{
                                enable:false
                            }).then(({data})=>{
                                if(data.code===200){
                                    setCheckStatus({
                                        ...checkStatus,
                                        offlineEnable:false
                                    })
                                    setSetting({offlie:false})
                                }
                            }).catch(err=>{})
                        }} >
                        <span><LockOutlined/>&nbsp;关闭</span>
                        {!checkStatus.offlineEnable&&<span style={{color:"goldenrod"}}><CheckOutlined /></span>}
                    </div>
                </div>
                <p className={style.cancle} onClick={()=>setSetting({offline:false})}>取消</p>
            </div>    
        </div>}

        {setting.age&&<div className={style.alterBg}>
            <div className={style.age}>
                <p className={style.title}>对方的年龄</p>
                <div className={style.input}>
                    <input value={setting.tmpAgeStart} onChange={v=>{
                        if(isNaN(v.target.value)) return
                        setSetting({
                            ...setting,
                            tmpAgeStart:v.target.value
                        })
                    }} />
                    -
                    <input value={setting.tmpAgeEnd} onChange={v=>{
                        if(isNaN(v.target.value)) return
                        setSetting({
                            ...setting,
                            tmpAgeEnd:v.target.value
                        })
                    }}  />
                </div>
                <p className={style.cancle}>
                    <span onClick={()=>setSetting({show:true,base:false})}>取消</span>
                    <span onClick={()=>alterAge()}>提交</span>
                </p>
            </div>
        </div>}

        {setting.sexrole&&<div className={style.alterBg}>
            <div className={style.sexRole}>
                <p className={style.title}>对方性偏向</p>
                <div className={style.items}>
                    <div className={setting.tmpSexrole===-1?style.active:''}
                        onClick={()=>setSetting({...setting,tmpSexrole:-1})}>不设置</div>
                    <div className={setting.tmpSexrole===0?style.active:''}
                        onClick={()=>setSetting({...setting,tmpSexrole:0})}>0</div>
                    <div className={setting.tmpSexrole===1?style.active:''} 
                        onClick={()=>setSetting({...setting,tmpSexrole:1})}>1</div>
                    <div className={setting.tmpSexrole===2?style.active:''} 
                        onClick={()=>setSetting({...setting,tmpSexrole:2})}>1、0都行</div>
                    <div className={setting.tmpSexrole===3?style.active:''}
                        onClick={()=>setSetting({...setting,tmpSexrole:3})}>1、0都不喜欢</div>
                </div>
                <p className={style.cancle}>
                    <span onClick={()=>setSetting({show:true,base:false})}>取消</span>
                    <span onClick={()=>setSexrole()}>提交</span>
                </p>
            </div>
        </div>}

        {setting.location&&<div className={style.alterBg}>
            <div className={style.location}>
                <p className={style.title}>对方在哪儿，城市可以不选</p>
                <div className={style.items}>
                    <div className={style.left}>
                        {getProvince().map(v=><div key={v} onClick={()=>{
                            setTmpLocation({
                                province:v,
                                city:''
                            })
                            setCities(getProvince(v))
                        }} className={[style.province,tmpLocation.province===v?style.active:''].join(' ')} >
                            {v}
                        </div>)}
                    </div>
                    <div className={style.right}>
                        {coties.map(v=><div key={v} onClick={()=>{
                            setTmpLocation({
                                ...tmpLocation,
                                city:v
                            })
                        }} className={[style.city,tmpLocation.city===v?style.active:''].join(' ')} >
                            {v}
                        </div>)}
                    </div>
                </div>
                <p className={style.cancle}>
                    <span onClick={()=>setSetting({show:true,location:false})}>取消</span>
                    <span onClick={()=>alterLocation()}>提交</span>
                </p>
            </div>
        </div>}
    </div>

    function setSexrole() {
        axios.put("/api/square/setting/filter/sex-role",{
            filterSexRole:setting.tmpSexrole
        }).then(({data})=>{
            if(data.code===200){
                setSetting({
                    ...setting,
                    sexrole:false
                })
                check()
            }else{
                alert(data.msg)
            }
        }).catch(err=>{})
    }


    function check(){
        axios.get("/api/square/status",{
        }).then(({data})=>{
            if(data.code===200){
                setCheckStatus({
                    vip:data.data.vip,
                    squareEnable:data.data.squareEnable,
                    filterAgeStart:data.data.filterAgeStart===0?'':data.data.filterAgeStart,
                    filterAgeEnd:data.data.filterAgeEnd===0?'':data.data.filterAgeEnd,
                    filterProvince:data.data.filterProvince,
                    filterCity:data.data.filterCity,
                    offlineEnable:data.data.offlineEnable,
                    filterSexRole:data.data.filterSexRole
                })
            }
    
        }).catch(err=>{})
    }

    function alterAge(){
        //值要在15-99之间 可以为空
        if(setting.tmpAgeStart!==''){
            if(setting.tmpAgeStart<15||setting.tmpAgeStart>99){
                alert("开始年龄范围不合法，只能在15-99之间，可以为空")
                return
            }
        }
        if(setting.tmpAgeEnd!==''){
            if(setting.tmpAgeEnd<15||setting.tmpAgeEnd>99){
                alert("结束年龄范围不合法，只能在15-99之间，可以为空")
                return
            }
        }
        //开始年龄不能大于结束年龄
        if(setting.tmpAgeStart!==''&&setting.tmpAgeEnd!==''&&setting.tmpAgeStart>setting.tmpAgeEnd){
            alert("开始年龄不能大于结束年龄")
            return
        }

        axios.put("/api/square/setting/filter/age",{
            filterAgeStart:setting.tmpAgeStart,
            filterAgeEnd:setting.tmpAgeEnd
        }).then(({data})=>{
            if(data.code===200){
                setSetting({
                    ...setting,
                    age:false
                })
                check()
            }else{
                alert(data.msg)
            }
        }).catch(err=>{})
    }

    function alterLocation(){
        axios.put("/api/square/setting/filter/location",{
            filterProvince:tmpLocation.province,
            filterCity:tmpLocation.city
        }).then(({data})=>{
            if(data.code===200){
                setSetting({
                    ...setting,
                    location:false
                })
                check()
            }else{
                alert(data.msg)
            }
        }).catch(err=>{})
    }


    function sexroleStr(i){
        if(i===-1){
            return ""
        }else if(i===0){
            return "0"
        }else if(i===1){
            return "1"
        }else if(i===2){
            return "1、0都行"
        }else if(i===3){
            return "1、0都不喜欢"
        }
    }
}

            

            

            

            