import { useEffect, useState } from "react"
import style from "./index.module.css"

export default function Page({total,current,pageSize,onChange}) {
  

    const totalPage = Math.ceil(total/pageSize)
    const [currentInner,setCurrentInner] = useState(current)
    //展示格式如   上一页 input(里面显示当前页) / 总页数 下一页
    //上一页
    function prev(){
      if(current>1){
        setTimeout(()=>{
          onChange(current-1)
        },200)
      }
    }
    //下一页
    function next(){
      if(totalPage === 0){
        return
      }
      if(current<totalPage){
        setTimeout(()=>{
          onChange(current+1)

        },200)
      }
    }
    //input框
    function inputChange(e){
      let value = e.target.value
      //只能输入数字或空
        value = value.replace(/\D/g, '');
        if(value === '' || (value > 0 && value <= totalPage)){
          setCurrentInner(value)
          return
        }
    }

    useEffect(()=>{
        setCurrentInner(current)
    },[current])

    //
    if (total === 0) {
        return null; // 或者显示一条消息给用户
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter' || e.keyCode === 13) { // 兼容不同的浏览器
          let value = e.target.value;
          value = value.replace(/\D/g, '');
    
          if (value > 0 && value <= totalPage) {
            setCurrentInner(value);
            // 更新父组件的current值
            onChange(parseInt(value, 10));
          }
        }
      }                                               

    return <div className={style.page}>
        <span className={[style.pre,current===1?style.disable:''].join(" ")} onClick={prev}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M10.8284 12.0007L15.7782 16.9504L14.364 18.3646L8 12.0007L14.364 5.63672L15.7782 7.05093L10.8284 12.0007Z"></path></svg>
        </span>
        <span className={style.center}>
            <input type="text" value={currentInner} onKeyDown={handleKeyDown} onChange={inputChange}/>
            <span style={{color:'#888'}}>/</span>
            <span style={{color:'#888',fontSize:'14px'}}>{totalPage}</span>
        </span>
        <span className={[style.next,(current===totalPage||totalPage===0)?style.disable:''].join(' ')} onClick={next}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M13.1717 12.0007L8.22192 7.05093L9.63614 5.63672L16.0001 12.0007L9.63614 18.3646L8.22192 16.9504L13.1717 12.0007Z"></path></svg>
        </span>
    </div>
}

