import { Button, Input, Modal, Progress } from "antd";
import { useContext, useRef, useState } from "react";
import { axios } from "../../axios";
import { useNavigate } from "react-router-dom";
import { Switch } from "antd";

import style from "./add.module.css"
import { CACHE } from "../../tools";
import { SusparContext } from "../../context";
import Msg from "../my-com/Msg";


export default function FlashAdd(){
    const SUSPAR = useContext(SusparContext)

    let tmp = localStorage.getItem("cache_flash_add")

    const [addContent,setAddContent] = useState(tmp?tmp:"")
    const [isPrivate,setIsPrivate] = useState(false)
    const [selectedImages, setSelectedImages] = useState([null, null, null])
    const navi = useNavigate()
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const submitingRef = useRef(false)
    const fileInputRefs = [useRef(), useRef(), useRef()]

    const handleImageClick = (index) => {
        if (selectedImages[index]) {
            // If image exists, show preview with delete option
            Modal.confirm({
                title: '图片预览',
                icon: null,
                content: (
                    <div style={{ textAlign: 'center' }}>
                        <img 
                            src={URL.createObjectURL(selectedImages[index])} 
                            alt="Preview" 
                            style={{ maxWidth: '100%', maxHeight: '400px', marginBottom: '10px' }} 
                        />
                        <Button 
                            danger 
                            onClick={() => {
                                const newImages = [...selectedImages];
                                newImages[index] = null;
                                setSelectedImages(newImages);
                                Modal.destroyAll();
                            }}
                            style={{ display: 'block', margin: '10px auto' }}
                        >
                            删除图片
                        </Button>
                        <Button 
                            onClick={() => Modal.destroyAll()}
                            style={{ display: 'block', margin: '10px auto' }}
                        >
                            关闭预览
                        </Button>
                    </div>
                ),
                footer: null,
                closable: true,
                maskClosable: true
            });
        } else {
            // If no image, trigger file input
            fileInputRefs[index].current.click();
        }
    }
    const handleImageChange = (event, index) => {
        const file = event.target.files[0]
        if (file && file.type.startsWith('image/')) {
            const newImages = [...selectedImages]
                newImages[index] = file
                console.log(file)
                setSelectedImages(newImages)
        } else {
            Msg.msg("请选择图片文件")
        }
    }

    return<div className={style.container}>
        {isUploading && (
            <div className={style.overlay}>
                <div className={style.progressContainer}>
                    <Progress percent={uploadProgress} status="active" />
                    <p>正在提交，请勿返回。稍等...</p>
                </div>
            </div>
        )}
        <h3>发布动态</h3>
        <p>
            <Switch checked={isPrivate} checkedChildren="仅自己可见" unCheckedChildren="仅自己可见" onChange={(newV)=>{
                setIsPrivate(newV)
            }} />
        </p>

        <Input.TextArea
            maxLength={2000} 
            placeholder="此刻心情，记录一下。"
            value={addContent} 
            defaultValue={""}
            autoSize={{minRows:6}}
            onChange={e=>{
                setAddContent(e.target.value)
                localStorage.setItem("cache_flash_add",e.target.value)
            }}
            />
        <p>
            {!SUSPAR.loggedIn&&<Button onClick={()=>navi("/login")}>请先登陆</Button>}
            {SUSPAR.loggedIn&&<span className={style.btn} onClick={()=>addFlash()}>提交</span>}
        </p>

        <div className={style.imgsContainer}>
            {[0, 1, 2].map((index) => (
                <div key={index} className={style.add} onClick={() => handleImageClick(index)}>
                    {selectedImages[index] ? (
                        <img src={URL.createObjectURL(selectedImages[index])} alt="Selected" style={{ width: '100%', height: '100%', objectFit: 'scale-down' }} />
                    ) : (
                        '+'
                    )}
                    <input
                        type="file"
                        accept="image/*"
                        style={{ display: 'none' }}
                        ref={fileInputRefs[index]}
                        onChange={(e) => handleImageChange(e, index)}
                    />
                </div>
            ))}
        </div>
    </div>

    async function addFlash(){
        if(submitingRef.current){
            return
        }
        submitingRef.current = true
        setIsUploading(true)
        setUploadProgress(0)

        //content不能超过2000字
        if(addContent.length===0){
            Msg.msg("内容不能为空")
            submitingRef.current = false
            setIsUploading(false)
            return
        }
        if(addContent.length>2000){
            Msg.msg("内容不能超过2000字")
            submitingRef.current = false
            setIsUploading(false)
            return
        }
        axios.post("/api/flash",{
            content:addContent,
            isPrivate:isPrivate
        }).then(async ({data})=>{
            if(data.code===200){
                setUploadProgress(30)
                let id = data.data
                //上传图片
                let imgs = selectedImages.filter(e=>e)
                if(imgs.length>0){
                    try {
                        for(let i = 0; i < imgs.length; i++) {
                            setUploadProgress(30 + Math.floor((i + 1) / imgs.length * 60))
                            const result = await uploadImg(id, imgs[i])
                            if (!result) {
                                throw new Error('Image upload failed')
                            }
                        }
                        setUploadProgress(90)
                        Msg.msg("发布成功")
                        new CACHE("flashes").clear().then(e=>{
                            setUploadProgress(100)
                            navi("/flash/detail/"+data.data)
                        })
                        localStorage.removeItem("cache_flash_add")
                    } catch(error) {
                        console.error("Error uploading images:", error)
                        Msg.msg("图片上传失败")
                    }
                }else{
                    setUploadProgress(90)
                    Msg.msg("发布成功")
                    new CACHE("flashes").clear().then(e=>{
                        setUploadProgress(100)
                        navi("/flash/detail/"+data.data)
                    })
                    localStorage.removeItem("cache_flash_add")
                }
     
            }else{
                Msg.msg(data.msg)
            }
            submitingRef.current = false
            setIsUploading(false)
        }).catch(err=>{
            submitingRef.current = false
            setIsUploading(false)
            console.log(err)
        })
    }

    async function uploadImg(id, img) {
        try {
            const extension = img.type.split('/')[1]; 
            
            // Get pre-signed URL for upload
            let preUrlResponse = await axios.put("/api/flash/add-image", {
                flashId: id,
                extension: extension
            });
            if (preUrlResponse.data.code !== 200) {
                throw new Error(preUrlResponse.data.msg);
            }
            let preUrl = preUrlResponse.data.data;
            if (!preUrl) {
                throw new Error('Failed to get upload URL');
            }
            try {
                // 原始图片上传
                let response = await fetch(preUrl.url, {
                    method: 'PUT',
                    body: img,
                    headers: {'Content-Type': img.type},
                    mode: 'cors'
                });
    
                if (response.ok) {
                    // 创建并上传缩略图
                    const temImg = new Image();
                    temImg.src = URL.createObjectURL(img);
                    await new Promise((resolve, reject) => {
                        temImg.onload = async () => {
                            const canvas = document.createElement('canvas');
                            const ctx = canvas.getContext('2d');
                            let width = temImg.width;
                            let height = temImg.height;
                            if(width>1000||height>1000){
                                width = width/10;
                                height = height/10;
                            }
                            canvas.width = width;
                            canvas.height = height;
                            ctx.drawImage(temImg, 0, 0, width, height);
                            canvas.toBlob(async (blob) => {
                                try {
                                    let thumbResponse = await fetch(preUrl.thumb, {
                                        method: 'PUT',
                                        body: blob,
                                        headers: {'Content-Type': 'image/webp'},
                                        mode: 'cors'
                                    });
    
                                    if (thumbResponse.ok) {
                                        console.log("缩略图上传ok");
                                        resolve();
                                    } else {
                                        alert('上传失败,请联系客服');
                                        reject(new Error('Thumbnail upload failed'));
                                    }
                                } catch (error) {
                                    console.error('Error during thumbnail upload:', error);
                                    reject(error);
                                }
                            }, 'image/webp', 0.1);
                        };
    
                        temImg.onerror = (err) => {
                            reject(err);
                        };
                    });
                } else {
                    alert('上传失败,请联系客服');
                }
                console.log("over");
            } catch(e) {
                console.log(e);
            }
            return true;
        } catch (error) {
            console.error('Error uploading image:', error);
            return false;
        }
    }
}

