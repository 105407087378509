import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import { axios } from "../../../axios"

import style from "./index.module.css"


export default function UserPointsDetail(){
    const userId = useParams().userId

    const [points,setPoints] = useState({})

    useEffect(()=>{
        getUserPoints()
        // eslint-disable-next-line
    },[])

    return <div className={style.container}>

        <div>
            <p>当前等级：{points.level}</p>
            <p>当前积分:{points.totalPoints}</p>
            <p>距离下一等级需要积分:{points.toNextLevelPoints}</p>
        </div>


        <div>
            
            <div style={{fontSize:'0.9em',color:'gray'}}>
                等级规则
            </div>
            <div>
                等级与积分对应关系 <span style={{fontWeight:'bold',fontSize:'1.2em'}}>2</span><sup>等级-1</sup> = 积分
            </div>
            <div style={{fontSize:'0.9em',color:'gray',marginTop:'1em'}}>
                积分获取方式
            </div>
            <div className={style.ways}>
                <div>每日签到 1积分</div>
                <div>优质话题 1积分</div>
                <div>话题加精 10积分</div>
                <div>回复加精 10积分</div>
            </div>
        </div>
    </div>

    function getUserPoints(){
        axios.get('/api/user/points',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                let points = data.data
                setPoints(points)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}