


import style from "./resetpwd.module.css"
export default function RestPwd(){


    return <div className={style.bg}>
        
        <div className={style.container}>
            


            <p>忘记密码是一种很笨蛋的行为。</p>
            <p>如果你真的忘记了，请按以下步骤重置：</p>
            <ul>
                <li>1. 重新注册一个小号，并登陆</li>
                <li>2. 使用大号绑定邮箱，发送主题为 "找回密码-大号账号-小号账号" 格式的邮件到邮箱 help@suspar.com</li>
                <li>3. 邮件发送后使用小号“联系客服”，发送“大号账号 - 已发送”。在这里可以尽可能提供大号属于你的证据</li>
                <li>4. 24小时内，如果验证通过，站长会将你的临时重置密码发送至小号私信</li>
            </ul>

            <p>
                本站用户密码采用一次哈希形式存储，除了你自己，包括站长在内的所有人都不可能知道你的密码是什么，所以别指望谁能帮你“找回密码”，只能重置！
            </p>
        </div>
    </div>
}