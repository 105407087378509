import { useNavigate } from "react-router-dom";

import { useEffect, useState } from "react";
import { axios } from "../../axios";

import style from "./index.module.css"
import { AvatarBaseURL, CACHE, localDate } from "../../tools";
export default function UserLogged(){

    const navigate = useNavigate();
    const navi = useNavigate()
    const [profile,setProfile] = useState({})
    const [level,setLevel] = useState(0)
    const [checkInStatus,setCheckInStatus] = useState(true)
    const [banStatus,setBanStatus] = useState({
        banedStatus:0,
        banedToDatetime:"",
        banedReason:""
    })
    const [noticeCount,setNoticeCount] = useState({
        notice:0,//通知
        attendant:0,//客服消息
    })

    useEffect(()=>{
        const isLogin = localStorage.getItem("info")!==null
        if(!isLogin){
            navigate("/login")
        }else{
            getUserProfile()
            getUserPoints()
            getCheckInStatus()
        }
        // eslint-disable-next-line
    },[])


    return<div className={style.container}>
        <div className={style.top}>
            <div className={style.user} onClick={()=>navigate("/user/profile/"+profile.userId)}>
                <div className={style.left}>
                    {profile.id&&<img
                        src={AvatarBaseURL+(profile.avatar?profile.avatar:'avatar/default/1.png')} alt="avatar" />
                    }
                </div>
                <div className={style.right}>
                    <span className={style.nickname}>
                        {profile.nickname}
                    </span>
                    <span>
                        帐号：{profile.username}
                    </span>
                </div>
            </div>

            <div className={style.userNum}>
                <span className={style.pointsLevel} onClick={()=>navi("/user/points/"+profile.userId)}>等级:{level}</span>
                {checkInStatus?<span>已签到</span>:<span onClick={checkIn}>签到</span>}
            </div>


            {banStatus.banedStatus===1&&<div className={style.ban}>
                <span>禁言中</span>
                <span>解除时间:{localDate(banStatus.banedToDatetime)}</span>
                <span>原因:{banStatus.banedReason}</span>
            </div>}

            <div className={style.followerContainer}>
                <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                    <span>{profile.followCount}</span>
                    <span>关注</span>
                </div>
                <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                    <span>{profile.fansCount}</span>
                    <span>粉丝</span>
                </div>
            </div>
        </div>

        <div className={style.more} style={{paddingBottom:'32px'}}>
            <div onClick={()=>navi("/notice")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M22 20H2V18H3V11.0314C3 6.04348 7.02944 2 12 2C16.9706 2 21 6.04348 21 11.0314V18H22V20ZM5 18H19V11.0314C19 7.14806 15.866 4 12 4C8.13401 4 5 7.14806 5 11.0314V18ZM9.5 21H14.5C14.5 22.3807 13.3807 23.5 12 23.5C10.6193 23.5 9.5 22.3807 9.5 21Z"></path></svg>
                    <span>通知{noticeCount.notice>0&&<sup style={{color:'red',padding:'4px'}}>{noticeCount.notice}</sup>}</span>
                </span>
            </div>
            <div onClick={()=>navi('/setting')}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M6.17071 18C6.58254 16.8348 7.69378 16 9 16C10.3062 16 11.4175 16.8348 11.8293 18H22V20H11.8293C11.4175 21.1652 10.3062 22 9 22C7.69378 22 6.58254 21.1652 6.17071 20H2V18H6.17071ZM12.1707 11C12.5825 9.83481 13.6938 9 15 9C16.3062 9 17.4175 9.83481 17.8293 11H22V13H17.8293C17.4175 14.1652 16.3062 15 15 15C13.6938 15 12.5825 14.1652 12.1707 13H2V11H12.1707ZM6.17071 4C6.58254 2.83481 7.69378 2 9 2C10.3062 2 11.4175 2.83481 11.8293 4H22V6H11.8293C11.4175 7.16519 10.3062 8 9 8C7.69378 8 6.58254 7.16519 6.17071 6H2V4H6.17071ZM9 6C9.55228 6 10 5.55228 10 5C10 4.44772 9.55228 4 9 4C8.44772 4 8 4.44772 8 5C8 5.55228 8.44772 6 9 6ZM15 13C15.5523 13 16 12.5523 16 12C16 11.4477 15.5523 11 15 11C14.4477 11 14 11.4477 14 12C14 12.5523 14.4477 13 15 13ZM9 20C9.55228 20 10 19.5523 10 19C10 18.4477 9.55228 18 9 18C8.44772 18 8 18.4477 8 19C8 19.5523 8.44772 20 9 20Z"></path></svg>
                    <span>设置</span>
                </span>
            </div>
            <div onClick={()=>navi("/attendant")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M21 8C22.1046 8 23 8.89543 23 10V14C23 15.1046 22.1046 16 21 16H19.9381C19.446 19.9463 16.0796 23 12 23V21C15.3137 21 18 18.3137 18 15V9C18 5.68629 15.3137 3 12 3C8.68629 3 6 5.68629 6 9V16H3C1.89543 16 1 15.1046 1 14V10C1 8.89543 1.89543 8 3 8H4.06189C4.55399 4.05369 7.92038 1 12 1C16.0796 1 19.446 4.05369 19.9381 8H21ZM7.75944 15.7849L8.81958 14.0887C9.74161 14.6662 10.8318 15 12 15C13.1682 15 14.2584 14.6662 15.1804 14.0887L16.2406 15.7849C15.0112 16.5549 13.5576 17 12 17C10.4424 17 8.98882 16.5549 7.75944 15.7849Z"></path></svg>
                    <span>联系客服{noticeCount.attendantUnread?<sup style={{color:"red"}}>未读</sup>:<></>}</span>
                </span>
            </div>
            <div onClick={()=>navi("/setting/rules")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M15.936 2.50098L21.501 8.06595V15.936L15.936 21.501H8.06595L2.50098 15.936V8.06595L8.06595 2.50098H15.936ZM15.1076 4.50098H8.89437L4.50098 8.89437V15.1076L8.89437 19.501H15.1076L19.501 15.1076V8.89437L15.1076 4.50098ZM11.0002 15.0002H13.0002V17.0002H11.0002V15.0002ZM11.0002 7.00024H13.0002V13.0002H11.0002V7.00024Z"></path></svg>
                    <span>社区规则</span>
                </span>
            </div>
            <div onClick={()=>navi("/setting/about")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 23.7279L5.63604 17.364C2.12132 13.8492 2.12132 8.15076 5.63604 4.63604C9.15076 1.12132 14.8492 1.12132 18.364 4.63604C21.8787 8.15076 21.8787 13.8492 18.364 17.364L12 23.7279ZM16.9497 15.9497C19.6834 13.2161 19.6834 8.78392 16.9497 6.05025C14.2161 3.31658 9.78392 3.31658 7.05025 6.05025C4.31658 8.78392 4.31658 13.2161 7.05025 15.9497L12 20.8995L16.9497 15.9497ZM12 13C10.8954 13 10 12.1046 10 11C10 9.89543 10.8954 9 12 9C13.1046 9 14 9.89543 14 11C14 12.1046 13.1046 13 12 13Z"></path></svg>
                    <span>关于</span>
                </span>
            </div>
            <div onClick={()=>navi("/setting/help")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M5.00488 9.00268C5.55717 9.00268 6.00488 9.45039 6.00488 10.0027C7.63965 10.0027 9.14352 10.5631 10.3349 11.5022L12.5049 11.5027C13.837 11.5027 15.0339 12.0815 15.8579 13.0014L19.0049 13.0027C20.9972 13.0027 22.7173 14.1679 23.521 15.8541C21.1562 18.9747 17.3268 21.0027 13.0049 21.0027C10.2142 21.0027 7.85466 20.3994 5.944 19.3447C5.80557 19.7283 5.43727 20.0027 5.00488 20.0027H2.00488C1.4526 20.0027 1.00488 19.555 1.00488 19.0027V10.0027C1.00488 9.45039 1.4526 9.00268 2.00488 9.00268H5.00488ZM6.00589 12.0027L6.00488 17.0238L6.05024 17.0572C7.84406 18.3176 10.183 19.0027 13.0049 19.0027C16.0089 19.0027 18.8035 17.847 20.84 15.8732L20.9729 15.7397L20.8537 15.6393C20.3897 15.2763 19.8205 15.051 19.2099 15.0096L19.0049 15.0027L16.8932 15.0017C16.9663 15.3236 17.0049 15.6586 17.0049 16.0027V17.0027H8.00488V15.0027L14.7949 15.0017L14.7605 14.9232C14.38 14.1296 13.593 13.568 12.6693 13.508L12.5049 13.5027L9.57547 13.5025C8.66823 12.5772 7.40412 12.003 6.00589 12.0027ZM4.00488 11.0027H3.00488V18.0027H4.00488V11.0027ZM13.6513 3.57806L14.0046 3.93183L14.3584 3.57806C15.3347 2.60175 16.9177 2.60175 17.894 3.57806C18.8703 4.55437 18.8703 6.13728 17.894 7.11359L14.0049 11.0027L10.1158 7.11359C9.13948 6.13728 9.13948 4.55437 10.1158 3.57806C11.0921 2.60175 12.675 2.60175 13.6513 3.57806ZM11.53 4.99227C11.3564 5.16584 11.3372 5.43526 11.4714 5.62938L11.5289 5.69831L14.0039 8.17368L16.4798 5.69938C16.6533 5.52581 16.6726 5.25639 16.5376 5.06152L16.4798 4.99227C16.3062 4.81871 16.0368 4.79942 15.8417 4.93457L15.7724 4.99249L14.0033 6.76111L12.236 4.9912L12.1679 4.93442C11.973 4.79942 11.7036 4.81871 11.53 4.99227Z"></path></svg>
                    <span>帮助·必读</span>
                </span>
            </div>
            <div onClick={()=>navi("/open")}>
                <span>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M2.5 7C2.5 9.48528 4.51472 11.5 7 11.5C9.48528 11.5 11.5 9.48528 11.5 7C11.5 4.51472 9.48528 2.5 7 2.5C4.51472 2.5 2.5 4.51472 2.5 7ZM2.5 17C2.5 19.4853 4.51472 21.5 7 21.5C9.48528 21.5 11.5 19.4853 11.5 17C11.5 14.5147 9.48528 12.5 7 12.5C4.51472 12.5 2.5 14.5147 2.5 17ZM12.5 17C12.5 19.4853 14.5147 21.5 17 21.5C19.4853 21.5 21.5 19.4853 21.5 17C21.5 14.5147 19.4853 12.5 17 12.5C14.5147 12.5 12.5 14.5147 12.5 17ZM9.5 7C9.5 8.38071 8.38071 9.5 7 9.5C5.61929 9.5 4.5 8.38071 4.5 7C4.5 5.61929 5.61929 4.5 7 4.5C8.38071 4.5 9.5 5.61929 9.5 7ZM9.5 17C9.5 18.3807 8.38071 19.5 7 19.5C5.61929 19.5 4.5 18.3807 4.5 17C4.5 15.6193 5.61929 14.5 7 14.5C8.38071 14.5 9.5 15.6193 9.5 17ZM19.5 17C19.5 18.3807 18.3807 19.5 17 19.5C15.6193 19.5 14.5 18.3807 14.5 17C14.5 15.6193 15.6193 14.5 17 14.5C18.3807 14.5 19.5 15.6193 19.5 17ZM17.5252 11.155L17.8026 10.5186C18.297 9.38398 19.1876 8.48059 20.2988 7.98638L21.1534 7.60631C21.6155 7.4008 21.6155 6.7284 21.1534 6.52289L20.3467 6.16406C19.2068 5.65713 18.3002 4.72031 17.8143 3.54712L17.5295 2.85945C17.3309 2.38018 16.669 2.38018 16.4705 2.85945L16.1856 3.54712C15.6997 4.72031 14.7932 5.65713 13.6534 6.16406L12.8466 6.52289C12.3845 6.7284 12.3845 7.4008 12.8466 7.60631L13.7011 7.98638C14.8124 8.48059 15.7029 9.38398 16.1974 10.5186L16.4748 11.155C16.6778 11.6209 17.3222 11.6209 17.5252 11.155Z"></path></svg>
                    <span>开放平台</span>
                </span>
            </div>
            {/* <div>
                <span onClick={()=>navi('/test')}>TEST</span>
            </div> */}
        </div>

    </div>


    function getUserProfile(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            new CACHE('profiles').get(userId)
            .then(data=>{
                setProfile(data)
            }).catch(err=>{
                axios.get('/api/user/profile')
                .then(({data})=>{
                    if(data.code===200){
                        let profile = data.data
                        setProfile(profile)
                        new CACHE('profiles').add(userId,profile)
                    }
                }).catch(err=>{
                    console.log(err)
                })
            })
        }
    }


    function getUserPoints(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            let chachedUserPoints = localStorage.getItem("userPoints")
            if(chachedUserPoints){
                let chachedData = JSON.parse(chachedUserPoints)
                setLevel(chachedData.level)
                asyncGetUserPoints(userId)
            }else{
                asyncGetUserPoints(userId)
            }
        }
    }
    async function asyncGetUserPoints(userId){
        axios.get('/api/user/points',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                let points = data.data
                setLevel(points.level)
                localStorage.setItem("userPoints",JSON.stringify(data.data))
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    //获取签到状态 vip信息 禁言状态 客服消息未读
    async function getCheckInStatus(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id

            let checkInStatus = localStorage.getItem("checkInStatus")
            if(checkInStatus){
                let cachedData = JSON.parse(checkInStatus)
                setCheckInStatus(cachedData.checkIn)
                setBanStatus({
                    banedStatus:cachedData.banedStatus,
                    banedToDatetime:cachedData.banedToDatetime,
                    banedReason:cachedData.banedReason
                })
                setNoticeCount({
                    notice:cachedData.noticeCount,
                    attendantUnread:cachedData.attendantUnread
                })
                check(userId)
            }else{
                check(userId) 
            }
            
        }
    }
    async function check(userId){
        axios.get('/api/user/check-in-state',{
            params:{
                userId
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setCheckInStatus(data.checkIn)
                setBanStatus({
                    banedStatus:data.banedStatus,
                    banedToDatetime:data.banedToDatetime,
                    banedReason:data.banedReason
                })
                setNoticeCount({
                    notice:data.noticeCount,
                    attendantUnread:data.attendantUnread
                })
                localStorage.setItem("checkInStatus",JSON.stringify(data))
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    //签到
    function checkIn(){
        let userInfo = localStorage.getItem("info")
        if(userInfo){
            let userId = JSON.parse(userInfo).user.id
            axios.get('/api/user/check-in',{
                params:{
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    setCheckInStatus(true)
                    getCheckInStatus()
                }
            }).catch(err=>{
                console.log(err)
            })
        }
    }
}
