

import { useRef, useState } from "react"
import style from "./index.module.css"
import { AvatarBaseURL, CACHE } from "../../../tools"
import { axios } from "../../../axios"
import Msg from "../../my-com/Msg"
import { AvatarUpload } from "./upload"
import { useNavigate } from "react-router-dom"
import Crop from "./crop"

const defaultNum = [
    1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20
]
export function AvatarChange(){
    const navi = useNavigate()



    const [image, setImage] = useState(null);
    const [showSelected,setShowSelected] = useState({
        show:false,
        uri:null
    })
    const [showCrop,setShowCrop] = useState(false)
    const [showUpload,setShowUpload] = useState(false)


    const handleImageChange2 = e=>{
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                alert('请选择一个图片文件');
                setImage(null);
                return;
              }
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    setShowCrop(true)
                };
                setImage(reader.result); // 设置图片预览URL
            };
            reader.onerror = e=>{
                console.log(e)
            }
        }
        if(false){
            handleImageChange()
        }
    }
    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (!file.type.startsWith('image/')) {
                alert('请选择一个图片文件');
                setImage(null);
                return;
              }

            const reader = new FileReader();
            reader.onloadend = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    if (img.width < 500 || img.height < 500) {
                        alert('尺寸必须大于 500x500 像素');
                        setImage(null);
                        return;
                    }
                    if(img.width!==img.height){
                        alert('必须是正方形图片')
                        setImage(null)
                        return
                    }
                    setShowUpload(true)
                };
                setImage(reader.result); // 设置图片预览URL
            };
          reader.readAsDataURL(file);
        }
    };

    const inputRef = useRef(null);
    return<div className={style.bg}>
        <div className={style.container}>
            <div className={style.defaultList}>
                {defaultNum.map((item,index)=><div key={index} className={style.item}>
                    <img src={AvatarBaseURL+'avatar/default/'+item+".png"} alt="avatar" onClick={()=>{
                        setShowSelected({
                            show:true,
                            uri:'avatar/default/'+item+".png"
                        })
                    }} />
                </div>)}
            </div>
            {!showSelected.show&&<span className={style.uploadBtn} onClick={()=>{
                inputRef.current.value = ''
                inputRef.current.click()
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M7 20.9811C3.64378 20.7257 1 17.9216 1 14.5C1 12.1716 2.22429 10.1291 4.06426 8.9812C4.56469 5.044 7.92686 2 12 2C16.0731 2 19.4353 5.044 19.9357 8.9812C21.7757 10.1291 23 12.1716 23 14.5C23 17.9216 20.3562 20.7257 17 20.9811V21H7V20.9811ZM13 13H16L12 8L8 13H11V17H13V13Z"></path></svg>
                上传
            </span>}
            <input ref={inputRef} style={{display:'none'}} type="file" accept="image/*" onChange={handleImageChange2} />
        </div>

        {showCrop&&<Crop image={image} close={()=>setShowCrop(false)} back={(i)=>{
            setImage(i)
            setShowCrop(false)
            setShowUpload(true)
        }}/>}

        {showSelected.show&&<Selected uri={showSelected.uri} close={(v)=>{
            if(v){
                //删除本地当前用户的profile
                let userInfo = localStorage.getItem("info")
                let userId = JSON.parse(userInfo).user.id
                new CACHE('profiles').del(userId).then(()=>{
                    setShowSelected({
                        show:false,
                        url:null
                    })
                    navi("/user")
                })
          
            }else{
                setShowSelected({
                    show:false,
                    url:null
                })
            }
        }} />}

        {showUpload&&<AvatarUpload image={image} close={(v)=>{
            if(v){
                //删除本地当前用户的profile
                let userInfo = localStorage.getItem("info")
                let userId = JSON.parse(userInfo).user.id
                new CACHE('profiles').del(userId).then(()=>{
                    setShowSelected({
                        show:false,
                        url:null
                    })
                    setImage(null)
                    navi("/user")
                })
         
            }else{
                setShowUpload(false)
                setImage(null)
            }
            setShowUpload(false)
        }} />}
    </div>
}

function Selected({uri,close}){
    
    return<div className={style.selected}>
        <div className={style.selectedContainer}>
            <div className={style.selectedContent}>
                <p className={style.a}>设为我的头像</p>
                <div className={style.c}>
                    <img src={AvatarBaseURL+uri} alt="avatar show" />
                </div>
                <p className={style.b}>
                    <span onClick={submit}>确定</span>
                    <span onClick={()=>close()}>取消</span>
                </p>
            </div>
        </div>
    </div>

    function submit() {
        axios.put("/api/user/avatar", {
            uri: uri
        }).then(({data})=>{
            if(data.code===200){
                close(true)
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }
}