


//

import { useEffect, useState } from "react"
import { axios } from "../../../axios"
import { Button } from "antd"

import style from "./index.module.css"
import Msg from "../../my-com/Msg"
export default function Security(){
    const [config,setConfig] = useState({
        email:'',
        phone:''
    })

    const [alter,setAlter] = useState({
        showEmail:false,
        showPhone:false,
        value:''
    })
    useEffect(()=>{
        getConfig()
    },[])
    return <div className={style.bg}>
        <h3>安全设置</h3>
        <p>邮箱用来找回密码，手机号可代替账号登录</p>
        <div className={style.container}>
            <div className={style.inputContainer} onClick={()=>setAlter({
                showEmail:true,
                showPhone:false,
                value:config.email
            })}>
                <span>邮箱</span>
                {!(config.email===null||config.email==='')&&<span>{config.email}&nbsp;&nbsp;&gt;</span>}
                {(config.email===null||config.email==='') && <span>未绑定&nbsp;&nbsp;&gt;</span>}
            </div>

            <div className={style.inputContainer} onClick={()=>setAlter({
                showEmail:false,
                showPhone:true,
                value:config.phone
            })}>
                <span>手机号</span>
                {!(config.phone===null||config.phone==='')&&<span>{config.phone}&nbsp;&nbsp;&gt;</span>}
                {(config.phone===null||config.phone==='') && <span>未绑定&nbsp;&nbsp;&gt;</span>}
            </div>
        </div>


        {alter.showEmail&&<div className={style.addBg}>
            <div>
                <p>修改绑定邮箱</p>
                <p>
                    <input defaultValue={config.email} onChange={v=>setAlter({
                        ...alter,
                        value:v.target.value
                    })} />
                </p>
                <p>
                    <Button type="dashed" danger onClick={()=>submitEmail()}>提交</Button>&nbsp;&nbsp;
                    <Button type="dashed" onClick={()=>setAlter({
                        ...alter,
                        showEmail:false
                    })}>取消</Button>
                </p>
            </div>
        </div>}
        {alter.showPhone&&<div className={style.addBg}>
            <div>
                <p>修改绑定手机号</p>
                <p>
                    <input defaultValue={alter.value} onChange={v=>setAlter({
                        ...alter,
                        value:v.target.value
                    })} />
                </p>
                <p>
                    <Button type="dashed" danger onClick={()=>submitPhone()}>提交</Button>&nbsp;&nbsp;
                    <Button type="dashed" onClick={()=>setAlter({
                        ...alter,
                        showPhone:false
                    })}>取消</Button>
                </p>
            </div>
        </div>}
        
    </div>

    function getConfig() {
        axios.get("/api/user/security",{

        }).then(({data})=>{
            if(data.code===200){
                setConfig({
                    ...data.data
                })
            }
        }).catch(err=>{})
    }

    function submitEmail(){
        if(!alter.value || alter.value.trim()===''){
            Msg.msg("不能为空")
            return
        }
        axios.put("/api/user/security/email",{
            email:alter.value
        }).then(({data})=>{
            if(data.code===200){
                Msg.msg("提交成功")
                getConfig()
                setAlter({
                    showEmail:false,
                    showPhone:false,
                    value:''
                })
            }
        })
    }
    function submitPhone(){
        if(!alter.value || alter.value.trim()===''){
            Msg.msg("不能为空")
            return
        }
        if(alter.showPhone){
            let phone = alter.value
            if(phone&&phone.length!==11){
                Msg.msg("请填入正确手机号")
                return
            }
            for(let i=0;i<phone.length;i++){
                let code = phone.charCodeAt(i);
                
                if(!(code>=48 && code<=57)){
                    Msg.msg("请填入正确手机号");
                    return;    
                }
            }
        }
        axios.put("/api/user/security/phone",{
            phone:alter.value
        }).then(({data})=>{
            if(data.code===200){
                Msg.msg("提交成功")
                getConfig()
                setAlter({
                    showEmail:false,
                    showPhone:false,
                    value:''
                })
            }else{
                Msg.msg(data.msg)
            }
        })
    }
}