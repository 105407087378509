


import { useState } from "react";
import styles from "./index.module.css"
export default function Switch({back,value}){

    const [isActive, setIsActive] = useState(value);

    const toggleSwitch = () => {
        let tmp = !isActive
      setIsActive(tmp);
      back(tmp)
    };
  
    return (
      <div
        className={`${styles.switch} ${isActive ? styles.active : ''}`}
        onClick={toggleSwitch}
      >
        <span className={styles.option}>自定义</span>
        <span className={styles.option}></span>
        <div className={styles.slider}></div>
      </div>
    )
}