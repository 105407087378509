import { useNavigate } from "react-router-dom"

import style from "./index.module.css"
export default function Open(){
    const navi = useNavigate()
    return <div className={style.container}>

        <div>
            <p>小游戏</p>
            <div>
                <span onClick={()=>navi("/game/mine")}>扫雷</span>
            </div>
        </div>

        <div>
            <p>开放客户端</p>
            <div>
                <span onClick={()=>window.location.href = "/open/vue3"}>vue3版</span>
            </div>
        </div>

        <div>
            <p>工单</p>
            <div>
                <span onClick={()=>navi("/feedback")}>协助找回密码</span>
            </div>
        </div>
    </div>
}