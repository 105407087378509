import { useNavigate, useParams } from "react-router-dom"
import { axios } from "../../axios"
import { useEffect, useRef, useState } from "react"

import style from './detail.module.css'
import { Input } from "antd"
import { AvatarBaseURL, AvatarDefault, checkLogin, localDate, share, toFold } from "../../tools"
import Alert from "../my-com/alert"
import Page from "../my-com/page"
import Linkify from "../my-com/linkfy"
import { Down, Ms, Share, UP } from "../../icons"


export default function FlashDetail(){

    const submitingRef = useRef(false)

    const id = useParams().id
    useEffect(()=>{
        getDetail()
        // eslint-disable-next-line
    },[id])
    const navi = useNavigate()

    const [detail,setDetail] = useState({
        user:{},
        content:null
    })
    const [comments,setComments] = useState({
        list:[],
        total:0,
        page:1
    })
    const [showAddComment,setShowAddComment] = useState(false)
    const [showCommentAddComment,setShowCommentAddComment] = useState({
        visible:false,
        currentId:null,
        msg:"",
        placeholder:''
    })
    
    let tmpComment = localStorage.getItem("cache_flash_comment_add")
    const [commentContent,setCommentContent] = useState(tmpComment?tmpComment:"")

    const [showFullContent,setShowFullContent] = useState(true)
    //评论只能展开一个
    const [showFullCommentId,setShowFullCommentId] = useState()

    const [imgPreview,setImgPreview] = useState({
        show:false,
        url:''
    })

    return <div className={style.container}>
        {detail.content===null?<div>loading...</div>:<>
        <div className={detail.state===0?style.contentContainer:style.privateContentContainer}>
            <div className={style.itemHeader}>
                <span className={style.avatar} onClick={()=>navi("/user/profile/"+detail.user.id)}><img src={AvatarBaseURL+(detail.user.avatar?detail.user.avatar:AvatarDefault)} alt="avatar" /></span>
                <span className={style.user} onClick={()=>navi("/user/profile/"+detail.user.id)}>{getNicknameOrUsername(detail.user)}</span>
                <span className={style.date}>{localDate(detail.createDatetime)}</span>
            </div>

            <div className={style.content}>
                <p className={toFold(detail.content)&&!showFullContent?style.contentTextHide:style.contentText}>
                    <Linkify text={detail.content} />
                </p>
                {
                toFold(detail.content)&&<>{showFullContent?
                <p className={style.folder} onClick={()=>setShowFullContent(false)}>
                    <span style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>
                </p>:<p className={style.folder} onClick={()=>setShowFullContent(true)}>
                    <span style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>
                </p>}</>
                }
            </div>

            <p className={style.footer}>
                <span className={style.comment}><Ms/>{detail.commentCount}</span>
                <span className={[style.btn,detail.stared?style.actived:''].join(" ")} onClick={starFlash}><UP/>{detail.up}</span>
                <span className={[style.btn,detail.unstared?style.actived:''].join(" ")} onClick={unstarFlash}><Down/>{detail.down}</span>
                <span className={style.btn} onClick={()=>share("/flash/detail/"+detail.id,detail.content)}><Share/></span>
            </p>

            {detail.imgs&&<div className={style.imgsContainer}>
                {detail.imgs.map((e,index)=><div key={index}>
                    <img src={AvatarBaseURL+e.thumbnail} alt="img" 
                        onClick={()=>{
                            setImgPreview({
                                show:true,
                                url:AvatarBaseURL+e.img
                            })
                        }}
                        className={style.img} 
                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                    <img src={AvatarBaseURL+e.img} alt=" " style={{width:'0',height:'0'}}/>
                </div>)}
            </div>}
        </div>

        <p className={style.addComment}>
            {!showAddComment&&<span className={style.btn} onClick={()=>setShowAddComment(true)}>添加评论</span>}
            {showAddComment&&<span className={style.btn} onClick={submitAddComment}>提交</span>}&nbsp;
            {showAddComment&&<span className={style.btn} onClick={()=>setShowAddComment(false)}>取消</span>}
        </p>

        <div>
            {showAddComment&&
            <Input.TextArea value={commentContent} rows={4} placeholder="请输入评论内容"
                onChange={e=>{
                    setCommentContent(e.target.value)
                    localStorage.setItem("cache_flash_comment_add",e.target.value)
                }} autoSize />}
        </div>


        <div className={style.commentsContainer}>
            {comments.list.map(e=><div key={e.id} className={style.commentDetail}>
                <div>
                    <div className={style.commentHeader}>
                    <span className={style.avatar}><img src={AvatarBaseURL+(e.user.avatar?e.user.avatar:AvatarDefault)} alt="avatar"/></span>
                        <span className={style.user} onClick={()=>navi("/user/profile/"+e.user.id)}>
                            {getNicknameOrUsername(e.user)} 
                            {e.parentUser&&" 评论 "+getNicknameOrUsername(e.parentUser)}
                        </span>
                        <span className={style.date}>{localDate(e.createDatetime)}</span>
                    </div>

                    <p className={toFold(e.content)&&showFullCommentId!==e.id?style.contentHide:style.content}>
                        <Linkify text={e.content} />
                    </p>
                    
                    {toFold(e.content)&&<>{showFullCommentId===e.id?
                    <p className={style.folder} onClick={()=>setShowFullCommentId(null)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>   
                    </p>:<p className={style.folder} onClick={()=>setShowFullCommentId(e.id)}>
                        <span style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>
                    </p>}</>}
                </div>
                <p className={style.footer}>
                    <span className={[style.btn,e.stared?style.actived:''].join(" ")} onClick={()=>starComment(e)}><UP/>{e.up}</span>
                    <span className={[style.btn,e.unstared?style.actived:''].join(" ")} onClick={()=>unstarComment(e)}><Down/>{e.down}</span>
                    <span className={style.btn} onClick={()=>{
                        setShowCommentAddComment({
                            visible:true,
                            currentId:e.id,
                            msg:"",
                            placeholder:"评论"+getNicknameOrUsername(e.user)
                        })
                    }}><Ms/></span>
                </p>
                {showCommentAddComment.visible&&showCommentAddComment.currentId===e.id
                &&<div className={style.commentAddComment}>
                    <Input.TextArea 
                        autoSize
                        onChange={v=>{setShowCommentAddComment({
                            ...showCommentAddComment,
                            msg:v.target.value
                        })}}
                        placeholder={showCommentAddComment.placeholder}
                    />
                    <p className={style.commentAddCommentBtns}>
                        <span onClick={submitCommentAddComment}>提交</span>
                        <span onClick={()=>{
                            setShowCommentAddComment({
                                visible:false,
                                currentId:null,
                                msg:"",
                                placeholder:''
                            })
                        }}>取消</span>
                    </p>
                </div>}
            </div>)}


            <div style={{padding:'1em 0'}}>
                <Page
                    total={comments.total}
                    current={comments.page}
                    pageSize={18}
                    onChange={(page)=>getComments(page)}
                />
            </div>
        </div>

        {imgPreview.show&&<div className={style.imgPreview} onClick={()=>{
            setImgPreview({
                show:false,
                url:''
            })
        }}>
            <img src={imgPreview.url} alt="img" />    
        </div>}
        </>}
    </div>

    function submitAddComment() {
        if(submitingRef.current){
            return
        }
        submitingRef.current = true
        if(!commentContent){
            alert("请输入评论内容")
            return
        }
        axios.post("/api/flash/comment",{
            flashId:id,
            content:commentContent
        }).then(({data})=>{
            if(data.code===200){
                getComments(1)
                setShowAddComment(false)
                setCommentContent("")
                localStorage.removeItem("cache_flash_comment_add")
            }else{
                Alert.alert(data.msg,"fail")
            }
            submitingRef.current = false
        }).catch(err=>{
            submitingRef.current = false
            console.log(err)
        })
    }
    function submitCommentAddComment() {
        if(!showCommentAddComment.msg){
            alert("请输入评论内容")
            return
        }
        axios.post("/api/flash/comment-add-comment",{
            flashId:id,
            currentId:showCommentAddComment.currentId,
            content:showCommentAddComment.msg   
        }).then(({data})=>{
            if(data.code===200){
                setShowCommentAddComment({
                    visible:false,
                    currentId:null,
                    msg:"",
                    placeholder:''
                })
                getComments(1)
            }else{
                Alert.alert(data.msg,"fail")
            }
        })
    }
    function getDetail() {
        axios.get("/api/flash/detail",{
            params:{
                id
            }
        }).then(({data})=>{
            if(data.code===200){
                setDetail(data.data)
                getComments(1)
            }else{
                Alert.alert(data.msg,"fail")
                window.setTimeout(()=>navi("/flash"),1000)
            }
        })
    }

    function getComments(page){
        axios.get("/api/flash/comment",{
            params:{
                flashId:id,
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                setComments({
                    ...comments,
                    list:data.data.list,
                    total:data.data.total,
                    page
                })
            }
        })
    }

    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }

    function starFlash(){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(detail.stared){
            return
        }
        axios.post("/api/flash/star",{
            flashId:id
        }).then(({data})=>{
            if(data.code===200){
                setDetail({
                    ...detail,
                    stared:true,
                    up:detail.up+1
                })
            }else{
                Alert.alert(data.msg,"fail")
            }
        })

    }
    function unstarFlash(){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(detail.unstared){
            return
        }
        axios.post("/api/flash/unstar",{
            flashId:id
        }).then(({data})=>{
            if(data.code===200){
                setDetail({
                    ...detail,
                    unstared:true,
                    down:detail.down+1
                })
            }else{
                Alert.alert(data.msg,"fail")
            }
        })
    }

    function starComment(comment){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(comment.stared){
            return
        }
        axios.post("/api/flash/comment/star",{
            commentId:comment.id
        }).then(({data})=>{
            if(data.code===200){
                let tmpComments = [...comments.list]
                tmpComments.forEach(e=>{
                    if(e.id===comment.id){
                        e.up++
                        e.stared = true
                        return
                    }
                })
                setComments({
                    ...comments,
                    list:tmpComments
                })
            }
        })
    }
    function unstarComment(comment){
        let logged = checkLogin()
        if(!logged){
            return
        }
        if(comment.unstared){
            return
        }
        axios.post("/api/flash/comment/unstar",{
            commentId:comment.id
        }).then(({data})=>{
            if(data.code===200){
                let tmpComments = [...comments.list]
                tmpComments.forEach(e=>{
                    if(e.id===comment.id){
                        e.down++
                        e.unstared=true
                        return
                    }
                })
                setComments({
                    ...comments,
                    list:tmpComments
                })
            }
        })
    }

    
}