import { useContext, useEffect, useState } from "react"
import { PAIRCONTEXT } from "../../context"
import { axios } from "../../../../../axios"
import { genderStr, getSexRole } from "../../../profile/dict"

import style from "./index.module.css"
import { Dislike, Like } from "../../../findyou/dislike"
import Alert from "../../../../my-com/alert"
import { useNavigate } from "react-router-dom"
import { RightSquareOutlined } from "@ant-design/icons"
import { localDate } from "../../../../../tools"

export default function ConfirmProfile(){
    const PAIRC = useContext(PAIRCONTEXT)
    const pairedSessionId = PAIRC.flow.pairSessionId

    const [profile,setProfile] = useState({
        sessionFinished:true
    })
    const [showDislike,setShowDislike] = useState(false)
    const [showLike,setShowLike] = useState(false)

    const navi = useNavigate()

    useEffect(()=>{
        getHistProfile()
         // eslint-disable-next-line
    },[])

    return <div>

        <div>
            <h3 style={{textAlign:"center"}}>资料确认</h3>
        </div>

        {profile.sessionFinished&&<div>
            <div className={style.profile}>
                <h4><span className={style.gray}>灰色</span>条目匹配后可见</h4>

                <div className={style.item}>
                    <span>昵称</span>
                    <div>{profile.name}</div>
                </div>

                <div className={style.item} >
                    <span>性别</span>
                    <div>{genderStr(profile.gender)}</div>
                </div>

                <div className={style.item}>
                    <span>年龄</span>
                    <div>{profile.age}</div>
                </div>
            </div>

        </div>}
        
        {!profile.sessionFinished&&<div>
            <div className={style.profile}>
                <h4><span className={style.gray}>灰色</span>条目匹配后可见</h4>

                <div className={style.item}>
                    <span>昵称</span>
                    <div>{profile.name}</div>
                </div>

                <div className={style.item} >
                    <span>性别</span>
                    <div>{genderStr(profile.gender)}</div>
                </div>

                <div className={style.item}>
                    <span>年龄</span>
                    <div>{profile.age}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<17))!==0?style.hide:""].join(" ")}>
                    <span>生日</span>
                    <div>{profile.birthday}</div>
                </div>

                <div className={style.signature}>
                    <span>个性签名</span>
                    <div>{profile.signature}</div>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<14))!==0?style.hide:""].join(" ")}>
                    <span>性偏向</span>
                    <div>{(profile.hideCode&(1<<14))===0?getSexRole(profile.sexRole):""}</div>
                </div>

                <div className={style.item}>
                    <span>身高</span>
                    <div>{profile.height}</div>
                </div>
                <div className={style.item}>
                    <span>体重</span>
                    <div>{profile.weight}</div>
                </div>
                <div className={style.item}>
                    <span>所在城市</span>
                    <div>
                        {profile.province ? profile.province : ''} {profile.city ? profile.city : ''}
                    </div>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<10))!==0?style.hide:""].join(" ")}>
                    <span>家乡</span>
                    <span>{profile.hometown}</span>
                </div>

                <div className={style.item}>
                    <span>职业</span>
                    <span>{profile.job}</span>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<8))!==0?style.hide:""].join(" ")}>
                    <span>年薪</span>
                    <span>{profile.salary}</span>
                </div>
                <div className={style.item}>
                    <span>学历</span>
                    <span>{profile.education}</span>
                </div>

                <div className={[style.item,(profile.hideCode&(1<<6))!==0?style.hide:""].join(" ")}>
                    <span>学校</span>
                    <span>{profile.school}</span>
                </div>

                <div className={style.item}>
                    <span>专业</span>
                    <span>{profile.major}</span>
                </div>
                <div className={[style.detail,(profile.hideCode&(1<<4))!==0?style.hide:""].join(" ")}>
                    <span>详细描述</span>
                    <div>
                        {profile.detail}
                    </div>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<11))!==0?style.hide:""].join(" ")}>
                    <span>微信</span>
                    <span>{profile.wechat}</span>
                </div>
                <div className={[style.item,(profile.hideCode&(1<<3))!==0?style.hide:""].join(" ")}>
                    <span>联系方式</span>
                    <span>{profile.contact}</span>
                </div>
            </div>
            

            {profile.mySayHello===null && <div>
                {showDislike&&<Dislike sessionId={pairedSessionId} close={(v)=>{
                    setShowDislike(false)
                    //执行了不喜欢操作成功
                    if(v===true){
                        getHistProfile()
                    }
                }} />}
                {showLike&&<Like sessionId={pairedSessionId} close={(v)=>{
                    setShowLike(false)
                    if(v===true){
                        //执行喜欢操作
                        getHistProfile()
                    }
                }} />}
            </div>}
        </div>}

        <div className={style.decision}>
            {profile.mySayHello && <div>
                <p><span className={style.btn}>我的决定:</span>&nbsp; {profile.mySayHello.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <div>
                    {profile.mySayHello.comment}
                    <p className={style.date}>{localDate(profile.mySayHello.createDatetime)}</p>
                </div>
            </div>}

            {profile.hisSayHello && <div>
                <p><span className={style.btn}>他的决定:</span>&nbsp;{profile.hisSayHello.toNext?<span>继续接触</span>:<span>停止接触</span>}</p>
                <div>
                    {profile.hisSayHello.comment}
                    <p className={style.date}>{localDate(profile.hisSayHello.createDatetime)}</p>
                </div>
            </div>}
        </div>

        {!profile.mySayHello &&!profile.sessionFinished && <div className={style.confirmBtns}>
            <div>
                <span onClick={()=>setTimeout(()=>{
                    setShowDislike(true)
                },200)}>不合适</span>
                <span onClick={()=>setTimeout(()=>{
                    setShowLike(true)
                },200)}>继续沟通</span>
            </div>
        </div>}

        <div className={style.nextBtn}>
            {PAIRC.canToNext('SAY_HELLO') && 
                <span className={style.baseBtn} onClick={()=>PAIRC.setFlow({...PAIRC.flow,showStatus:"FACE_PAIRING"})}>
                    <RightSquareOutlined />    
                </span>}
        </div>
        
    </div>


    //获取对方的资料
    function getHistProfile() {
        axios.get("/api/square/profile/by-session",{
            params:{
                sessionId:pairedSessionId
            }
        }).then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
                let sessionStatus = data.data.sessionStatus
                PAIRC.setFlow({
                    ...PAIRC.flow,
                    status:sessionStatus,
                })
            }else{
                Alert.alertAndDo(data.msg,"fail",()=>{
                    setTimeout(()=>{
                        navi("/square")
                    },3000)
                })
            }
        }).catch((err=>console.log(err)))
    }



}