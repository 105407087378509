
import { useNavigate } from "react-router-dom"
import style from "./flashs.module.css"
import { useEffect, useState } from "react"
import { AvatarBaseURL, CACHE, localDate, toFold } from "../../../../tools"
import { axios } from "../../../../axios"
import Page from "../../../my-com/page"
import { Down, Ms, UP } from "../../../../icons"


export default function Flashs({userId,setScroll}){
    const navi = useNavigate()


    useEffect(()=>{

        let config = localStorage.getItem("hisProfileConfig")
        if(config){
            getFlashsOfUser(parseInt(JSON.parse(config).flashPage))
        }else{
            getFlashsOfUser(1)
        }

        // eslint-disable-next-line
    },[userId])

    const [flashs,setFlashs] = useState({
        total:0,
        list:[],
        page:1
    })
    useEffect(()=>{
        setScroll()
    },[flashs,setScroll])

    return <div className={style.flashContainer}>
        {flashs.list.map(e=><div key={e.id} className={[style.item,e.state===1?style.privateItem:''].join(" ")}
            >
            <div className={style.itemHeader}>
                <span className={style.user}>{getNicknameOrUsername(e.user)}</span>
                <span className={style.date}>{localDate(e.createDatetime)}</span>
            </div>
            <div onClick={()=>{navi("/flash/detail/"+e.id)}}>
                <p className={toFold(e.content)?style.hideContent:style.content}>{e.content}</p>
                {toFold(e.content)&&<span style={{fontSize:"0.9em",color:"#ababab"}}>...查看全部</span>}
            </div>
            {e.imgs&&<div className={style.imgsContainer}>
                {e.imgs.map((image,index)=><div key={index}>
                    <img src={AvatarBaseURL+image.thumbnail} alt="img" className={style.img} style={{ width: '100%', height: '100%', objectFit: 'cover' }}/>
                </div>)}
            </div>}
            <p className={style.footer}>
                <span><Ms/>{e.commentCount}</span>
                <span><UP/>{e.up}</span>
                <span><Down/>{e.down}</span>
            </p>
        </div>)}

        <div style={{margin:'1em 0'}}>
            <Page
                total={flashs.total}
                pageSize={18}
                current={flashs.page}
                onChange={p=>{
                    getFlashsOfUser(p)
                    setScroll(0)
                }}
            />
        </div>
    </div>


    function getFlashsOfUser(page) {
        new CACHE("ContentOfUser").get("user-flash-"+userId+"-"+page).then(data=>{
            data = data.data
            setFlashs({...flashs,total:data.total,list:data.list,page})

            let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),flashPage:page}
            localStorage.setItem("hisProfileConfig",JSON.stringify(config))
    
        }).catch(()=>{
            axios.get("/api/flash/of-user",{
                params:{
                    userId:userId,
                    page:page-1
                }
            }).then(({data})=>{
                if(data.code===200){
                    new CACHE("ContentOfUser").add("user-flash-"+userId+"-"+page,data)
                    data = data.data
                    setFlashs({...flashs,total:data.total,list:data.list,page})
    
                    let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),flashPage:page}
                    localStorage.setItem("hisProfileConfig",JSON.stringify(config))
                }
            }).catch(err=>{
                console.log(err)
            })
        })

    }


    function getNicknameOrUsername(user){
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }
}