import { useContext, useEffect, useLayoutEffect, useRef, useState } from "react"
import { axios } from "../../../../axios"
import Btn from "../../../my-com/btn"
import { AvatarBaseURL, CACHE, shareText } from "../../../../tools"

import style from "./index.module.css"
import Flashs from "./flashs"
import Posts from "./posts"
import Replies from "./replies"
import ArchivedPosts from "./archived.posts"
import ArchivedReplies from "./archived.replies"
import { useNavigate } from "react-router-dom"
import Msg from "../../../my-com/Msg"
import { SusparContext } from "../../../../context"
import { Tabs } from "react-vant"


export default function MyProfile() {

    const APP = useContext(SusparContext)
    const navi = useNavigate()
    const [profile,setProfile] = useState({})

    const scrollRef = useRef(null)
    useLayoutEffect(()=>{
        const tmp = scrollRef.current
        return ()=>{
            //离开时获取滚动条位置
            if(tmp){
                localStorage.setItem("user-scroll",tmp.scrollTop)
            }
        }
        // eslint-disable-next-line
    },[])

    function setScroll(v){
        if(scrollRef.current){
            let container = scrollRef.current
            if(v===0){
                container.scrollTop = 0
                localStorage.setItem("user-scroll",0)
            }else{
                container.scrollTop = localStorage.getItem("user-scroll")
            }
        }
    }


    const [tab,setTab] = useState(null) //0 动态 1 话题 2 回复
    const [topicActiveTab,setTopicActiveTab] = useState(null) //0 我的 1 收藏
    const [replyActiveTab,setReplyActiveTab] = useState(null) //0我的 1 收藏
    //首次加载 从ls里获取启动tab
    useEffect(()=>{
        let myProfileActiveTab = localStorage.getItem("myProfileActiveTab")
        if(myProfileActiveTab){
            setTab(parseInt(myProfileActiveTab))
        }else{
            setTab(0)
        }
        let myProfileTopicActive = localStorage.getItem("myProfileTopicActive")
        if(myProfileTopicActive){
            setTopicActiveTab(parseInt(myProfileTopicActive))
        }else{
            setTopicActiveTab(0)
        }
        let myProfileReplyActive = localStorage.getItem("myProfileReplyActive")
        if(myProfileReplyActive){
            setReplyActiveTab(parseInt(myProfileReplyActive))
        }else{
            setReplyActiveTab(0)
        }
        getUserProfileFromCache()
        // eslint-disable-next-line
    },[])

    const [edit,setEdit] = useState({
        show:false,
        title:"",
        field:"",
        value:""
    })


    return <div className={style.container} ref={scrollRef}>
        {!edit.show&&<div>
            <div className={style.items}>
                <div className={style.avatarAndAccount}>
                    <span onClick={()=>navi("/user/avatarChange")} className={style.avatar}>
                        <img src={AvatarBaseURL+profile.avatar} alt="avatar" />
                    </span>
                    <div>
                        <span className={style.nickname} onClick={()=>toEdit("nickname")}>{profile.nickname}</span>
                        <span className={style.username} onClick={()=>shareText(profile.username,"账号")}>@{profile.username}</span>
                    </div>
                </div>
                <div className={style.itemNextLine} onClick={()=>toEdit("signature")}>
                    <div className={style.placeholder}>个性签名</div>
                    <div className={style.content} >
                        <span className={style.text}>
                            {profile.signature}
                        </span>
                    </div>
                </div>
                
                <div className={style.followerContainer}>
                    <div>
                        <div>
                            <span>{profile.level}</span>
                            <span>等级</span>
                        </div>
                        <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                            <span>{profile.followCount}</span>
                            <span>关注</span>
                        </div>
                        <div onClick={()=>navi("/user/followers?tab=fans&page=1")}>
                            <span>{profile.fansCount}</span>
                            <span>粉丝</span>
                        </div>
                    </div>
                </div>
            </div>

            <div className={style.myActivity}>
                <Tabs swipeable active={tab} color="#333" onChange={(v)=>{
                        setTab(v)
                        localStorage.setItem("myProfileActiveTab",v)
                        clearScroll()
                    }}>
                    <Tabs.TabPane title="动态">
                        {tab===0&&<Flashs setScroll={setScroll} />}
                    </Tabs.TabPane>
                    <Tabs.TabPane title="话题">
                        <div className={style.topicActiveTabs}>
                            <span className={topicActiveTab===0?style.active:''} onClick={()=>{
                                setTopicActiveTab(0)
                                localStorage.setItem("myProfileTopicActive",0)
                                clearScroll()
                            }}>我的</span>
                            <span className={topicActiveTab===1?style.active:''} onClick={()=>{
                                setTopicActiveTab(1)
                                localStorage.setItem("myProfileTopicActive",1)
                                clearScroll()
                            }}>收藏</span>
                        </div>
                        {tab===1&&topicActiveTab===0&&<Posts setScroll={setScroll}  />}
                        {tab===1&&topicActiveTab===1&&<ArchivedPosts setScroll={setScroll}  />}
                    </Tabs.TabPane>
                    <Tabs.TabPane title="回复">
                        <div className={style.topicActiveTabs}>
                            <span className={replyActiveTab===0?style.active:''} onClick={()=>{
                                setReplyActiveTab(0)
                                localStorage.setItem("myProfileReplyActive",0)
                                clearScroll()
                            }}>我的</span>
                            <span className={replyActiveTab===1?style.active:''} onClick={()=>{
                                setReplyActiveTab(1)
                                localStorage.setItem("myProfileReplyActive",1)
                                clearScroll()
                            }}>收藏</span>
                        </div>
                        {tab===2&&replyActiveTab===0&&<Replies setScroll={setScroll}  />}
                        {tab===2&&replyActiveTab===1&&<ArchivedReplies setScroll={setScroll}  />}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        </div>}

        {edit.show&&<div className={style.edit}>
            <div className={style.item}>
                <div className={style.headBtns}>
                    <Btn onClick={()=>setEdit({...edit,show:false})} text="取消"/>
                    <Btn confirm onClick={submitEdit} text="确定"/>
                </div>
                <div style={{textAlign:"center",fontWeight:"bolder"}}>
                    <span>{edit.title}</span>
                </div>
            </div>
            <div className={style.item}>
                <div>
                    {<input value={edit.value} onChange={v=>setEdit({...edit,value:v.target.value})} />}
                </div>
            </div>
        </div>}
    </div>

    function clearScroll(){
        localStorage.setItem("user-scroll",0)
    }
 

    function toEdit(field) {
        let title = "";
        let value = "";
        if(field==="nickname"){
            title = "修改昵称"
            value = profile.nickname
        }else if(field==="signature"){
            title = "修改个性签名"
            value = profile.signature==null?"":profile.signature
        }else if(field==="gender"){
            title = "修改性别，请填写男或女"
            value = profile.gender===1?"男":"女"
        }
        setEdit({...edit,show:true,title,field,value})
    }

    function checkDate(data){
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if(regex.test(data)===false){
            Msg.msg("格式不正确","fail")
            return false
        }
        let tmps =data.split("-")
        let tmpsYear = parseInt(tmps[0])
        let tmpsMonth = parseInt(tmps[1])
        let tmpsDay = parseInt(tmps[2])

        let tmp = new Date(data)
        let year = tmp.getFullYear()
        let month = tmp.getMonth()+1
        let day = tmp.getDate()

        if(year!==tmpsYear || month!==tmpsMonth || day!==tmpsDay){
            Msg.msg("格式不正确","fail")
            return false
        }
        return true
    }

    function submitEdit(){
        if(!edit.value){
            Msg.msg("别填空的啊","fail")
            return
        }
        if(edit.field==="gender"){
            if(edit.value!=="男" && edit.value!=="女"){
                Msg.msg("性别只能填男或女","fail")
                return
            }
        }
        if(edit.field==="birthday"){
            if(checkDate(edit.value)===false){
                Msg.msg("生日格式不正确","fail")
                return
            }
        }

        axios.put("/api/user/profile",{
            field:edit.field,
            value:edit.value
        }).then(({data})=>{
            if(data.code===200){
                setEdit({...edit,show:false})
                getUserProfile()
            }
        }).catch(err=>{})
    }


    function getUserProfile(){
        axios.get('/api/user/profile')
        .then(({data})=>{
            if(data.code===200){
                setProfile(data.data)
                new CACHE("profiles").del(data.data.userId).then(()=>{
                    new CACHE("profiles").add(data.data.userId,data.data)
                })
            }
        })
    }
    function getUserProfileFromCache(){
        if(APP.userInfo){
            let userId = APP.userInfo.user.id
            new CACHE("profiles").get(userId).then(data=>{
                setProfile(data)
            }).catch(()=>{
                axios.get('/api/user/profile')
                .then(({data})=>{
                    if(data.code===200){
                        setProfile(data.data)
                        new CACHE("profiles").del(data.data.userId).then(()=>{
                            new CACHE("profiles").add(data.data.userId,data.data)
                        })
                    }
                })
            })
        }else{
            axios.get('/api/user/profile')
            .then(({data})=>{
                if(data.code===200){
                    setProfile(data.data)
                    new CACHE("profiles").del(data.data.userId).then(()=>{
                        new CACHE("profiles").add(data.data.userId,data.data)
                    })
                }
            })
        }
    }
}


