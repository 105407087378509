


import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import style from "./replys.module.css"
import { useEffect, useRef, useState } from "react"
import { Input } from "antd"
import { localDate, share, toFold } from "../../tools"
import { axios } from "../../axios"
import Msg from "../my-com/Msg"
import Linkify from "../my-com/linkfy"
import Page from "../my-com/page"
import { Down, Ms, Share, UP } from "../../icons"


// 回复的回复
export default function PhoneReplys(){

    const navi = useNavigate()

    const replyId = useParams().id

    const [searchParmas,setSearchParmas] = useSearchParams()
    const [replys,setReplys] = useState({
        filter:"all",
        list:[],
        total:0,
        page:1
    })

    const [postDetail,setPostDetail] = useState({})
    const [replyDetail,setReplyDetail] = useState({})

    let cacheReplyAdd = localStorage.getItem("cache_reply_add")
    const [add,setAdd] = useState({
        reply:cacheReplyAdd?cacheReplyAdd:""
    })
    //是否置灰提交按钮
    const submittingRef = useRef(false)
    
    const [showFullContent,setShowFullContent]=useState(false)
    const [unfoldReplyIds,setUnfoldReplyIds] = useState([])


    useEffect(()=>{
        let page = searchParmas.get("page")?parseInt(searchParmas.get("page")):1
        getReplys(page,false,searchParmas.get("filter"))
        getReplyAndPost()
        //eslint-disable-next-line
    },[replyId])



    return<div className={style.container}>
            <h3>回复详情</h3>
        <p onClick={()=>{
                navi("/topic/post/detail/"+postDetail.id)
            }} style={{color:'goldenrod'}}><span style={{fontSize:"0.9em",fontWeight:'bold'}}>所属话题 </span>{postDetail.title}
        </p>
        

        <div className={style.father}>
            <div className={style.replyId}>#{replyDetail.id}</div>
            <div className={style.replyHeader}>
                <span className={style.user} 
                    onClick={()=>navi("/user/profile/"+replyDetail.user.id)}>{getNicknameOrUsername(replyDetail.user)}</span>
                <span className={style.date}>{localDate(replyDetail.createDatetime)}</span>
            </div>

            {/* 回复内容 */}
            <p className={toFold(replyDetail.content)&&!showFullContent?style.replyContentHide:style.replyContent}>
                <Linkify text={replyDetail.content} />
            </p>
            {toFold(replyDetail.content)&&<div>
                        {showFullContent?<span onClick={()=>setShowFullContent(false)} style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>:
                        <span onClick={()=>setShowFullContent(true)} style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span>}
                    </div>}

            <div className={style.btns}>
                <span><Ms/>{replyDetail.replyCount}</span>

                {replyDetail.stared?<span className={style.star}>
                    <UP />
                    {replyDetail.star}</span>:
                <span onClick={()=>starReplyFather(replyDetail)}><UP />
                    {replyDetail.star}</span>}
                {replyDetail.unstared?<span className={style.star}><Down />{replyDetail.unstar}</span>:
                <span onClick={()=>unstarReplyFather(replyDetail)}><Down />{replyDetail.unstar}</span>}


                {replyDetail.pid!==0&&<span onClick={()=>navi("/topic/post/reply/"+replyDetail.pid)}>上层回复</span>}
                {!replyDetail.archived&&<span onClick={()=>archiveReplyFather(replyDetail)}>收藏</span>}
                {replyDetail.archived&&<span onClick={()=>unarchiveReplyFather(replyDetail)}>取消收藏</span>}
                <span onClick={()=>share("/topic/post/reply/"+replyDetail.id,replyDetail.content)}><Share/></span>
            </div>
        </div>


        <div className={style.input}>
            <div>
                <Input.TextArea placeholder={"回复 回复#"+replyDetail.id} 
                    onChange={(v)=>{
                        setAdd({...add,reply:v.target.value})
                        localStorage.setItem("cache_reply_add",v.target.value)
                    }} 
                    maxLength={2000}
                    value={add.reply}
                    autoSize={{minRows:4}} />
                <p>
                    <span className={style.btn}
                        onClick={()=>{addReply()}}>提交</span>
                </p>
            </div>
        </div>

        <div className={style.replys}>
            <div className={style.replysFilter}>
                <span className={replys.filter==='all'?style.active:''}
                    onClick={()=>{
                        setReplys({...replys,filter:"all"})
                        getReplys(1,true,"all")
                    }}
                >全部</span>
                <span className={replys.filter==='op'?style.active:''}
                    onClick={()=>{
                        setReplys({...replys,filter:"op"})
                        getReplys(1,true,"op")
                    }}>只看回复OP</span>
            </div>

            {replys.list.map(e=><div key={e.id} className={style.reply}>
                <div className={style.id}>#{e.id}</div>
                <div className={style.replyHeader}>
                    <span className={style.user} 
                        onClick={()=>navi("/user/profile/"+e.user.id)}>{getNicknameOrUsername(e.user)}</span>
                    <span className={style.date}>{localDate(e.createDatetime)}</span>
                </div>
                <p className={toFold(e.content)&&!unfoldReplyIds.includes(e.id)?style.contentHide:style.content}>
                    <Linkify text={e.content} />
                </p>
                {toFold(e.content)&&<>{unfoldReplyIds.includes(e.id)?<p>
                    <span onClick={()=>{
                        let tmp = unfoldReplyIds.filter(tmp=>tmp!==e.id)
                        setUnfoldReplyIds(tmp)
                    }} style={{fontSize:"0.9em",color:"#ababab"}}>收起</span>
                </p>:<p><span onClick={()=>{
                    let tmp = [...unfoldReplyIds]
                    tmp.push(e.id)
                    setUnfoldReplyIds(tmp)
                }} style={{fontSize:"0.9em",color:"#ababab"}}>查看全部</span></p>}</>}  

                <div className={style.replyFoot}>
                    <span onClick={()=>navi("/topic/post/reply/"+e.id)}><Ms/>{e.replyCount}</span>
                    <span onClick={()=>starReply(e)} className={e.stared?style.active:""}><UP/>{e.star}</span>
                    <span onClick={()=>unstarReply(e)} className={e.unstared?style.active:""}><Down/>{e.unstar}</span> 
                    {!e.archived&&<span onClick={()=>archiveReply(e)}>收藏</span>}
                    {e.archived&&<span onClick={()=>unarchiveReply(e)} className={style.active}>取消收藏</span>}
                    <span onClick={()=>share("/topic/post/reply/"+e.id,e.content)}><Share/></span>
                </div>
            </div>)}

            {replys.total>=18&&<div style={{padding:'1em 0',textAlign:'center'}}>
                <Page
                    total={replys.total}
                    current={replys.page}
                    pageSize={18}
                    onChange={(p)=>getReplys(p,true,null)}
                />
            </div>}
        </div>  
    </div>



    function getReplys(tmpPage,changeURL,filter){
        axios.get("/api/topic/reply",{
            params:{
                replyId,
                page:(tmpPage?tmpPage:replys.page)-1,
                filter:filter?filter:replys.filter
            }
        }).then(({data})=>{
            if(data.code===200){
                setReplys({...replys,
                    filter:filter?filter:replys.filter,
                    list:data.data.list,
                    page:tmpPage?tmpPage:replys.page,
                    total:data.data.total})
                if(changeURL){
                    setSearchParmas(pre=>{
                        let tmp = new URLSearchParams(pre)
                        tmp.set("page",tmpPage?tmpPage:replys.page)
                        if(filter==='all'){
                            tmp.delete("filter")
                        }else if(filter==='op'){
                            tmp.set("filter",filter)
                        }
                        return tmp.toString()
                    })
                }
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function addReply(){    
        if(submittingRef.current){
            return
        }
        submittingRef.current = true
        if(!add.reply){
            alert("回复内容不能为空")
            submittingRef.current = false
            return
        }
        //长度不能超过2000
        if(add.reply.length>2000){
            alert("回复内容不能超过2000个字符")
            submittingRef.current = false
            return
        }
        axios.post("/api/topic/reply/add-to-reply",{
            pId:replyId,
            reply:add.reply
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
                setAdd({...add,reply:""})
                localStorage.removeItem("cache_reply_add")
            }else{
                Msg.msg(data.msg)
            }
            submittingRef.current = false
        }).catch(err=>{
            submittingRef.current = false
        })
    }

    function getReplyAndPost(){
        axios.get("/api/topic/reply/detail-with-topic",{
            params:{
                replyId:replyId
            }
        }).then(({data})=>{
            if(data.code === 200){
                setPostDetail(data.data.post)
                setReplyDetail(data.data.reply)
            }else{
                navi("/topic")
            }
        })
    }



    // 
    function getNicknameOrUsername(user){
        if(!user){
            return ""
        }
        if(user.nickname){
            return user.nickname
        }
        if(user.username){
            return user.username
        }
        return ""
    }



    // 顶踩收藏评论
    function starReplyFather(e){
        if(e.stared){
            return
        }
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{})
    }
    function starReply(e){
        if(e.stared){
            return
        }
        axios.post("/api/topic/reply/star",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{})
    }
    function unstarReplyFather(e){
        if(e.unstared){
            return
        }
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{})
    }
    function unstarReply(e){
        if(e.unstared){
            return
        }
        axios.post("/api/topic/reply/unstar",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplys()
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{})
    }

    function archiveReplyFather(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                getReplyAndPost()
            } 
        }).catch(err=>{})
    }
    function archiveReply(e){
        axios.post("/api/topic/reply/archive",{
            replyId:e.id
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = true
                    }
                })
                setReplys({...replys,list:tmps})
            } 
        }).catch(err=>{})
    }

    function unarchiveReplyFather(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            getReplyAndPost()
        }).catch(err=>{})
    }
    function unarchiveReply(e){
        axios.delete("/api/topic/reply/archive",{
            params:{
                replyId:e.id
            }
        }).then(({data})=>{
            if(data.code===200){
                let tmps = [...replys.list]
                tmps.forEach(tmp=>{
                    if(tmp.id===e.id){
                        tmp.archived = false
                    }
                })
                setReplys({...replys,list:tmps})
            }    
        }).catch(err=>{})
    }

}







