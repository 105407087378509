import { useEffect } from "react"
import { axios } from "../../../../axios"
import { useState } from "react"
import { Link } from "react-router-dom"

import style from "./archived.replies.module.css"
import { CACHE, localDate2, toFold } from "../../../../tools"
import Page from "../../../my-com/page"
import { Down, Ms, UP } from "../../../../icons"


//收藏的回复
export default function ArchivedReplies({userId,setScroll}){

    useEffect(()=>{
        let config = localStorage.getItem("hisProfileConfig")
        if(config){
            getMyReplies(parseInt(JSON.parse(config).replyArchivedPage))
        }else{
            getMyReplies(1)
        }
        // eslint-disable-next-line
    },[])

    const [replies,setReplies] = useState({
        list:[],
        page:1,
        total:0
    })
    useEffect(()=>{
        setScroll()
    },[replies,setScroll])

    return <div>
        {replies.list.map(e=><div key={e.id} className={style.item}>
            <div className={style.postTitle}>
                <p>
                    <Link to={'/topic/post/detail/'+e.postId}>{e.post}</Link>
                </p>
            </div>
            <div className={style.replyContentContainer}>
                <p className={toFold(e.content)?style.hideReplyContent:style.replyContent}>
                    <Link to={'/topic/post/reply/'+e.id}>{e.content}</Link>
                </p>

                {toFold(e.content)&&<>{
                    <p className={style.folder}>
                        <span>
                            <Link style={{fontSize:"0.9em",color:"#ababab"}} to={'/topic/post/reply/'+e.id}>查看全部</Link>
                        </span>
                    </p>}</>}

                <p className={style.btns}>
                    <span className={style.btn}>
                        <span><UP/>{e.star}</span>
                        <span><Down/>{e.unstar}</span>
                        <span><Ms/>{e.replyCount}</span>
                    </span>
                    <span>{localDate2(e.createDatetime)}</span>
                </p>
            </div>
        </div>)}

        <div style={{margin:'1em 0'}}>
            <Page 
                total={replies.total}
                current={replies.page}
                pageSize={10}
                onChange={p=>getMyReplies(p)}
            />
        </div>
    </div>


    function getMyReplies(page) {
        new CACHE("ContentOfUser").get("user-archived-replies-"+userId+"-"+page).then(data=>{
            setReplies({...replies,list:data.data.list,total:data.data.total,page})
            let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),replyArchivedPage:page}
            localStorage.setItem("hisProfileConfig",JSON.stringify(config))
        }).catch(()=>{
            axios.get("/api/topic/reply/list/of-user/archived",{
                params:{
                    page:page-1,
                    userId
                }
            }).then(({data})=>{
                if(data.code===200){
                    new CACHE("ContentOfUser").add("user-archived-replies-"+userId+"-"+page,data)
                    setReplies({...replies,list:data.data.list,total:data.data.total,page})
                    let config = {...JSON.parse(localStorage.getItem("hisProfileConfig")),replyArchivedPage:page}
                    localStorage.setItem("hisProfileConfig",JSON.stringify(config))
                }
            }).catch(er=>{console.log(er)})
        })
        
    }
}