import React, { useState } from 'react';
import styles from './index.module.css';

function DateInput({onChange,initValue}){
    const [value, setValue] = useState(initValue);

    const handleInputChange = (e) => {
        let inputValue = e.target.value.replace(/[^0-9]/g, '');
        const cursorPosition = e.target.selectionStart;

        // 删除操作的特殊处理
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            inputValue = inputValue.slice(0, inputValue.length - 1); // 手动移除最后一个字符
        }

        if (inputValue.length === 0) {
            setValue('');
            return;
        }

        // 新的格式化逻辑（改为 YYYY-MM-DD）
        const year = inputValue.slice(0, 4);
        const month = inputValue.slice(4, 6);
        const day = inputValue.slice(6, 8);
        let formattedValue = year;

        if (year.length === 4) {
            formattedValue += '-';
            formattedValue += month;
            if (month.length === 2) {
                formattedValue += '-';
                formattedValue += day;
            }
        }

        // 删除多余斜杠的处理
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            if (formattedValue.endsWith('-')) {
                formattedValue = formattedValue.slice(0, -1); // 移除最后的横线
            }
        }

        setValue(formattedValue);

        // 计算新的光标位置
        let newCursorPosition = cursorPosition;

        // 如果光标在插入横线的位置，则需要向后移动
        if (cursorPosition === 4 && year.length === 4) {
            newCursorPosition += 1; // 跳过第一个横线
        } else if (cursorPosition === 7 && month.length === 2) {
            newCursorPosition += 1; // 跳过第二个横线
        }

        // 处理删除操作时的光标位置
        if (e.nativeEvent.inputType === 'deleteContentBackward') {
            newCursorPosition = Math.max(0, newCursorPosition - 1); // 光标向前移动一位
        }

        // 确保光标不会超出范围
        newCursorPosition = Math.min(newCursorPosition, formattedValue.length);

        setTimeout(() => {
            if (e.target) {
                e.target.setSelectionRange(newCursorPosition, newCursorPosition);
            }
        }, 0);
        console.log(year+"-"+month+"-"+day)
        onChange(year+"-"+month+"-"+day)
    };

    return (
        <input
        type="text"
        id="date-input"
        className={styles.input}
        value={value}
        onChange={handleInputChange}
        placeholder="YYYY-MM-DD"
        maxLength={10}
    />
    );
};

export default DateInput;