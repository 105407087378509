import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import style from './phone.bottom.module.css'
import { CACHE } from "../../tools";

export default function PhoneBottom(){

    const location = useLocation()
    let path = location.pathname;
    if(path.startsWith('/topic')){
        path = '/topic'
    }
    if(path.startsWith('/flash')){
        path = '/flash'
    }
    if(path.startsWith('/square')){
        path = '/square'
    }
    if(path.startsWith('/user')){
        path = '/user'
    }
    if(path.startsWith('/setting')){
        path = '/user'
    }
    const [activeTab,setActiveTab] = useState(path)
    const navigate = useNavigate()

    useEffect(()=>{
        let path = location.pathname;
        if(path.startsWith('/topic')){
            path = '/topic'
        }
        if(path.startsWith('/flash')){
            path = '/flash'
        }
        if(path.startsWith('/square')){
            path = '/square'
        }
        if(path.startsWith('/user')){
            path = '/user'
        }
        if(path.startsWith('/setting')){
            path = '/user'
        }
        if(path.startsWith('/attendant')){
            path = '/user'
        }
        setActiveTab(path)
    },[location])    


    return<div className={style.container}>
        <div className={(activeTab==='/flash'||activeTab==='/')?style.activeTab:''} onClick={()=>to('/flash')}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M4.7134 7.12811L4.46682 7.69379C4.28637 8.10792 3.71357 8.10792 3.53312 7.69379L3.28656 7.12811C2.84706 6.11947 2.05545 5.31641 1.06767 4.87708L0.308047 4.53922C-0.102682 4.35653 -0.102682 3.75881 0.308047 3.57612L1.0252 3.25714C2.03838 2.80651 2.84417 1.97373 3.27612 0.930828L3.52932 0.319534C3.70578 -0.106511 4.29417 -0.106511 4.47063 0.319534L4.72382 0.930828C5.15577 1.97373 5.96158 2.80651 6.9748 3.25714L7.69188 3.57612C8.10271 3.75881 8.10271 4.35653 7.69188 4.53922L6.93228 4.87708C5.94451 5.31641 5.15288 6.11947 4.7134 7.12811ZM3.06361 21.6132C4.08854 15.422 6.31105 1.99658 21 1.99658C19.5042 4.99658 18.5 6.49658 17.5 7.49658L16.5 8.49658L18 9.49658C17 12.4966 14 15.9966 10 16.4966C7.33146 16.8301 5.66421 18.6635 4.99824 21.9966H3C3.02074 21.8722 3.0419 21.7443 3.06361 21.6132Z"></path></svg>
            </span>
            <span className={style.text}>动态</span>
        </div>

        <div className={activeTab==='/topic'?style.activeTab:''} onClick={()=>to('/topic')}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M17.0007 1.20825 18.3195 3.68108 20.7923 4.99992 18.3195 6.31876 17.0007 8.79159 15.6818 6.31876 13.209 4.99992 15.6818 3.68108 17.0007 1.20825ZM10.6673 9.33325 15.6673 11.9999 10.6673 14.6666 8.00065 19.6666 5.33398 14.6666.333984 11.9999 5.33398 9.33325 8.00065 4.33325 10.6673 9.33325ZM11.4173 11.9999 9.18905 10.8115 8.00065 8.58325 6.81224 10.8115 4.58398 11.9999 6.81224 13.1883 8.00065 15.4166 9.18905 13.1883 11.4173 11.9999ZM19.6673 16.3333 18.0007 13.2083 16.334 16.3333 13.209 17.9999 16.334 19.6666 18.0007 22.7916 19.6673 19.6666 22.7923 17.9999 19.6673 16.3333Z"></path></svg>
            </span>
            <span className={style.text}>话题</span>
        </div>
        
        <div className={activeTab==='/square'?style.activeTab:''} onClick={()=>{
            to('/square')
        }}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M9 7.53861L15 21.5386L18.6594 13H23V11H17.3406L15 16.4614L9 2.46143L5.3406 11H1V13H6.6594L9 7.53861Z"></path></svg>
            </span>
            <span className={style.text}>寻TA</span>
        </div>

        <div className={activeTab==='/user'?style.activeTab:''} onClick={()=>{
            to('/user')
            new CACHE("ContentOfUser").delByPrefix("mine")
            new CACHE("ContentOfUser").delByPrefix("user")
        }}>
            <span>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M16.0001 17.4723C17.0616 18.4223 18.4634 19 20 19H22V21H20C18.5428 21 17.1766 20.6104 15.9998 19.9296C14.8242 20.6101 13.4576 21 12 21C10.5428 21 9.17657 20.6104 7.99984 19.9296C6.8242 20.6101 5.45763 21 4 21H2V19H4C5.53713 19 6.93925 18.422 8.00013 17.4723C9.06163 18.4223 10.4634 19 12 19C13.5371 19 14.9393 18.422 16.0001 17.4723ZM12.5734 1.53204L12.6727 1.6115L23 11H20V17C19.2988 17 18.6258 16.8797 18.0003 16.6587L18 9.15698L12 3.70198L6 9.15598L6.00125 16.6582C5.4648 16.8479 4.89325 16.9635 4.29898 16.9927L4 17V11H1L11.3273 1.6115C11.677 1.29365 12.1956 1.26716 12.5734 1.53204Z"></path></svg>
            </span>
            <span className={style.text}>更多</span>
        </div>
    </div>

    function to(path){
        setActiveTab(path)
        navigate(path)
    }
}





