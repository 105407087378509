import style from "./index.module.css"
import { useNavigate } from "react-router-dom"

export default function Home({enable,loadding}){

    const navi = useNavigate()

    return<div className={style.home}>
        <div className={style.container}>

        <div className={style.top} >
            <div className={style.hint}>
                <p>浮尘于世，总要有个归宿。希望你能在这里找到你的归宿。love is everything~</p>
            </div>
        </div>

        <div className={style.itemsBg}>
            <div className={[style.items,loadding?style.loadding:''].join(" ")}>
                <div className={style.left}> 
                    <div className={style.find} onClick={()=>{
                        if(enable)navi("/square/find")
                        }}>
                        <div>
                            {enable&&<span>寻</span>}
                        </div>
                    </div>
                    <div className={style.profile} onClick={()=>{
                        if(enable)navi("/square/profile")
                    }}>
                        <div>
                            {enable&&<span>我的资料</span>}
                        </div>
                    </div>
                </div>

                <div className={style.right}>
                    <div className={style.love} onClick={()=>{
                        navi("/square/lover")
                    }}>
                        <div >
                            <span>LOVER</span>
                        </div>
                        <div className={style.heart}></div>
                    </div>


                    <div className={style.record} onClick={()=>{
                        if(enable)navi("/square/paired")
                    }}>
                        <div>
                            <p>{enable&&<span>擦肩记录</span>}</p>
                            <div>
                                别错过，别等太久
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div className={style.bottom}>
            <div onClick={()=>{
                        if(enable){
                            setTimeout(()=>{
                                navi('/square/share')
                            },150)
                        }
                    }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M10.313 11.5656L18.253 3.62561L20.3744 5.74693L18.9602 7.16114L21.0815 9.28246L17.5459 12.818L15.4246 10.6967L12.4343 13.687C13.4182 15.5719 13.1186 17.9524 11.5355 19.5355C9.58291 21.4881 6.41709 21.4881 4.46447 19.5355C2.51184 17.5829 2.51184 14.4171 4.46447 12.4644C6.04755 10.8814 8.42809 10.5818 10.313 11.5656ZM9.41421 17.4142C10.1953 16.6331 10.1953 15.3668 9.41421 14.5858C8.63316 13.8047 7.36684 13.8047 6.58579 14.5858C5.80474 15.3668 5.80474 16.6331 6.58579 17.4142C7.36684 18.1952 8.63316 18.1952 9.41421 17.4142Z"></path></svg>
                密钥
            </div>

            <div onClick={()=>{
                        if(enable){
                            setTimeout(()=>{
                                navi("/square/share/passkey")
                            },150)
                        }
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M1.99805 21.0001V19.0001L3.99805 18.9999V4.83465C3.99805 4.35136 4.34367 3.93723 4.81916 3.85078L14.2907 2.12868C14.6167 2.0694 14.9291 2.28564 14.9884 2.61167C14.9948 2.64708 14.998 2.68301 14.998 2.719V3.9999L18.998 4.00007C19.5503 4.00007 19.998 4.44779 19.998 5.00007V18.9999L21.998 19.0001V21.0001H17.998V6.00007L14.998 5.9999V21.0001H1.99805ZM12.998 4.3965L5.99805 5.66923V19.0001H12.998V4.3965ZM11.998 11.0001V13.0001H9.99805V11.0001H11.998Z"></path></svg>
                密钥直达
            </div>

            <div onClick={()=>{
                setTimeout(()=>{
                    navi("/square/setting")
                },150)
            }}>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M2 18H9V20H2V18ZM2 11H11V13H2V11ZM2 4H22V6H2V4ZM20.674 13.0251L21.8301 12.634L22.8301 14.366L21.914 15.1711C21.9704 15.4386 22 15.7158 22 16C22 16.2842 21.9704 16.5614 21.914 16.8289L22.8301 17.634L21.8301 19.366L20.674 18.9749C20.2635 19.3441 19.7763 19.6295 19.2391 19.8044L19 21H17L16.7609 19.8044C16.2237 19.6295 15.7365 19.3441 15.326 18.9749L14.1699 19.366L13.1699 17.634L14.086 16.8289C14.0296 16.5614 14 16.2842 14 16C14 15.7158 14.0296 15.4386 14.086 15.1711L13.1699 14.366L14.1699 12.634L15.326 13.0251C15.7365 12.6559 16.2237 12.3705 16.7609 12.1956L17 11H19L19.2391 12.1956C19.7763 12.3705 20.2635 12.6559 20.674 13.0251ZM18 17C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15C17.4477 15 17 15.4477 17 16C17 16.5523 17.4477 17 18 17Z"></path></svg>
                必读与设置
            </div>
        </div>
        
    </div>
    </div>




}