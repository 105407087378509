import { Route, Routes } from "react-router-dom";
import FlashAdd from "./add";
import FlashDetail from "./detail";
import { useState } from "react";
// import FlashWarrper from "./wrapper";
import NFlashHome from "./nhome";



export default function Flash(){
    
    const [flashCroll,setFlashScroll] = useState(null)

    return <Routes>

        <Route path="/" element={<NFlashHome flashScroll={flashCroll} setFlashScroll={setFlashScroll} />} />
        {/* <Route path='/' element={<FlashWarrper root flashCroll={flashCroll}
            setFlashScroll={setFlashScroll} />} />
        <Route path='/list/:page' element={<FlashWarrper flashCroll={flashCroll}
            setFlashScroll={setFlashScroll} />} /> */}
        <Route path='/add' element={<FlashAdd />} />  
        <Route path="/detail/:id" element={<FlashDetail />} />
    </Routes>
}