

import { useContext, useEffect, useState } from "react"
import style from "./index.module.css"
import { axios } from "../../axios"
import { useNavigate } from "react-router-dom"
import { Button } from "antd"

import { SusparContext } from "../../context"
import Msg from "../my-com/Msg"

export default function Login(){

    const navigater = useNavigate()
    const [loginOrRegister,setLoginOrRegister]=useState('login')
    const [allowRegister,setAllowRegister]=useState(false)

    const SUSPAR = useContext(SusparContext)

    const [registerInfo,setRegisterInfo]=useState({
        username:'',
        nickname:'',
        pw1:'',
        pw2:'',
        email:'',
        phone:''
    })

    const [loginInfo,setLoginInfo]=useState({
        username:'',
        password:''
    })

    useEffect(()=>{
        checkIsAllowUserRegister()
    },[])


    return<div>

        <div className={style.bg}>
            <svg fill="none" height="10em" width="10em" viewBox="0 0 288 288" xmlns="http://www.w3.org/2000/svg">
                <rect className="bgLightTint" fill="#E2EEFF" height="304.742" rx="123.401" transform="rotate(45 164.485 -51)" width="246.801" x="164.485" y="-51"></rect>
            </svg>
            <div className={style.floatingText}>
                <span>免费</span>
                <span>隐私</span>
                <span>正向</span>
                <span>公益</span>
            </div>
            <div className={style.decorativeShapes}>
                <div className={style.circle}></div>
                <div className={style.square}></div>
                <div className={style.triangle}></div>
            </div>
        </div>
        <div className={style.container}>
            
            <p className={style.header}>
                <span 
                    className={[loginOrRegister==='login'?style.activeTab:''].join(" ")}
                    onClick={()=>setLoginOrRegister('login')}>登录</span> 
                <span 
                    className={[loginOrRegister==='register'?style.activeTab:''].join(" ")}
                    onClick={()=>setLoginOrRegister('register')}>注册</span>
            </p>

            {loginOrRegister==='login'?<div className={style.loginContainer}>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M5 20H19V22H5V20ZM12 18C7.58172 18 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 14.4183 16.4183 18 12 18ZM12 16C15.3137 16 18 13.3137 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 13.3137 8.68629 16 12 16Z"></path></svg>
                    <input placeholder="帐号或手机号" onChange={e=>setLoginInfo({...loginInfo,username:e.target.value})} />
                </div>
                <div>
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 1C8.68629 1 6 3.68629 6 7V8H4C3.44772 8 3 8.44772 3 9V21C3 21.5523 3.44772 22 4 22H13.044C12.6947 21.2389 12.5 20.3922 12.5 19.5C12.5 16.1863 15.1863 13.5 18.5 13.5C19.3922 13.5 20.2389 13.6947 21 14.044V9C21 8.44772 20.5523 8 20 8H18V7C18 3.68629 15.3137 1 12 1ZM16 8H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8ZM21.145 23.1406L20.6399 20.1953L22.7798 18.1094L19.8225 17.6797L18.5 15L17.1775 17.6797L14.2202 18.1094L16.3601 20.1953L15.855 23.1406L18.5 21.75L21.145 23.1406Z"></path></svg>
                    <input placeholder="密码" type='password' onChange={e=>setLoginInfo({...loginInfo,password:e.target.value})} />
                </div>
                <p className={style.btns}>
                    <Button type="dashed" onClick={submitLogin}>登录</Button>
                    <Button type="dashed" onClick={()=>navigater("/resetpwd")}>找回密码</Button>
                    <Button type="dashed" onClick={()=>{
                        navigater("/setting/about")
                    }}>关于</Button>
                </p>
            </div>:<div className={style.registerContainer}>
                {allowRegister?<div className={style.register}>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 2C16.9706 2 21 6.02944 21 11V18.5C21 20.433 19.433 22 17.5 22C16.3001 22 15.2413 21.3962 14.6107 20.476C14.0976 21.3857 13.1205 22 12 22C10.8795 22 9.9024 21.3857 9.38728 20.4754C8.75869 21.3962 7.69985 22 6.5 22C4.63144 22 3.10487 20.5357 3.00518 18.692L3 18.5V11C3 6.02944 7.02944 2 12 2ZM12 4C8.21455 4 5.1309 7.00478 5.00406 10.7593L5 11L4.99927 18.4461L5.00226 18.584C5.04504 19.3751 5.70251 20 6.5 20C6.95179 20 7.36652 19.8007 7.64704 19.4648L7.73545 19.3478C8.57033 18.1248 10.3985 18.2016 11.1279 19.4904C11.3053 19.8038 11.6345 20 12 20C12.3651 20 12.6933 19.8044 12.8687 19.4934C13.5692 18.2516 15.2898 18.1317 16.1636 19.2151L16.2606 19.3455C16.5401 19.7534 16.9976 20 17.5 20C18.2797 20 18.9204 19.4051 18.9931 18.6445L19 18.5V11C19 7.13401 15.866 4 12 4ZM12 12C13.1046 12 14 13.1193 14 14.5C14 15.8807 13.1046 17 12 17C10.8954 17 10 15.8807 10 14.5C10 13.1193 10.8954 12 12 12ZM9.5 8C10.3284 8 11 8.67157 11 9.5C11 10.3284 10.3284 11 9.5 11C8.67157 11 8 10.3284 8 9.5C8 8.67157 8.67157 8 9.5 8ZM14.5 8C15.3284 8 16 8.67157 16 9.5C16 10.3284 15.3284 11 14.5 11C13.6716 11 13 10.3284 13 9.5C13 8.67157 13.6716 8 14.5 8Z"></path></svg>
                        <input placeholder="昵称" 
                            onChange={e=>setRegisterInfo({...registerInfo,nickname:e.target.value})} />
                    </div>
                    
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M5 20H19V22H5V20ZM12 18C7.58172 18 4 14.4183 4 10C4 5.58172 7.58172 2 12 2C16.4183 2 20 5.58172 20 10C20 14.4183 16.4183 18 12 18ZM12 16C15.3137 16 18 13.3137 18 10C18 6.68629 15.3137 4 12 4C8.68629 4 6 6.68629 6 10C6 13.3137 8.68629 16 12 16Z"></path></svg>
                        <input placeholder="帐号,字母开头的5个以上数字组合" onChange={e=>checkInput(e.target.value)}/>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 1C8.68629 1 6 3.68629 6 7V8H4C3.44772 8 3 8.44772 3 9V21C3 21.5523 3.44772 22 4 22H13.044C12.6947 21.2389 12.5 20.3922 12.5 19.5C12.5 16.1863 15.1863 13.5 18.5 13.5C19.3922 13.5 20.2389 13.6947 21 14.044V9C21 8.44772 20.5523 8 20 8H18V7C18 3.68629 15.3137 1 12 1ZM16 8H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8ZM21.145 23.1406L20.6399 20.1953L22.7798 18.1094L19.8225 17.6797L18.5 15L17.1775 17.6797L14.2202 18.1094L16.3601 20.1953L15.855 23.1406L18.5 21.75L21.145 23.1406Z"></path></svg>
                        <input placeholder="密码" type='password' 
                        onChange={e=>setRegisterInfo({...registerInfo,pw1:e.target.value})}/>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M12 1C8.68629 1 6 3.68629 6 7V8H4C3.44772 8 3 8.44772 3 9V21C3 21.5523 3.44772 22 4 22H13.044C12.6947 21.2389 12.5 20.3922 12.5 19.5C12.5 16.1863 15.1863 13.5 18.5 13.5C19.3922 13.5 20.2389 13.6947 21 14.044V9C21 8.44772 20.5523 8 20 8H18V7C18 3.68629 15.3137 1 12 1ZM16 8H8V7C8 4.79086 9.79086 3 12 3C14.2091 3 16 4.79086 16 7V8ZM21.145 23.1406L20.6399 20.1953L22.7798 18.1094L19.8225 17.6797L18.5 15L17.1775 17.6797L14.2202 18.1094L16.3601 20.1953L15.855 23.1406L18.5 21.75L21.145 23.1406Z"></path></svg>
                        <input placeholder="再次输入密码" type='password' 
                        onChange={e=>setRegisterInfo({...registerInfo,pw2:e.target.value})}/>
                    </div>
                    
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M22 14H20V7.23792L12.0718 14.338L4 7.21594V19H14V21H3C2.44772 21 2 20.5523 2 20V4C2 3.44772 2.44772 3 3 3H21C21.5523 3 22 3.44772 22 4V14ZM4.51146 5L12.0619 11.662L19.501 5H4.51146ZM19 22L15.4645 18.4645L16.8787 17.0503L19 19.1716L22.5355 15.636L23.9497 17.0503L19 22Z"></path></svg>
                        <input placeholder="邮箱,用来找回密码" 
                        onChange={(e)=>setRegisterInfo({...registerInfo,email:e.target.value})}/>
                    </div>
                    <div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="16" height="16" fill="currentColor"><path d="M7 4V20H17V4H7ZM6 2H18C18.5523 2 19 2.44772 19 3V21C19 21.5523 18.5523 22 18 22H6C5.44772 22 5 21.5523 5 21V3C5 2.44772 5.44772 2 6 2ZM12 17C12.5523 17 13 17.4477 13 18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18C11 17.4477 11.4477 17 12 17Z"></path></svg>
                        <input placeholder="手机号,用来登录" 
                        onChange={(e)=>setRegisterInfo({...registerInfo,phone:e.target.value})}/>
                    </div>

                    <span onClick={submitregister}>提交</span>
                    <span style={{backgroundColor:'#eee',color:'#555'}} onClick={()=>setLoginOrRegister('login')}>已有帐号去登录</span>
                </div>:<div>
                    <p>暂不允许注册</p>    
                </div>}
            </div>}
        </div>
    </div>


    function submitLogin() {
        if(!loginInfo.username || !loginInfo.password){
            Msg.msg("用户名、密码不能为空","fail")
            return
        }
        axios.post("/api/user/login",{
            username:loginInfo.username,
            password:loginInfo.password
        }).then(({data})=>{
            if(data.code===200){
                localStorage.setItem("info",JSON.stringify(data.data))
                SUSPAR.setLogin()
                indexedDB.databases().then((dbs)=>{
                    dbs.forEach(db=>{
                        indexedDB.deleteDatabase(db.name)
                    })
                })

                let preURL = localStorage.getItem("preURL")
                if(preURL){
                    window.location.href=preURL
                }else{
                    navigater('/flash')
                }
            }else{
                Msg.msg(data.msg)
            }
        }).catch(err=>{
            console.log(err)
        })
    }

    function checkInput(e){
        if(!e){
            setRegisterInfo({...registerInfo,username:e})
            return
        }
        let firstC = e.charCodeAt(0)
        if((firstC>=65 && firstC<=90)||(firstC>=97 && firstC<=122)){

        }else{
            Msg.msg("必须以字母开头")
            return
        }
        for(let i=0;i<e.length;i++){
            let code = e.charCodeAt(i)
            if((code>=65 && code<=90)||(code>=97 && code<=122) || (code>=48 && code<=57)){
            }else{
                Msg.msg("只能输入字母、数字")
                return
            }
        }
        setRegisterInfo({...registerInfo,username:e})
    }


    function submitregister(){
        if(!registerInfo.username || !registerInfo.pw1 || !registerInfo.pw2){
            Msg.msg("用户名和密码不能为空","fail")
            return
        }
        if(registerInfo.pw1!==registerInfo.pw2){
            Msg.msg("两次密码不一致","fail")
            return
        }
        //只能包含字母和数字
        {
            let username = registerInfo.username;
            for(let i=0;i<username.length;i++){
                let code = username.charCodeAt(i);
                if(i===0 && !((code>=65 && code<=90) || (code>=97 && code <= 122))){
                    Msg.msg("用户名以字母开头且只能包含字母、数字");
                    return;
                }
                if(!((code>=65 && code<=90) || (code>=97 && code <= 122) || (code>=48 && code<=57))){
                    Msg.msg("用户名以字母开头且只能包含字母、数字");
                    
                    return;    
                }
            }
        }
        //手机号要11位
        {
            let phone = registerInfo.phone
            if(phone&&phone.length!==11){
                Msg.msg("请填入正确手机号")
                return
            }
            for(let i=0;i<phone.length;i++){
                let code = phone.charCodeAt(i);
                
                if(!(code>=48 && code<=57)){
                    Msg.msg("请填入正确手机号");
                    return;    
                }
            }

        }
        axios.post("/api/user",{
            username:registerInfo.username,
            nickname:registerInfo.nickname,
            password:registerInfo.pw1,
            email:registerInfo.email,
            phone:registerInfo.phone
        }).then(({data})=>{
            if(data.code===200){
                Msg.msg("注册成功","success")
                setLoginOrRegister('login')
            }else{
                Msg.msg(data.msg,"fail")
            }
        }).catch(err=>{
            console.log(err)
        })
    }


    function checkIsAllowUserRegister(){
        axios.get("/api/system/setting/user-register").then(({data})=>{
            if(data.code===200){
                setAllowRegister(data.data)
            }
        }).catch(err=>{})  
    }
}




