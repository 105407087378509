import React, { useState } from 'react';
import { subscribeNotify, unsubscribeNotify } from './tools';
import { Heart1 } from './icons';
import { PullRefresh, Toast } from 'react-vant';

const Test = () => {

    const [count, setCount] = useState(0)
    const onRefresh = showToast => {
        return new Promise(resolve => {
        setTimeout(() => {
            if (showToast) {
            Toast.info('刷新成功')
            }
            setCount(count + 1)
            resolve(true)
        }, 1000)
        })
    }

  return<div>

    <button onClick={subscrib}>test</button>
    <button onClick={unsubscribe}>unsubscribe</button>

    <PullRefresh 
            onRefresh={() => onRefresh(true)}
            onRefreshEnd={() => console.log('onRefreshEnd')}>
        
        <div>

        <div>
                    <svg fill="none" height="10em" width="10em" viewBox="0 0 288 288"  xmlns="http://www.w3.org/2000/svg">
                        <rect class="bgLightTint" fill="#E2EEFF" height="304.742" rx="123.401" transform="rotate(45 164.485 -51)" width="246.801" x="164.485" y="-51"></rect>
                    </svg>
    </div>
    <Heart1/>
        </div>
    </PullRefresh>



  </div>
    async function unsubscribe() {
        unsubscribeNotify()
    }

    async function subscrib(){
        subscribeNotify()
    }
}

export default Test;

