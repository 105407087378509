import { useContext, useEffect, useState } from "react";
import { axios } from "../../../../axios";
import { PAIRCONTEXT } from "../context";

import style from "./index.module.css"
import { localDate } from "../../../../tools";
import Msg from "../../../my-com/Msg";
import Confirm from "../../../my-com/confirm";
import { Pagination } from "antd";
function InProgress(){
    const PAIRC = useContext(PAIRCONTEXT)

    useEffect(()=>{
        getProcessingList(1)
        // eslint-disable-next-line
    },[])

    const [records,setRecords] = useState({
        page:1,
        list:[],
        total:0
    })

    
    return <div className={style.container}>
        {
            records.list.map(e=><div 
                className={style.item} 
                key={e.id} 
                >
                    <div onClick={()=>{
                        PAIRC.setFlow({pairSessionId:e.id,status:e.status,showStatus:e.status})
                    }}>
                        <p><span className={style.a}>昵称:</span> <span>{e.name}</span></p>
                        <p><span className={style.b}>状态:</span> <span className={style.status}>{statusToCN(e.status)}</span></p>
                        <p> 
                            <span className={style.c}>时间:</span>&nbsp;
                            <span className={style.date}>{localDate(e.createDatetime)}</span>
                        </p>
                        {e.originFrom&&<p>
                            <span className={style.d}>来源:</span> 
                            <span className={style.meOrHim}>{e.fromMe?'我':'他'}</span> 
                            <span>{e.originFrom}</span>
                        </p>}
                        {e.comment&&<p><span className={style.d}>备注:</span> <span>{e.comment}</span></p>}
                    </div>
                
                {e.over2Days&&<div className={style.end}>
                    <span onClick={()=>over2Finish(e.id)}>对方已2天未确认资料，可主动结束</span>
                </div>}
                {e.over10Days&&<div className={style.end}>
                    <span onClick={()=>over10Finish(e.id)}>流程已超10天，可主动结束</span>
                </div>}
            </div>)

            
        }
            {records.total>18&&<div>
                <Pagination
                    current={records.page}
                    total={records.total}
                    pageSize={18}
                    onChange={(page)=>{
                        getProcessingList(page)
                    }}
                />
            </div>}
    </div>

    //主动结束
    function over10Finish(id) {
        Confirm.confirm("缘分不易，确定要结束吗？").then(()=>{
            axios.post("/api/square/flow/over10days",{
                sessionId:id
            }).then(({data})=>{
                if(data.code===200){
                    getProcessingList(records.page)
                    Msg.msg("操作成功，已结束")
                }
            }).catch(err=>{})
        }).catch(()=>{return})
        
    }
    function over2Finish(id) {
        Confirm.confirm("缘分不易，确定要结束吗？").then(()=>{
            axios.post("/api/square/flow/over2days",{
                sessionId:id
            }).then(({data})=>{
                if(data.code===200){
                    getProcessingList(records.page)
                    Msg.msg("操作成功，已结束")
                }
            }).catch(err=>{})
        }).catch(()=>{return})
        
    }


     //获取正在进行的session
     function getProcessingList(page){
        axios.get("/api/square/flow/records/in-progress",{
            params:{
                page:page-1
            }
        }).then(({data})=>{
            if(data.code===200){
                data = data.data
                setRecords({...records,page,total:data.total,list:data.list})
            }
        }).catch(err=>{})

    }


    function statusToCN(status){
        console.log(status)
        if(status === 'CREATED'){
            return "待确认资料"
        }
        if(status === 'SAY_HELLO'){
            return "资料确认中"
        }
        if(status === 'FACE_PAIRING'){
            return "形象反馈中"
        }
        if(status === 'READY_BE_LOVER'){
            return "一周接触"
        }
        if(status === 'NOW_LOVER'){
            return "已确认关系"
        }
        return "fuck"
    }
}


export {InProgress}